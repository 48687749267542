import React, { useState, useEffect, SyntheticEvent } from 'react';
import styled from 'styled-components/macro';
import { IUser } from '../../domain/portal-domain';
import { bridgeAdministration, chevronRight, signOut } from '../../ui-icons/illustrations';
import { feedbackIcon, help, verticalLine } from '../../ui-icons/footer-icons';
import { mainTheme, animations } from '../../styles/themes/main-theme';
import { USER_ADMIN_URL } from '../../domain/consts';
import { INotificationViewModel } from '../../data-providers/notifications';
import Emitter from './fresh-service/emitter';
import { getGeneralSurveyUrl } from './user-survey-url';

const MenuContainer = styled.div`
    position: absolute;
    text-align: left;
    background-color: ${mainTheme.colors.white};
    animation-name: ${animations.profileMenu.expand};
    animation-duration: 1s;
    width: ${mainTheme.widths.sideMenuMax};
    height: calc(100vh - ${mainTheme.heights.navbar});
    box-shadow: 0px 0px 5px ${mainTheme.colors.shadow};
    border-radius: 3px;
    z-index: 3;
    white-space: nowrap;
    margin-right: -30.8px;
    right: 0px;
    top: calc(50px + 2px);
    display: flex;
    flex-direction: column;
    line-height: 140.6%;
    overflow-x: hidden;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        width: ${mainTheme.widths.sideMenuMobile};
    }
`;

const EmptyContainer = styled.div`
    position: absolute;
    background-color: ${mainTheme.colors.white};
    animation-name: ${animations.profileMenu.hide};
    animation-duration: 1s;
    top: 60px;
    right: -36rem;
    z-index: 30;
    width: ${mainTheme.widths.sideMenuMax};
    height: 93vh;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        width: ${mainTheme.widths.sideMenuMobile};
        top: 50px;
        animation-name: ${animations.profileMenu.phoneHide};
    }
`;
const HorizontalLine = styled.div`
    border-bottom: 2px solid #dfdfdf;
    margin: ${mainTheme.margins.big} ${mainTheme.margins.biggest};
`;

const Bolded = styled.div`
    font-weight: bold;
    overflow-wrap: anywhere;
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
`;

const LinkItem = styled.a`
    &:hover {
        text-decoration: none;
        color: ${mainTheme.colors.grey};
    }
`;

const MailToItem = styled.div`
    overflow-wrap: anywhere;
    padding: 0rem 1.5rem 1.25rem;
    color: ${mainTheme.colors.lightGrey};
    font-size: 14px;
`;

const HiddenElement = styled.div`
    display: none;
`;
const Text = styled.div`
    position: relative;
    padding: ${mainTheme.margins.normal} ${mainTheme.margins.biggest};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    &:hover {
        color: ${mainTheme.colors.grey};
        background: rgba(0, 129, 138, 0.05);
    }
`;

const Notificator = styled.div`
    position: absolute;
    top: 10px;
    left: 110px;
    font-size: 10px;
    padding-top: 1px;
    background-color: ${mainTheme.colors.error};
    border-radius: 100%;
    width: 15px;
    height: 15px;
    font-weight: bold;
    color: ${mainTheme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SupportText = styled.div`
    position: relative;
    padding: ${mainTheme.margins.normal} ${mainTheme.margins.normal} ${mainTheme.margins.normal} ${mainTheme.margins.biggest};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    justify-content: flex-start;
    margin-left: 10px;
`;
const FeedbackIcon = styled.span`
    margin-top: 7.5px;
`;
const Feedback = styled.span`
    margin-top: 10px;
    color: ${mainTheme.colors.bridgePrimary};
    font-weight: bold;
    position: absolute;
    margin-left: 42px;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        margin-left: 10px;
    }
`;
const Span1 = styled.span`
    position: absolute;
    margin-left: 120px;
    svg {
        margin-top: 5px;
    }
`;
const Span2 = styled.span`
    position: absolute;
    margin-left: 10px;
    margin-top: 7px;
    color: ${mainTheme.colors.lightGrey};
`;
const NoUnderline = styled.a`
    text-decoration: none !important;
    color: ${mainTheme.colors.grey};
`;

const SupportLink = styled.a`
    margin-top: 100px;
    text-decoration: none !important;
    color: ${mainTheme.colors.grey};
    font-weight: 'normal';

    & ${SupportText} svg path:nth-child(1) {
        stroke: ${mainTheme.colors.grey};
    }

    & ${SupportText} svg path:nth-child(2) {
        fill: ${mainTheme.colors.grey};
    }
    &:hover {
        color: ${mainTheme.colors.grey};
        font-weight: 'normal';
    }
`;
const SupportLink2 = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding-left: 15px;
    margin-top: 20px;
    padding-bottom: 10px;
    &:hover {
        background: ${mainTheme.colors.inputBackground};
        color: ${mainTheme.colors.grey};
    }
`;
const Help = styled.span`
    margin-right: 55%;
    margin-top: 2px;
    color: ${mainTheme.colors.bridgePrimary};
    font-weight: bold;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        margin-left: 10px;
    }
`;
export interface IProfileCollapseProps {
    isOpen: boolean;
    user: IUser;
    notifications: INotificationViewModel[];
    changePasswordUrl: () => void;
    onShowNotifications: () => void;
    onLogout: () => void;
}
const generalSurveyUrl = getGeneralSurveyUrl();
export const ProfileCollapse: React.FC<IProfileCollapseProps> = ({
    isOpen,
    user,
    notifications,
    changePasswordUrl,
    onShowNotifications,
    onLogout,
}) => {
    function onNotificationsClicked(e: SyntheticEvent) {
        e.preventDefault();
        onShowNotifications();
    }

    const [isOpenedState, setIsOpenedState] = useState<Boolean | null>(null);

    useEffect(() => {
        if (isOpen) {
            setIsOpenedState(false);
        } else if (isOpenedState !== null) {
            setIsOpenedState(true);
            setTimeout(() => {
                setIsOpenedState(null);
            }, 400);
        }
    }, [isOpen, isOpenedState]);

    return isOpen ? (
        <MenuContainer>
            <HiddenElement>{(document.body.style.overflow = 'hidden')}</HiddenElement>
            <ul className="navbar-nav flex-grow">
                <li className="nav-item">
                    <Bolded>
                        <span>{user.name}</span>
                    </Bolded>
                </li>
                <li className="nav-item">
                    <MailToItem>
                        <span>{user.email}</span>
                    </MailToItem>
                </li>
                <HorizontalLine />
                <li className="nav-item">
                    <>
                        <SupportLink onClick={() => Emitter.emit('CALL_TO_OPEN_FS_DIALOG', true)}>
                            <Text>
                                <span>{help}</span>
                                <Help>Help and support</Help>
                            </Text>
                        </SupportLink>
                        <SupportLink
                            onClick={() => {
                                window.open(generalSurveyUrl, '_blank');
                            }}>
                            <SupportLink2>
                                <FeedbackIcon>{feedbackIcon}</FeedbackIcon>
                                <Feedback>Feedback</Feedback>
                                <Span1>
                                    {verticalLine} <Span2>Axess Digital </Span2>
                                </Span1>
                            </SupportLink2>
                        </SupportLink>
                    </>
                </li>
                <HorizontalLine />
                {notifications.length > 0 ? (
                    <>
                        <HorizontalLine />
                        <li className="nav-item">
                            <NoUnderline href="/" onClick={onNotificationsClicked}>
                                <Text>
                                    <span>Notifications</span>
                                    <Notificator>{notifications.length}</Notificator>
                                    <span>{chevronRight}</span>
                                </Text>
                            </NoUnderline>
                        </li>
                    </>
                ) : null}
                <li className="nav-item">
                    <NoUnderline href="/" onClick={changePasswordUrl}>
                        <Text>
                            <span>Change password</span>
                            <span>{bridgeAdministration}</span>
                        </Text>
                    </NoUnderline>
                </li>

                {user.isGlobalAdministrator || user.isCompanyAdministrator ? (
                    <li className="nav-item">
                        <LinkItem className="nav-link" href={USER_ADMIN_URL} target="_blank" rel="noopener noreferrer">
                            <Text>
                                <span>Bridge Administration</span>
                                <span>{bridgeAdministration}</span>
                            </Text>
                        </LinkItem>
                    </li>
                ) : null}
                <HorizontalLine />
                <li className="nav-item">
                    <LinkItem onClick={onLogout} href="#">
                        <Text>
                            <span>Sign out</span>
                            <span>{signOut}</span>
                        </Text>
                    </LinkItem>
                </li>
            </ul>
        </MenuContainer>
    ) : isOpenedState !== null ? (
        <EmptyContainer>
            <HiddenElement>
                {(document.body.style.overflowX = 'hidden')}
                {(document.body.style.overflowY = 'auto')}
            </HiddenElement>
        </EmptyContainer>
    ) : null;
};
