import { IOption, OptionType } from "../../domain/portal-domain";

interface IRecentSearch extends IOption {
  userEmail: string;
}

const RECENT_SEARCHES_KEY = "RECENT_SEARCHES";
const RECENT_SEARCHES_LIMIT = 5;

export const setRecentSearch = (option: IOption, userEmail: string): void => {
  let objects: IRecentSearch[] = getRecentSearchesFor(userEmail);
  if (objects.length >= RECENT_SEARCHES_LIMIT) {
    objects.shift();
  }

  const allSearchesInLocalStorage = getAllRecentSearches();
  let filteredAllSearchesInLocalStorage = allSearchesInLocalStorage.filter((x) => x.key !== option.key || x.type !== option.type);
  filteredAllSearchesInLocalStorage.push({ ...option, userEmail: userEmail });
  localStorage.setItem(
    RECENT_SEARCHES_KEY,
    JSON.stringify(filteredAllSearchesInLocalStorage)
  );
};

export const getRecentSearchesFor = (userEmail: string): IRecentSearch[] => {
  const allItems = JSON.parse(
    localStorage.getItem(RECENT_SEARCHES_KEY) || "[]"
  ) as IRecentSearch[];
  const userItems = allItems.filter((x) => x.userEmail === userEmail);
  return userItems;
};

export const getAllRecentSearches = (): IRecentSearch[] => {
  return JSON.parse(localStorage.getItem(RECENT_SEARCHES_KEY) || "[]");
};

export const removeByKey = (key: string, type: OptionType) => {
  const allSearches = getAllRecentSearches().filter(
    (x) => !(x.key === key && x.type === type)
  );

  localStorage.removeItem(RECENT_SEARCHES_KEY);
  localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(allSearches));
};
