import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { NavMenuRow } from './nav-menu-row';
import { ICompany, IOption, ISearchResult, ISelection, IUser } from '../../domain/portal-domain';
import { mainTheme } from '../../styles/themes/main-theme';
import { INotificationViewModel } from '../../data-providers/notifications';

const Navigation = styled.nav`
    padding-top: 0px;
    width: 100vw;
    box-shadow: 0px 1px 2px rgba(0, 129, 138, 0.5);
    @media ${mainTheme.responsiveBreakpoints.phone} {
        padding-top: 10px !important;
    }
`;

const Container = styled.div`
    background: ${mainTheme.colors.white};
`;

const Row = styled.div`
    width: 100%;
`;

const Overlay = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0);
    opacity: 0;
    z-index: 3;
`;

const NavRowWrapper = styled.div`
    padding-left: 16px;
`;

export interface INavMenuContainerProps {
    currentSelection: ISelection;
    user: IUser;
    notifications: INotificationViewModel[];
    allCompaniesWithInstallations: ICompany[];
    onSelectedOption: (option: IOption) => void;
    onOptionsFiltered: (options: ISearchResult[]) => void;
    onSearchTextChanged: (searchText: string) => void;
    onArchivedNotification: (id: number) => void;
}

export const NavMenuContainer: React.FC<INavMenuContainerProps> = ({
    user,
    notifications,
    currentSelection,
    allCompaniesWithInstallations,
    onSelectedOption,
    onOptionsFiltered,
    onSearchTextChanged,
    onArchivedNotification,
}) => {
    const [isMenuExpanded, setIsMenuExpanded] = useState(false);

    const expandMenuHandler = (isExpanded: boolean) => {
        setIsMenuExpanded(isExpanded);
    };

    return (
        <Container>
            {isMenuExpanded ? <Overlay></Overlay> : null}
            <header>
                <Navigation className="navbar pb-0 mb-0">
                    <Row className="row">
                        <NavRowWrapper className="col">
                            <NavMenuRow
                                expandedMenuCallback={expandMenuHandler}
                                notifications={notifications}
                                allCompaniesWithInstallations={allCompaniesWithInstallations}
                                currentSelection={currentSelection}
                                onOptionsFiltered={onOptionsFiltered}
                                onSearchTextChanged={onSearchTextChanged}
                                onSelectedOption={onSelectedOption}
                                onArchivedNotification={onArchivedNotification}
                                user={user}
                            />
                        </NavRowWrapper>
                    </Row>
                </Navigation>
            </header>
        </Container>
    );
};
