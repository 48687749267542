import React from 'react';
import styled from 'styled-components/macro';
import { mainTheme } from '../../../styles/themes/main-theme';
import { birds, birdsnoclouds, squerelinkicon } from '../../../ui-icons/browser-not-supported';
import { browserDetect } from '../../../data-providers/helpers';
export interface IScreenMobileState {
    isMobileSize: boolean;
}

const ModalDialog = styled.div`
    width: 60%;
    padding: ${mainTheme.containerMargins.extrasmall} 112px ${mainTheme.containerMargins.extrasmall} 112px;
    height: auto;
    overflow: auto;
    color: ${mainTheme.colors.white};
    margin: auto;

    background-color: ${mainTheme.colors.white};

    @media ${mainTheme.responsiveBreakpoints.phone} {
        width: 100%;
        padding: ${mainTheme.containerMargins.small};
    }
    @media ${mainTheme.responsiveBreakpoints.smallScreen} {
        width: 70%;
        padding-right: 0px;
        padding-left: 0px;
    }
    @media ${mainTheme.responsiveBreakpoints.tablet} {
        width: 100%;
    }
`;
const ModalHeader = styled.div`
    padding-right: 112px;
    padding-left: 112px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: ${mainTheme.colors.black};

    @media (max-width: 767px) {
        padding-right: ${mainTheme.containerMargins.extrasmall};
        padding-left: 0px;
    }
`;
const ModalBody = styled.div`
    padding-right: 112px;
    padding-left: 112px;
    padding-top: 4px;
    padding-bottom: ${mainTheme.containerMargins.extrasmall};
    font-style: normal;
    font-weight: normal;
    font-size: ${mainTheme.fontSizes.bigger};
    color: ${mainTheme.colors.lightGrey};
    background-color: ${mainTheme.colors.white};

    @media ${mainTheme.responsiveBreakpoints.phone} {
        padding-right: ${mainTheme.containerMargins.extrasmall};
        padding-left: ${mainTheme.containerMargins.extrasmall};
        font-size: ${mainTheme.fontSizes.big};
    }
    @media ${mainTheme.responsiveBreakpoints.tablet} {
        font-size: ${mainTheme.fontSizes.big};
    }
`;

const ImgBckg = styled.div`
    padding-top: 0px;
    padding-right: 112px;
    padding-bottom: 0px;
    padding-left: 112px;
    background-color: ${mainTheme.colors.white};
    svg {
        width: 460px;
    }
    @media ${mainTheme.responsiveBreakpoints.smallScreen} {
        svg {
            width: 412px;
        }
    }
    @media ${mainTheme.responsiveBreakpoints.tablet} {
        svg {
            width: 318px;
        }
    }
    @media ${mainTheme.responsiveBreakpoints.phone} {
        svg {
            width: 280px;
        }

        padding: 0px;
    }
`;

const Row = styled.div`
    padding-bottom: 0px;
`;

const Column1 = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: ${mainTheme.fontSizes.big};
    align-items: left;
    color: ${mainTheme.colors.bridgePrimary};

    border-bottom: 0.5px solid ${mainTheme.colors.unactiveButtonBackground};
    width: 95%;
    float: left;
    padding-bottom: 6px;
    padding-top: 14px;
`;
const Column2 = styled.div`
    border-bottom: 0.5px solid ${mainTheme.colors.unactiveButtonBackground};
    float: right;
    display: inline;
    padding-bottom: 6px;
    padding-top: 14px;
`;

const BoldedText = styled.div`
    font-weight: bold;
    color: ${mainTheme.colors.bridgePrimary};
`;

const BoldedText2 = styled.span`
    font-weight: bold;
    color: ${mainTheme.colors.grey};
    font-size: ${mainTheme.fontSizes.big};
`;

const LighterText = styled.span`
    font-weight: lighter;
    color: ${mainTheme.colors.lightGrey};
`;

const P1 = styled.p`
    padding-bottom: 16px;
`;

const P2 = styled.p`
    padding-bottom: ${mainTheme.containerMargins.big};
`;

const HeaderTitle = styled.div`
    font-size: ${mainTheme.fontSizes.biggest28};
    font-style: normal;
    font-weight: 700;
    line-height: ${mainTheme.lineHeights.h33};
    letter-spacing: 0em;
    text-align: left;

    @media ${mainTheme.responsiveBreakpoints.phone} {
        padding-left: ${mainTheme.containerMargins.extrasmall};
        padding-right: ${mainTheme.containerMargins.extrasmall};
        font-size: ${mainTheme.fontSizes.bigger};
    }
`;

const Span = styled.span`
    font-size: ${mainTheme.fontSizes.big};
    font-style: normal;
    font-weight: 400;
    line-height: ${mainTheme.lineHeights.h1};
    letter-spacing: 0em;
    text-align: left;
    color: ${mainTheme.colors.lightGrey};
`;

export class BrowserInfo extends React.Component<{}, IScreenMobileState> {
    constructor(props: {}, state: IScreenMobileState) {
        super(props);

        this.setImage = this.setImage.bind(this);
        this.state = { isMobileSize: false };
    }
    componentDidMount() {
        this.setImage();
    }
    setImage() {
        if (window.matchMedia(mainTheme.responsiveBreakpoints.phone).matches === true) {
            this.setState({ isMobileSize: true });
        } else {
            this.setState({ isMobileSize: false });
        }
    }

    render() {
        return (
            <ModalDialog>
                <ImgBckg>{this.state.isMobileSize === true ? birdsnoclouds : birds}</ImgBckg>
                <ModalHeader>
                    <HeaderTitle>Your browser is not supported.</HeaderTitle>
                </ModalHeader>

                <ModalBody>
                    <P1>Update your existing browser, or download a new supported one from the list below.</P1>
                    <P2>
                        <BoldedText2>
                            Your current browser is: <LighterText>{browserDetect}</LighterText>
                        </BoldedText2>
                    </P2>
                    <Row>
                        <Column1>
                            <BoldedText>
                                Google Chrome<Span> (Recommended)</Span>
                            </BoldedText>
                        </Column1>
                        <Column2>
                            <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                                {squerelinkicon}
                            </a>
                        </Column2>
                    </Row>
                    <Row>
                        <Column1>
                            <BoldedText>Microsoft Edge</BoldedText>
                        </Column1>
                        <Column2>
                            <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noopener noreferrer">
                                {squerelinkicon}
                            </a>
                        </Column2>
                    </Row>
                    <Row>
                        <Column1>
                            <BoldedText>Mozzila Firefox</BoldedText>
                        </Column1>
                        <Column2>
                            <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">
                                {squerelinkicon}
                            </a>
                        </Column2>
                    </Row>
                    <Row>
                        <Column1>
                            <BoldedText>Apple Safari</BoldedText>
                        </Column1>
                        <Column2>
                            <a href="https://support.apple.com/downloads/safari" target="_blank" rel="noopener noreferrer">
                                {squerelinkicon}
                            </a>
                        </Column2>
                    </Row>
                    <Row>
                        <Column1>
                            <BoldedText>Opera</BoldedText>
                        </Column1>
                        <Column2>
                            <a href="https://www.opera.com/" target="_blank" rel="noopener noreferrer">
                                {squerelinkicon}
                            </a>
                        </Column2>
                    </Row>
                </ModalBody>
            </ModalDialog>
        );
    }
}
