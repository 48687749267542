import { INotification } from "../domain/portal-domain";
import { getFromatedDate } from "./helpers";

export interface INotificationViewModel {
  notificationId: number;
  title: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  shortDescription: string;
  longDescription: string;
  isArchived: boolean;
  isGlobal: boolean;
  wasNotified: boolean;
  importanceLevel: string;
  scopeCompanies: number[];
  scopeInstallations: number[];
}

export const mapNotifications = (
  notifications: INotification[]
): INotificationViewModel[] => {
  return notifications
    .sort((a: INotification, b: INotification) => {
      return (
        new Date(a.activationDate).valueOf() -
        new Date(b.activationDate).valueOf()
      );
    })
    .map((x) => {
      const startDate = new Date(x.startDate);
      const endDate = new Date(x.endDate);
      const startDateString = getFromatedDate(startDate);
      const startTimeString = startDate.toLocaleTimeString("no").slice(0, -3);
      const endDateString = getFromatedDate(endDate);
      const endTimeString = endDate.toLocaleTimeString("no").slice(0, -3);

      return {
        notificationId: x.notificationId,
        title: x.title,
        startDate: startDateString,
        startTime: startTimeString + " UTC",
        endDate: endDateString,
        endTime: endTimeString + " UTC",
        longDescription: x.longDescription,
        shortDescription: x.shortDescription,
        isArchived: x.isArchived,
        isGlobal: x.isGlobal,
        wasNotified: x.wasNotified,
        importanceLevel: x.importanceLevel,
        scopeCompanies: x.scopeCompanies,
        scopeInstallations: x.scopeInstallations
      };
    });
};
