import React from 'react';
import { NavMenuContainer } from './nav-menu/nav-menu-container';
import styled from 'styled-components/macro';
import { Footer } from './footer';
import { ICompany, IOption, ISearchResult, ISelection, IUser } from '../domain/portal-domain';
import { mainTheme } from '../styles/themes/main-theme';
import { INotificationViewModel } from '../data-providers/notifications';

interface IStyleProps {
    isLoading: boolean;
}

export interface ILayoutProps {
    isLoading: boolean;
    children: any;
    notifications: INotificationViewModel[];
    user: IUser;
    currentSelection: ISelection;
    allCompaniesWithInstallations: ICompany[];
    companyNotExisting: boolean;
    onSelectedOption: (option: IOption) => void;
    onOptionsFiltered: (options: ISearchResult[]) => void;
    onSearchTextChanged: (searchText: string) => void;
    onArchivedNotification: (id: number) => void;
}

const Blur = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${mainTheme.colors.black};
    opacity: 0.2;
    z-index: 10;
`;

const App = styled.div`
    filter: ${(styles: IStyleProps) => (styles?.isLoading ? 'blur(5px)' : 'blur(0px)')};
    -webkit-filter: ${(styles: IStyleProps) => (styles?.isLoading ? 'blur(5px)' : 'blur(0px)')};
    background: ${mainTheme.colors.darkerGrey};
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    @media ${mainTheme.responsiveBreakpoints.smallScreens} {
        background: ${mainTheme.colors.mainBackground};
    }
`;

export const Layout: React.FC<ILayoutProps> = ({
    isLoading,
    children,
    user,
    notifications,
    currentSelection,
    companyNotExisting,
    allCompaniesWithInstallations,
    onSelectedOption,
    onOptionsFiltered,
    onSearchTextChanged,
    onArchivedNotification,
}) => {
    return (
        <>
            {isLoading ? <Blur /> : null}
            <App isLoading={isLoading}>
                <NavMenuContainer
                    user={user}
                    notifications={notifications}
                    currentSelection={currentSelection}
                    allCompaniesWithInstallations={allCompaniesWithInstallations}
                    onOptionsFiltered={onOptionsFiltered}
                    onSearchTextChanged={onSearchTextChanged}
                    onSelectedOption={onSelectedOption}
                    onArchivedNotification={onArchivedNotification}
                />
                {children}
                <Footer companyNotExisting={companyNotExisting} />
            </App>
        </>
    );
};
