import { AuthHelper } from '@axbit/bridge-auth';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Hook that logs out the user when the URL hash is '#logout'.
 *
 * Used from Equip to also log out from portal when logging out of Equip.
 */
export function useLogoutUrl(): void {
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#logout') {
            AuthHelper.getInstance().logout();
        }
    }, [location]);
}
