import * as React from 'react';

export const disabled: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.2291 28.3132L23.0648 23.2337L27.4843 21.1799L27.7217 25.7826L23.2291 28.3132Z" fill="#8E8E8E" />
        <path d="M18.2148 20.9728L18.0505 15.8933L22.47 13.8395L22.7074 18.4422L18.2148 20.9728Z" fill="#8E8E8E" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7892 26.0359L17.7892 20.6193L18.8394 20.6193L18.8394 26.0359L17.7892 26.0359Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6165 18.382C13.9066 18.3799 14.1433 18.6143 14.1454 18.9055L14.178 23.4839C14.18 23.7751 13.9466 24.0129 13.6566 24.015C13.3666 24.017 13.1298 23.7827 13.1277 23.4914L13.0952 18.9131C13.0931 18.6219 13.3265 18.3841 13.6165 18.382Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9568 18.7191C23.0779 18.9837 22.9577 19.303 22.6883 19.4322L18.7076 21.3418C18.4382 21.471 18.1216 21.3613 18.0004 21.0967C17.8792 20.8322 17.9994 20.5129 18.2688 20.3836L22.2496 18.4741C22.519 18.3448 22.8356 18.4545 22.9568 18.7191Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1967 23.3289C13.3343 23.0727 13.6511 22.9802 13.9044 23.1223L18.3304 25.6056C18.5837 25.7477 18.6776 26.0706 18.5401 26.3268C18.4026 26.583 18.0857 26.6755 17.8324 26.5333L13.4067 23.9658C13.1534 23.8237 13.0467 23.4598 13.1967 23.3289Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2077 18.4629C13.3295 18.1987 13.6526 18.092 13.9295 18.2246L18.4964 20.4122C18.7733 20.5448 18.8991 20.8665 18.7773 21.1308C18.6556 21.395 18.3325 21.5017 18.0556 21.369L13.4886 19.1814C13.2118 19.0488 13.086 18.7271 13.2077 18.4629Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.4417 28.4224L22.4417 22.9522L23.492 22.9522L23.492 28.4224L22.4417 28.4224Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.4752 20.7812C27.7653 20.7814 28.0002 21.0176 28 21.3088L27.9968 25.8494C27.9966 26.1406 27.7613 26.3765 27.4713 26.3763C27.1813 26.3761 26.9463 26.1399 26.9466 25.8487L26.9497 21.3081C26.9499 21.0169 27.1852 20.781 27.4752 20.7812Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.3153 28.7253C22.1786 28.4687 22.2737 28.1463 22.5276 28.005L27.1563 25.4305C27.4102 25.2893 27.7268 25.3828 27.8635 25.6394C28.0002 25.896 27.9051 26.2185 27.6512 26.3597L23.0225 28.9342C22.7686 29.0754 22.452 28.9819 22.3153 28.7253Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.4674 21.174C27.5885 21.4386 27.4746 21.7548 27.2129 21.8803L23.3464 23.7351C23.0847 23.8607 22.7744 23.748 22.6532 23.4834C22.532 23.2188 22.646 22.9025 22.9077 22.777L26.7742 20.9222C27.0359 20.7967 27.3462 20.9094 27.4674 21.174Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6697 25.8557C17.7977 25.5944 18.1109 25.4901 18.3691 25.6227L22.966 27.9828C23.2242 28.1154 23.3297 28.4347 23.2017 28.696C23.0736 28.9572 22.7604 29.0616 22.5022 28.929L17.9053 26.5688C17.6471 26.4362 17.5416 26.117 17.6697 25.8557Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.8605 20.8494C17.9828 20.5854 18.3282 20.4889 18.632 20.6337L23.6427 23.0231C23.9465 23.1679 24.0936 23.4993 23.9713 23.7633C23.849 24.0273 23.5036 24.1238 23.1998 23.979L18.1891 21.5896C17.8853 21.4448 17.7382 21.1134 17.8605 20.8494Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.4089 18.2832C21.2797 18.5438 21.2984 18.8151 21.6073 18.9692L27.1604 21.741C27.4693 21.8951 27.8051 21.8336 27.9342 21.573C28.0634 21.3124 27.937 20.9513 27.6282 20.7972L22.2019 18.0902C21.8931 17.9362 21.538 18.0226 21.4089 18.2832Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7631 20.9816L17.7631 15.565L18.8133 15.565L18.8133 20.9816L17.7631 20.9816Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6397 19.6615C13.3497 19.6669 13.1103 19.4353 13.1049 19.1442L13.0001 13.5241C12.9947 13.233 13.2253 12.9925 13.5153 12.9871C13.8053 12.9816 14.0448 13.2132 14.0502 13.5044L14.1549 19.1244C14.1604 19.4156 13.9297 19.656 13.6397 19.6615Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6486 19.3517C22.3586 19.3535 22.122 19.119 22.1202 18.8277L22.0883 13.7292C22.0864 13.4379 22.3201 13.2004 22.6101 13.1986C22.9001 13.1967 23.1367 13.4313 23.1385 13.7225L23.1704 18.8211C23.1722 19.1123 22.9386 19.3499 22.6486 19.3517Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.7887 13.7332C22.9099 13.9978 22.796 14.314 22.5343 14.4395L18.6678 16.2943C18.4061 16.4199 18.0957 16.3072 17.9745 16.0426C17.8534 15.778 17.9673 15.4617 18.229 15.3362L22.0955 13.4814C22.3572 13.3559 22.6676 13.4686 22.7887 13.7332Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1819 13.4087C13.3037 13.1445 13.6315 13.0399 13.914 13.1751L18.5743 15.4051C18.8568 15.5403 18.9871 15.8641 18.8652 16.1283C18.7434 16.3924 18.4156 16.497 18.133 16.3618L13.4728 14.1318C13.1903 13.9966 13.06 13.6728 13.1819 13.4087Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.045 13.6415C12.9278 13.3751 13.0479 13.0637 13.3132 12.9461L17.5999 11.0451C17.8652 10.9274 18.1752 11.048 18.2924 11.3144C18.4096 11.5808 18.2895 11.8921 18.0242 12.0098L13.7375 13.9108C13.4722 14.0284 13.1621 13.9078 13.045 13.6415Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3753 11.3306C17.2591 11.5974 17.379 11.9113 17.6431 12.0318L22.1957 14.1079C22.4598 14.2283 22.768 14.1096 22.8842 13.8428C23.0004 13.5759 23.0582 13.3482 22.7942 13.2278L18.0897 11.0701C17.8256 10.9497 17.4915 11.0638 17.3753 11.3306Z"
            fill="#8E8E8E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
            fill="#8E8E8E"
        />
    </svg>
);

export const enabled: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.2291 28.3132L23.0648 23.2337L27.4843 21.1799L27.7217 25.7826L23.2291 28.3132Z" fill="#3D3D3D" />
        <path d="M18.2148 20.9728L18.0505 15.8933L22.47 13.8395L22.7074 18.4422L18.2148 20.9728Z" fill="#3D3D3D" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7892 26.0359L17.7892 20.6193L18.8394 20.6193L18.8394 26.0359L17.7892 26.0359Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6165 18.382C13.9066 18.3799 14.1433 18.6143 14.1454 18.9055L14.178 23.4839C14.18 23.7751 13.9466 24.0129 13.6566 24.015C13.3666 24.017 13.1298 23.7827 13.1277 23.4914L13.0952 18.9131C13.0931 18.6219 13.3265 18.3841 13.6165 18.382Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9568 18.7191C23.0779 18.9837 22.9577 19.303 22.6883 19.4322L18.7076 21.3418C18.4382 21.471 18.1216 21.3613 18.0004 21.0967C17.8792 20.8322 17.9994 20.5129 18.2688 20.3836L22.2496 18.4741C22.519 18.3448 22.8356 18.4545 22.9568 18.7191Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1967 23.3289C13.3343 23.0727 13.6511 22.9802 13.9044 23.1223L18.3304 25.6056C18.5837 25.7477 18.6776 26.0706 18.5401 26.3268C18.4026 26.583 18.0857 26.6755 17.8324 26.5333L13.4067 23.9658C13.1534 23.8237 13.0467 23.4598 13.1967 23.3289Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2077 18.4629C13.3295 18.1987 13.6526 18.092 13.9295 18.2246L18.4964 20.4122C18.7733 20.5448 18.8991 20.8665 18.7773 21.1308C18.6556 21.395 18.3325 21.5017 18.0556 21.369L13.4886 19.1814C13.2118 19.0488 13.086 18.7271 13.2077 18.4629Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.4417 28.4224L22.4417 22.9522L23.492 22.9522L23.492 28.4224L22.4417 28.4224Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.4752 20.7812C27.7653 20.7814 28.0002 21.0176 28 21.3088L27.9968 25.8494C27.9966 26.1406 27.7613 26.3765 27.4713 26.3763C27.1813 26.3761 26.9463 26.1399 26.9466 25.8487L26.9497 21.3081C26.9499 21.0169 27.1852 20.781 27.4752 20.7812Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.3153 28.7253C22.1786 28.4687 22.2737 28.1463 22.5276 28.005L27.1563 25.4305C27.4102 25.2893 27.7268 25.3828 27.8635 25.6394C28.0002 25.896 27.9051 26.2185 27.6512 26.3597L23.0225 28.9342C22.7686 29.0754 22.452 28.9819 22.3153 28.7253Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.4674 21.174C27.5885 21.4386 27.4746 21.7548 27.2129 21.8803L23.3464 23.7351C23.0847 23.8607 22.7744 23.748 22.6532 23.4834C22.532 23.2188 22.646 22.9025 22.9077 22.777L26.7742 20.9222C27.0359 20.7967 27.3462 20.9094 27.4674 21.174Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6697 25.8557C17.7977 25.5944 18.1109 25.4901 18.3691 25.6227L22.966 27.9828C23.2242 28.1154 23.3297 28.4347 23.2017 28.696C23.0736 28.9572 22.7604 29.0616 22.5022 28.929L17.9053 26.5688C17.6471 26.4362 17.5416 26.117 17.6697 25.8557Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.8605 20.8494C17.9828 20.5854 18.3282 20.4889 18.632 20.6337L23.6427 23.0231C23.9465 23.1679 24.0936 23.4993 23.9713 23.7633C23.849 24.0273 23.5036 24.1238 23.1998 23.979L18.1891 21.5896C17.8853 21.4448 17.7382 21.1134 17.8605 20.8494Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.4089 18.2832C21.2797 18.5438 21.2984 18.8151 21.6073 18.9692L27.1604 21.741C27.4693 21.8951 27.8051 21.8336 27.9342 21.573C28.0634 21.3124 27.937 20.9513 27.6282 20.7972L22.2019 18.0902C21.8931 17.9362 21.538 18.0226 21.4089 18.2832Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7631 20.9816L17.7631 15.565L18.8133 15.565L18.8133 20.9816L17.7631 20.9816Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6397 19.6615C13.3497 19.6669 13.1103 19.4353 13.1049 19.1442L13.0001 13.5241C12.9947 13.233 13.2253 12.9925 13.5153 12.9871C13.8053 12.9816 14.0448 13.2132 14.0502 13.5044L14.1549 19.1244C14.1604 19.4156 13.9297 19.656 13.6397 19.6615Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6486 19.3517C22.3586 19.3535 22.122 19.119 22.1202 18.8277L22.0883 13.7292C22.0864 13.4379 22.3201 13.2004 22.6101 13.1986C22.9001 13.1967 23.1367 13.4313 23.1385 13.7225L23.1704 18.8211C23.1722 19.1123 22.9386 19.3499 22.6486 19.3517Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.7887 13.7332C22.9099 13.9978 22.796 14.314 22.5343 14.4395L18.6678 16.2943C18.4061 16.4199 18.0957 16.3072 17.9745 16.0426C17.8534 15.778 17.9673 15.4617 18.229 15.3362L22.0955 13.4814C22.3572 13.3559 22.6676 13.4686 22.7887 13.7332Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1819 13.4087C13.3037 13.1445 13.6315 13.0399 13.914 13.1751L18.5743 15.4051C18.8568 15.5403 18.9871 15.8641 18.8652 16.1283C18.7434 16.3924 18.4156 16.497 18.133 16.3618L13.4728 14.1318C13.1903 13.9966 13.06 13.6728 13.1819 13.4087Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.045 13.6415C12.9278 13.3751 13.0479 13.0637 13.3132 12.9461L17.5999 11.0451C17.8652 10.9274 18.1752 11.048 18.2924 11.3144C18.4096 11.5808 18.2895 11.8921 18.0242 12.0098L13.7375 13.9108C13.4722 14.0284 13.1621 13.9078 13.045 13.6415Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3753 11.3306C17.2591 11.5974 17.379 11.9113 17.6431 12.0318L22.1957 14.1079C22.4598 14.2283 22.768 14.1096 22.8842 13.8428C23.0004 13.5759 23.0582 13.3482 22.7942 13.2278L18.0897 11.0701C17.8256 10.9497 17.4915 11.0638 17.3753 11.3306Z"
            fill="#3D3D3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
            fill="#DFBC00"
        />
    </svg>
);

export const hovered: JSX.Element = (
    <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <path d="M23.2291 28.3132L23.0648 23.2337L27.4843 21.1799L27.7217 25.7826L23.2291 28.3132Z" fill="#3D3D3D" />
            <path d="M18.2148 20.9728L18.0505 15.8933L22.47 13.8395L22.7074 18.4422L18.2148 20.9728Z" fill="#3D3D3D" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7892 26.0359L17.7892 20.6193L18.8394 20.6193L18.8394 26.0359L17.7892 26.0359Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6165 18.382C13.9066 18.3799 14.1433 18.6143 14.1454 18.9055L14.178 23.4839C14.18 23.7751 13.9466 24.0129 13.6566 24.015C13.3666 24.017 13.1298 23.7827 13.1277 23.4914L13.0952 18.9131C13.0931 18.6219 13.3265 18.3841 13.6165 18.382Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9568 18.7191C23.0779 18.9837 22.9577 19.303 22.6883 19.4322L18.7076 21.3418C18.4382 21.471 18.1216 21.3613 18.0004 21.0967C17.8792 20.8322 17.9994 20.5129 18.2688 20.3836L22.2496 18.4741C22.519 18.3448 22.8356 18.4545 22.9568 18.7191Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1967 23.3289C13.3343 23.0727 13.6511 22.9802 13.9044 23.1223L18.3304 25.6056C18.5837 25.7477 18.6776 26.0706 18.5401 26.3268C18.4026 26.583 18.0857 26.6755 17.8324 26.5333L13.4067 23.9658C13.1534 23.8237 13.0467 23.4598 13.1967 23.3289Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2077 18.4629C13.3295 18.1987 13.6526 18.092 13.9295 18.2246L18.4964 20.4122C18.7733 20.5448 18.8991 20.8665 18.7773 21.1308C18.6556 21.395 18.3325 21.5017 18.0556 21.369L13.4886 19.1814C13.2118 19.0488 13.086 18.7271 13.2077 18.4629Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4417 28.4224L22.4417 22.9522L23.492 22.9522L23.492 28.4224L22.4417 28.4224Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.4752 20.7812C27.7653 20.7814 28.0002 21.0176 28 21.3088L27.9968 25.8494C27.9966 26.1406 27.7613 26.3765 27.4713 26.3763C27.1813 26.3761 26.9463 26.1399 26.9466 25.8487L26.9497 21.3081C26.9499 21.0169 27.1852 20.781 27.4752 20.7812Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.3153 28.7253C22.1786 28.4687 22.2737 28.1463 22.5276 28.005L27.1563 25.4305C27.4102 25.2893 27.7268 25.3828 27.8635 25.6394C28.0002 25.896 27.9051 26.2185 27.6512 26.3597L23.0225 28.9342C22.7686 29.0754 22.452 28.9819 22.3153 28.7253Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.4674 21.174C27.5885 21.4386 27.4746 21.7548 27.2129 21.8803L23.3464 23.7351C23.0847 23.8607 22.7744 23.748 22.6532 23.4834C22.532 23.2188 22.646 22.9025 22.9077 22.777L26.7742 20.9222C27.0359 20.7967 27.3462 20.9094 27.4674 21.174Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.6697 25.8557C17.7977 25.5944 18.1109 25.4901 18.3691 25.6227L22.966 27.9828C23.2242 28.1154 23.3297 28.4347 23.2017 28.696C23.0736 28.9572 22.7604 29.0616 22.5022 28.929L17.9053 26.5688C17.6471 26.4362 17.5416 26.117 17.6697 25.8557Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8605 20.8494C17.9828 20.5854 18.3282 20.4889 18.632 20.6337L23.6427 23.0231C23.9465 23.1679 24.0936 23.4993 23.9713 23.7633C23.849 24.0273 23.5036 24.1238 23.1998 23.979L18.1891 21.5896C17.8853 21.4448 17.7382 21.1134 17.8605 20.8494Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.4089 18.2832C21.2797 18.5438 21.2984 18.8151 21.6073 18.9692L27.1604 21.741C27.4693 21.8951 27.8051 21.8336 27.9342 21.573C28.0634 21.3124 27.937 20.9513 27.6282 20.7972L22.2019 18.0902C21.8931 17.9362 21.538 18.0226 21.4089 18.2832Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.7631 20.9816L17.7631 15.565L18.8133 15.565L18.8133 20.9816L17.7631 20.9816Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6397 19.6615C13.3497 19.6669 13.1103 19.4353 13.1049 19.1442L13.0001 13.5241C12.9947 13.233 13.2253 12.9925 13.5153 12.9871C13.8053 12.9816 14.0448 13.2132 14.0502 13.5044L14.1549 19.1244C14.1604 19.4156 13.9297 19.656 13.6397 19.6615Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.6486 19.3517C22.3586 19.3535 22.122 19.119 22.1202 18.8277L22.0883 13.7292C22.0864 13.4379 22.3201 13.2004 22.6101 13.1986C22.9001 13.1967 23.1367 13.4313 23.1385 13.7225L23.1704 18.8211C23.1722 19.1123 22.9386 19.3499 22.6486 19.3517Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.7887 13.7332C22.9099 13.9978 22.796 14.314 22.5343 14.4395L18.6678 16.2943C18.4061 16.4199 18.0957 16.3072 17.9745 16.0426C17.8534 15.778 17.9673 15.4617 18.229 15.3362L22.0955 13.4814C22.3572 13.3559 22.6676 13.4686 22.7887 13.7332Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1819 13.4087C13.3037 13.1445 13.6315 13.0399 13.914 13.1751L18.5743 15.4051C18.8568 15.5403 18.9871 15.8641 18.8652 16.1283C18.7434 16.3924 18.4156 16.497 18.133 16.3618L13.4728 14.1318C13.1903 13.9966 13.06 13.6728 13.1819 13.4087Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.045 13.6415C12.9278 13.3751 13.0479 13.0637 13.3132 12.9461L17.5999 11.0451C17.8652 10.9274 18.1752 11.048 18.2924 11.3144C18.4096 11.5808 18.2895 11.8921 18.0242 12.0098L13.7375 13.9108C13.4722 14.0284 13.1621 13.9078 13.045 13.6415Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.3753 11.3306C17.2591 11.5974 17.379 11.9113 17.6431 12.0318L22.1957 14.1079C22.4598 14.2283 22.768 14.1096 22.8842 13.8428C23.0004 13.5759 23.0582 13.3482 22.7942 13.2278L18.0897 11.0701C17.8256 10.9497 17.4915 11.0638 17.3753 11.3306Z"
                fill="#3D3D3D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
                fill="#DFBC00"
            />
        </g>
        <defs>
            <filter id="filter0_d" x="0" y="0" width="46" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="5" />
                <feGaussianBlur stdDeviation="1.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
);
