import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { IApplication, IOption, ISelection, IUser } from '../../../domain/portal-domain';
import { ModuleOption } from './module-option';
import { mainTheme } from '../../../styles/themes/main-theme';
import spinner from '../../../assets/spinner.gif';
import FreshServicesDialog from '../../nav-menu/fresh-service/fresh-service-dialog-component';

interface IStyleProps {
    isLoading: boolean;
}

const MainContainer = styled.div`
    filter: ${(styles: IStyleProps) => (styles?.isLoading ? mainTheme.blur.loading : mainTheme.blur.noBlur)};
    -webkit-filter: ${(styles: IStyleProps) => (styles?.isLoading ? mainTheme.blur.loading : mainTheme.blur.noBlur)};
`;

const Spinner = styled.img`
    width: ${mainTheme.widths.loadingSpinnerWidth};
    height: ${mainTheme.heights.loadingSpinnerHeight};
`;

const LabelContainer = styled.div`
    line-height: ${mainTheme.lineHeights.h2};
    color: ${mainTheme.colors.inactiveInput};
    padding-bottom: ${mainTheme.margins.normal};
`;

const Loader = styled.div`
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 40%;
`;

const SelectionContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const ActiveText = styled.span`
    line-height: ${mainTheme.lineHeights.h2};
    color: ${mainTheme.colors.grey};
    padding: ${mainTheme.margins.big} 0;
`;

export interface IModuleSelector {
    availableModules: IOption[];
    currentSelection: ISelection;
    allAppsInInstallation: IApplication[];
    user: IUser;
    isLoadingModules: boolean;
    onNotSupportedBrowser: (isNotSupported: boolean) => void;
}

export const ModuleSelector: React.FC<IModuleSelector> = ({
    user,
    availableModules,
    currentSelection,
    isLoadingModules,
    allAppsInInstallation,
    onNotSupportedBrowser,
}) => {
    const [currentlySelected, setCurrentlySelected] = useState({} as IOption);

    return (
        <>
            {isLoadingModules ? (
                <Loader>
                    <Spinner src={spinner} alt="loading..." />
                </Loader>
            ) : null}
            <FreshServicesDialog userName={user} />
            <MainContainer isLoading={isLoadingModules}>
                <LabelContainer>
                    {availableModules.length !== 0 && <ActiveText>Select application</ActiveText>}
                </LabelContainer>
                <SelectionContainer>
                    {allAppsInInstallation.map((option: IApplication, index: number) => (
                        <ModuleOption
                            key={`${index}-${option.id}`}
                            appId={option.id}
                            label={option.name}
                            logoUrl={option.logoUrl}
                            availableModules={availableModules}
                            currentSelection={currentSelection}
                            selectedModule={currentlySelected}
                            onSelect={setCurrentlySelected}
                            onNotSupportedBrowser={onNotSupportedBrowser}
                        />
                    ))}
                </SelectionContainer>
            </MainContainer>
        </>
    );
};
