import React from 'react';
import { ICompany, IOption, ISelection, IUser, OptionType } from '../../../domain/portal-domain';
import { SelectionForm } from './selection-element/selection-form';
import { SearchResultsContainer } from './search-results/search-results-container';
import { ClickOutsideWrapper } from '../../click-outside/click-outside';
import { PageNotFound } from './page-not-found';

export interface IMainContainerProps {
    currentSelection: ISelection;
    allCompaniesWithInstallations: ICompany[];
    user: IUser;
    onSelectedOption: (option: IOption) => void;
    companyNotExisting: boolean;
    installationNotExisting: boolean;
    onNotSupportedBrowser: (isNotSupported: boolean) => void;
}

export const MainContainer: React.FC<IMainContainerProps> = ({
    currentSelection,
    allCompaniesWithInstallations,
    companyNotExisting,
    installationNotExisting,
    user,
    onSelectedOption,
    onNotSupportedBrowser,
}) => {
    return !currentSelection.searchText ? (
        <div>
            {companyNotExisting ? (
                <PageNotFound />
            ) : (
                <SelectionForm
                    currentSelection={currentSelection}
                    companiesWithInstallations={allCompaniesWithInstallations}
                    user={user}
                    onSelectedOption={onSelectedOption}
                    installationNotExisting={installationNotExisting}
                    onNotSupportedBrowser={onNotSupportedBrowser}
                />
            )}
        </div>
    ) : (
        <ClickOutsideWrapper handleClickOutside={() => onSelectedOption({ type: OptionType.NONE } as IOption)}>
            <SearchResultsContainer currentSelection={currentSelection} onSelectedOption={onSelectedOption} />
        </ClickOutsideWrapper>
    );
};
