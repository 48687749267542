import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { mainTheme, px } from '../../styles/themes/main-theme';

const SpinAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const Container = styled.div<ISpinnerStyles>`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: ${mainTheme.margins.normal};

    .loader {
        border: ${(props) => px(props.thickness)} solid ${mainTheme.colors.white};
        border-radius: 50%;
        width: ${(props) => px(props.size)};
        height: ${(props) => px(props.size)};
        border-top: ${(props) => px(props.thickness)} solid ${mainTheme.colors.lightBlue};
        border-bottom: ${(props) => px(props.thickness)} solid ${mainTheme.colors.lightBlue};
        box-shadow: 0 0 10px ${mainTheme.colors.darkerGrey};
        -webkit-animation: ${SpinAnimation} 1.25s linear infinite; /* Safari */
        animation: ${SpinAnimation} 1.25s linear infinite;
    }

    .text {
        color: ${mainTheme.colors.lightBlue};
        font-size: ${mainTheme.fontSizes.bigger};
    }
`;

export interface ISpinnerStyles {
    thickness: number;
    size: number;
}

export interface ISpinner {
    text: string;
    styles?: ISpinnerStyles;
}

export const Spinner: React.FC<ISpinner> = (props) => (
    <Container size={props.styles?.size || 100} thickness={props.styles?.thickness || 15}>
        <div className="loader" />
        <span className="text">{props.text}</span>
    </Container>
);
