import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import { MsalProvider } from '@azure/msal-react';
import { AuthHelper } from '@axbit/bridge-auth';
import { SentryFacade } from './utils/sentry-facade';
import setLoggingLevel from './utils/logging-setup';

setLoggingLevel();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '';
AuthHelper.setInstance(
    process.env.REACT_APP_B2C_CLIENTID,
    window.location.origin,
    `https://${process.env.REACT_APP_AZURE_DIRECTORY}.onmicrosoft.com/${process.env.REACT_APP_B2C_BRIDGE_PORTAL_BACKEND_CLIENTID}/Portal.Backend`,
    process.env.REACT_APP_AZURE_DIRECTORY,
    window.location.pathname + window.location.hash
);
const msalInstance = AuthHelper.getInstance().getPublicClientApplication();
SentryFacade.init();

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <BrowserRouter basename={baseUrl}>
            <App msalInstance={msalInstance} />
        </BrowserRouter>
    </MsalProvider>,
    document.getElementById('root')
);
