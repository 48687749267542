import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { mainTheme } from '../../../styles/themes/main-theme';
import { notificationImportanceHighIcon, notificationImportanceMediumIcon, notificationImportanceLowIcon } from '../../../ui-icons/components/notifications';
import { minimize, close } from '../../../ui-icons/illustrations';
import { INotificationViewModel } from '../../../data-providers/notifications';

const ModalContainer = styled.div`
    background: ${mainTheme.colors.white};
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    min-height: ${mainTheme.heights.notificationsHeight};
    box-shadow: ${(styles: IStyleProps) => (styles.showShadow ? `0px 0px 5px ${mainTheme.colors.shadow}` : null)};
    border-radius: 3px;
`;

const CloseIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

const MinimizeIcon = styled.div`
    position: absolute;
    top: 9px;
    right: ${(styles: IStyleProps) => (styles.isCloseIconVisible ? '45px' : '20px')};
    cursor: pointer;
`;

const Header = styled.div`
    padding-top: 20px;
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
`;

const Icon = styled.div`
    padding-right: 15px;
    margin-top: -2px;
`;

const Body = styled.div`
    padding-top: 15px;
`;
const Footer = styled.div``;
const Link = styled.div`
    padding-top: 15px;
    padding-bottom: ${mainTheme.containerMargins.small};
    text-decoration-line: underline;
    color: ${mainTheme.colors.bridgePrimary};
    cursor: pointer;
`;

export interface IStyleProps {
    showShadow: boolean;
    isCloseIconVisible: boolean;
}

export interface INotificationProps {
    notification: INotificationViewModel;
    showShortLabel: boolean;
    showShadow: boolean;
    showCancelIcon: boolean;
    onHide: (id: number) => void;
}

export const Notification: React.FC<INotificationProps> = React.memo(
    ({ notification, showCancelIcon, showShadow, showShortLabel, onHide }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const onClick = (notification: INotificationViewModel) => {
            onHide(notification.notificationId);
        };
        const onExpanderClick = () => {
            setIsExpanded(!isExpanded);
        };

        const closeIcon = showCancelIcon ? <CloseIcon onClick={() => onClick(notification)}>{close}</CloseIcon> : null;
        const warningIcon = notification.importanceLevel==='High'?notificationImportanceHighIcon:( notification.importanceLevel==='Medium'?notificationImportanceMediumIcon:notificationImportanceLowIcon)
        const headerTextColor = notification.importanceLevel==='High'?mainTheme.colors.bridgeRed:( notification.importanceLevel==='Medium'?mainTheme.colors.bridgeOrange:mainTheme.colors.bridgePrimary)

        const HeaderText = styled.div`
            font-weight: bold;
            min-width: 240px;
            color: ${headerTextColor};
            padding-right: ${mainTheme.margins.bigger};
        `;

        return (
            <ModalContainer showShadow={showShadow} isCloseIconVisible={showCancelIcon} key={'notificationContainer_' + notification.notificationId}>
                <Header>
                    <Icon>{warningIcon}</Icon>
                    <HeaderText>{notification.title.toUpperCase()}</HeaderText>                    
                    {isExpanded ? (
                        <MinimizeIcon isCloseIconVisible={showCancelIcon} showShadow={showShadow} onClick={() => setIsExpanded(false)}>
                            {minimize}
                        </MinimizeIcon>
                    ) : null}
                    {closeIcon}
                </Header>
                {isExpanded || showShortLabel ? (
                    <Body>{isExpanded ? notification.longDescription : notification.shortDescription}</Body>
                ) : null}
                <Footer>
                    <Link onClick={onExpanderClick}>{isExpanded ? 'See less' : 'See more'}</Link>
                </Footer>
            </ModalContainer>
        );
    }
);
