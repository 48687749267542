import * as React from "react";

export const notAvailable: JSX.Element = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1635 28.8366 4.00004 20 4.00004C11.1635 4.00004 4.00002 11.1635 4.00002 20C4.00002 28.8366 11.1635 36 20 36Z"
      fill="#8E8E8E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8059 16.2856C22.3418 15.7187 22.6704 14.9537 22.6704 14.112C22.6704 12.3642 21.2535 10.9473 19.5057 10.9473C17.7578 10.9473 16.3409 12.3642 16.3409 14.112C16.3409 15.3795 17.086 16.4729 18.1621 16.9782C18.1727 17.5297 18.3012 18.3147 18.8438 18.9704C19.3296 19.5574 20.054 19.968 20.7298 20.3511C21.3727 20.7156 21.9718 21.0552 22.2798 21.498C22.9118 22.4064 22.5563 23.9862 22.2798 24.4207C22.27 24.4362 22.2575 24.4568 22.2423 24.4816C22.0713 24.7631 21.5668 25.5936 20.7396 25.882C19.5979 26.28 17.9834 25.9002 17.343 24.4207C16.1976 21.7745 15.4472 20.5897 14.1439 20.4317C13.5593 20.3608 13.7022 20.5925 14.0641 21.1793C14.5091 21.9009 15.2852 23.1594 15.4472 25.0526C15.7411 28.4855 18.5278 28.8836 19.0807 28.9626C19.0891 28.9638 19.0981 28.9651 19.1076 28.9665C19.7246 29.0553 22.6617 29.4783 24.3731 27.0668C26.1109 24.6181 26.5058 22.9594 26.1109 21.735C25.7159 20.5107 24.5818 19.3727 21.8059 17.1931V16.2856ZM21.411 14.112C21.411 15.1643 20.558 16.0174 19.5057 16.0174C18.4534 16.0174 17.6003 15.1643 17.6003 14.112C17.6003 13.0597 18.4534 12.2067 19.5057 12.2067C20.558 12.2067 21.411 13.0597 21.411 14.112Z"
      fill="#8E8E8E"
    />
  </svg>
);

export const available: JSX.Element = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1635 28.8366 4.00004 20 4.00004C11.1635 4.00004 4.00002 11.1635 4.00002 20C4.00002 28.8366 11.1635 36 20 36Z"
      fill="#61A600"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8059 16.2856C22.3418 15.7187 22.6704 14.9537 22.6704 14.112C22.6704 12.3642 21.2535 10.9473 19.5057 10.9473C17.7578 10.9473 16.3409 12.3642 16.3409 14.112C16.3409 15.3795 17.086 16.4729 18.1621 16.9782C18.1727 17.5297 18.3012 18.3147 18.8438 18.9704C19.3296 19.5574 20.054 19.968 20.7298 20.3511C21.3727 20.7156 21.9718 21.0552 22.2798 21.498C22.9118 22.4064 22.5563 23.9862 22.2798 24.4207C22.27 24.4362 22.2575 24.4568 22.2423 24.4816C22.0713 24.7631 21.5668 25.5936 20.7396 25.882C19.5979 26.28 17.9834 25.9002 17.343 24.4207C16.1976 21.7745 15.4472 20.5897 14.1439 20.4317C13.5593 20.3608 13.7022 20.5925 14.0641 21.1793C14.5091 21.9009 15.2852 23.1594 15.4472 25.0526C15.7411 28.4855 18.5278 28.8836 19.0807 28.9626C19.0891 28.9638 19.0981 28.9651 19.1076 28.9665C19.7246 29.0553 22.6617 29.4783 24.3731 27.0668C26.1109 24.6181 26.5058 22.9594 26.1109 21.735C25.7159 20.5107 24.5818 19.3727 21.8059 17.1931V16.2856ZM21.411 14.112C21.411 15.1643 20.558 16.0174 19.5057 16.0174C18.4534 16.0174 17.6003 15.1643 17.6003 14.112C17.6003 13.0597 18.4534 12.2067 19.5057 12.2067C20.558 12.2067 21.411 13.0597 21.411 14.112Z"
      fill="#3D3D3D"
    />
  </svg>
);

export const selected: JSX.Element = (
  <svg
    width="46"
    height="48"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 40C34.0457 40 43 31.0457 43 20C43 8.9543 34.0457 0 23 0C11.9543 0 3 8.9543 3 20C3 31.0457 11.9543 40 23 40ZM23 36C31.8366 36 39 28.8366 39 20C39 11.1635 31.8366 4.00004 23 4.00004C14.1635 4.00004 7.00002 11.1635 7.00002 20C7.00002 28.8366 14.1635 36 23 36Z"
        fill="#61A600"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8059 16.2856C25.3418 15.7187 25.6704 14.9537 25.6704 14.112C25.6704 12.3642 24.2535 10.9473 22.5057 10.9473C20.7578 10.9473 19.3409 12.3642 19.3409 14.112C19.3409 15.3795 20.086 16.4729 21.1621 16.9782C21.1727 17.5297 21.3012 18.3147 21.8438 18.9704C22.3296 19.5574 23.054 19.968 23.7298 20.3511C24.3727 20.7156 24.9718 21.0552 25.2798 21.498C25.9118 22.4064 25.5563 23.9862 25.2798 24.4207C25.27 24.4362 25.2575 24.4568 25.2423 24.4816C25.0713 24.7631 24.5668 25.5936 23.7396 25.882C22.5979 26.28 20.9834 25.9002 20.343 24.4207C19.1976 21.7745 18.4472 20.5897 17.1439 20.4317C16.5593 20.3608 16.7022 20.5925 17.0641 21.1793C17.5091 21.9009 18.2852 23.1594 18.4472 25.0526C18.7411 28.4855 21.5278 28.8836 22.0807 28.9626C22.0891 28.9638 22.0981 28.9651 22.1076 28.9665C22.7246 29.0553 25.6617 29.4783 27.3731 27.0668C29.1109 24.6181 29.5058 22.9594 29.1109 21.735C28.7159 20.5107 27.5818 19.3727 24.8059 17.1931V16.2856ZM24.411 14.112C24.411 15.1643 23.558 16.0174 22.5057 16.0174C21.4534 16.0174 20.6003 15.1643 20.6003 14.112C20.6003 13.0597 21.4534 12.2067 22.5057 12.2067C23.558 12.2067 24.411 13.0597 24.411 14.112Z"
        fill="#3D3D3D"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="46"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
