/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import styled from 'styled-components/macro';
import { mainTheme } from "../../../styles/themes/main-theme";
import { IUser } from '../../../domain/portal-domain';
import { searchIcon } from '../../../ui-icons/illustrations';



let API_KEY:any = "";
const FD_ENDPOINT = 'axessdigital';

const SearchContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 120px;
`;

const SearchResultsUl = styled('ul')`
  width: 293px;
  max-width: 293px;
  height: 420px;
  padding-left: 24px;
  position: absolute;
  top:260px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: ${mainTheme.colors.mainBackground};
    }

    &::-webkit-scrollbar-thumb {
      background:  ${mainTheme.colors.bridgescrollbar};
      border-radius: 5px;
      border: 1px solid ${mainTheme.colors.bridgescrollbar};
    }

    &::-webkit-scrollbar-thumb:hover {
        background:  ${mainTheme.colors.bridgescrollbar};
        border-radius: 5px;
        border: 1px solid ${mainTheme.colors.bridgescrollbar};
    }
`;

const SearchResultsLi = styled('li')`
  color: ${mainTheme.colors.bridgePrimary};
  padding: 5px;
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  white-space: normal;
  & a:hover {
    color: ${mainTheme.colors.bridgePrimary};
  }
`;

const Input = styled.input`

    font-family: 'Roboto';
    padding-left: 20px;
    padding-right: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 220px;
    margin-left: 30px;
    border: 0.5px solid ${mainTheme.colors.inactiveInput};
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
      background-color: ${mainTheme.colors.focusBackground};
      outline: none;
      border-color: ${mainTheme.colors.bridgePrimary};
    }
`;


const Search = styled.span`
    padding: 4px 0px 0px 0px;
    margin: 0px 0px 0px -40px;
    cursor:pointer;
`;

const Title = styled.div`
  position: absolute;
  top: 180px;
  font-family: 'Roboto';
  font-weight: bold;
  padding: 10px 33px 10px 30px;
  color: ${mainTheme.colors.lightGrey};
`;

const BrowseLink = styled.a`
  font-family: 'Roboto';
  font-weight: bold;
  color: ${mainTheme.colors.bridgePrimary};
  & a:hover {
    color: ${mainTheme.colors.lightGrey};
  }
`;



const Container = styled.div`
  font-family: 'Roboto';
  padding: 5px;
  background-color: ${mainTheme.colors.mainBackground};
  height: 640px;
`;

export interface ISearchRespone {
  id: number;
  title: string;
  description: string;
  external_url: string;
}

export interface FreshServicessAPIGetSearchProps {
  userName: IUser;
}

export interface FreshServicessAPIGetSearchState {
  searchResponse: ISearchRespone[];
  searchTerm: string;
  loading: boolean;
  API_KEY:string;
}
class FreshServicessAPISearch extends React.Component<
  FreshServicessAPIGetSearchProps,
  FreshServicessAPIGetSearchState
> {
  constructor(props: FreshServicessAPIGetSearchProps, state: FreshServicessAPIGetSearchState) {
    super(props);
    this.state = {
      searchResponse: [],
      searchTerm: '',
      loading: false,
      API_KEY:'',
    };
    this._setSearchTerm = this._setSearchTerm.bind(this);
    this._getAllSearchTerms = this._getAllSearchTerms.bind(this);
  }


    componentDidMount(): void {
      fetch('secrets')
      .then(response => {
      return response.json();
      })        
      .then(result => {
          this.setState({API_KEY:result.secret});
      })
      .catch(e => {
          console.log(e);
      });
    }


  _setSearchTerm(event: any) {
    this.setState({ searchTerm: event.target.value });
  }
  _getAllSearchTerms() {

    this.setState({ loading: true });
    const PATH = '/support/search/solutions.json?web=true?web=true&max_matches=20&term=' + this.state.searchTerm;
    const URL = 'https://' + FD_ENDPOINT + '.freshservice.com' + PATH;
    fetch(URL, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Authorization: 'Basic ' + btoa(API_KEY + ':X'),
      },
    })
      .then(async (response: any): Promise<any> => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        } else {
          let items: ISearchRespone[] = [];
          data['solutions'].forEach(function (item: ISearchRespone) {
            items.push({
              id: item.id,
              title: item.title,
              description: item.description,
              external_url: item.external_url,
            });
          });
          this.setState({ searchResponse: items });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }
  render() {
    return (
      <Container>
        <SearchContainer>
          <Input type="text" size={22} autoFocus onChange={this._setSearchTerm} /><Search onClick={this._getAllSearchTerms}>{searchIcon}</Search>
        </SearchContainer>
        <Title>Search our knowledge base, or <BrowseLink href="https://axessdigital.freshservice.com/support/home"
                      target="_blank">Browse help articles &gt;</BrowseLink></Title>
        {!this.state.loading ? (
          <SearchResultsUl className="SearchResultsUl">
            {this.state.searchResponse.length > 0 &&
              this.state.searchResponse.map((dt, index) => {
                return (
                  <SearchResultsLi className="SearchResultsLi" key={index}>
                    <a
                      href={'https://axessdigital.freshservice.com/support/solutions/articles/' + dt.id}
                      target="_blank"
                      rel="noreferrer">
                      {dt.title}
                    </a>
                  </SearchResultsLi>
                );
              })}
          </SearchResultsUl>
        ) : (
          <SearchResultsUl className="SearchResultsUl">
            <SearchResultsLi className="SearchResultsLi">Searching ...</SearchResultsLi>
          </SearchResultsUl>
        )}
      </Container>
    );
  }
}
export default FreshServicessAPISearch;
