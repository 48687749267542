import React from 'react';
import { ICompany, IOption, ISelection, IUser } from '../domain/portal-domain';
import { Header } from './containers/header';
import { MainContainer } from './containers/main-body/main-container';
import { INotificationViewModel } from '../data-providers/notifications';

export interface IHomeProps {
    currentSelection: ISelection;
    notifications: INotificationViewModel[];
    allCompaniesWithInstallations: ICompany[];
    companyNotExisting: boolean;
    installationNotExisting: boolean;
    user: IUser;
    onSelectedOption: (option: IOption) => void;
    onArchivedNotification: (id: number) => void;
    onRestoreNotifications: (notificationId: number) => void;
    onNotSupportedBrowser: (isNotSupported: boolean) => void;
}

export const Home: React.FC<IHomeProps> = ({
    notifications,
    currentSelection,
    allCompaniesWithInstallations,
    companyNotExisting,
    installationNotExisting,
    user,
    onSelectedOption,
    onArchivedNotification,
    onRestoreNotifications,
    onNotSupportedBrowser,
}) => {
    return (
        <>
            <Header
                notifications={notifications}
                currentSelection={currentSelection}
                onSelectedOption={onSelectedOption}
                onArchivedNotification={onArchivedNotification}
                onRestoreNotifications={onRestoreNotifications}
                allCompaniesWithInstallations={allCompaniesWithInstallations}
            />
            <MainContainer
                currentSelection={currentSelection}
                allCompaniesWithInstallations={allCompaniesWithInstallations}
                user={user}
                onSelectedOption={onSelectedOption}
                companyNotExisting={companyNotExisting}
                installationNotExisting={installationNotExisting}
                onNotSupportedBrowser={onNotSupportedBrowser}
            />
        </>
    );
};
