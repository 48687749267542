import * as React from "react";

export const warningIcon: JSX.Element = (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0.972198 13.1681C0.550514 13.8339 1.0289 14.7031 1.81702 14.7031H17.183C17.9711 14.7031 18.4495 13.8339 18.0278 13.1681L10.3448 1.03705C9.9522 0.417117 9.0478 0.417119 8.65518 1.03705L0.972198 13.1681ZM10.3636 11.5452C10.3636 11.9812 10.0102 12.3347 9.57416 12.3347H9.42584C8.98982 12.3347 8.63636 11.9812 8.63636 11.5452C8.63636 11.1092 8.98982 10.7558 9.42584 10.7558H9.57416C10.0102 10.7558 10.3636 11.1092 10.3636 11.5452ZM10.3636 8.31317C10.3636 8.79015 9.97697 9.17681 9.5 9.17681C9.02303 9.17681 8.63636 8.79015 8.63636 8.31317V5.88255C8.63636 5.40558 9.02303 5.01891 9.5 5.01891C9.97697 5.01891 10.3636 5.40558 10.3636 5.88255V8.31317Z"
        fill="#A20000"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const notificationImportanceHighIcon: JSX.Element = (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0.972198 13.1681C0.550514 13.8339 1.0289 14.7031 1.81702 14.7031H17.183C17.9711 14.7031 18.4495 13.8339 18.0278 13.1681L10.3448 1.03705C9.9522 0.417117 9.0478 0.417119 8.65518 1.03705L0.972198 13.1681ZM10.3636 11.5452C10.3636 11.9812 10.0102 12.3347 9.57416 12.3347H9.42584C8.98982 12.3347 8.63636 11.9812 8.63636 11.5452C8.63636 11.1092 8.98982 10.7558 9.42584 10.7558H9.57416C10.0102 10.7558 10.3636 11.1092 10.3636 11.5452ZM10.3636 8.31317C10.3636 8.79015 9.97697 9.17681 9.5 9.17681C9.02303 9.17681 8.63636 8.79015 8.63636 8.31317V5.88255C8.63636 5.40558 9.02303 5.01891 9.5 5.01891C9.97697 5.01891 10.3636 5.40558 10.3636 5.88255V8.31317Z"
        fill="#c96875"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const notificationImportanceMediumIcon: JSX.Element = (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0.972198 13.1681C0.550514 13.8339 1.0289 14.7031 1.81702 14.7031H17.183C17.9711 14.7031 18.4495 13.8339 18.0278 13.1681L10.3448 1.03705C9.9522 0.417117 9.0478 0.417119 8.65518 1.03705L0.972198 13.1681ZM10.3636 11.5452C10.3636 11.9812 10.0102 12.3347 9.57416 12.3347H9.42584C8.98982 12.3347 8.63636 11.9812 8.63636 11.5452C8.63636 11.1092 8.98982 10.7558 9.42584 10.7558H9.57416C10.0102 10.7558 10.3636 11.1092 10.3636 11.5452ZM10.3636 8.31317C10.3636 8.79015 9.97697 9.17681 9.5 9.17681C9.02303 9.17681 8.63636 8.79015 8.63636 8.31317V5.88255C8.63636 5.40558 9.02303 5.01891 9.5 5.01891C9.97697 5.01891 10.3636 5.40558 10.3636 5.88255V8.31317Z"
        fill="#f5b97d"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const notificationImportanceLowIcon: JSX.Element = (
  <svg
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0.972198 13.1681C0.550514 13.8339 1.0289 14.7031 1.81702 14.7031H17.183C17.9711 14.7031 18.4495 13.8339 18.0278 13.1681L10.3448 1.03705C9.9522 0.417117 9.0478 0.417119 8.65518 1.03705L0.972198 13.1681ZM10.3636 11.5452C10.3636 11.9812 10.0102 12.3347 9.57416 12.3347H9.42584C8.98982 12.3347 8.63636 11.9812 8.63636 11.5452C8.63636 11.1092 8.98982 10.7558 9.42584 10.7558H9.57416C10.0102 10.7558 10.3636 11.1092 10.3636 11.5452ZM10.3636 8.31317C10.3636 8.79015 9.97697 9.17681 9.5 9.17681C9.02303 9.17681 8.63636 8.79015 8.63636 8.31317V5.88255C8.63636 5.40558 9.02303 5.01891 9.5 5.01891C9.97697 5.01891 10.3636 5.40558 10.3636 5.88255V8.31317Z"
        fill="#00818a"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const archivedIcon: JSX.Element = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4889 2.47778L17.9444 0.611111C17.6444 0.233333 17.1889 0 16.6667 0H3.33333C2.81111 0 2.35556 0.233333 2.04444 0.611111L0.511111 2.47778C0.188889 2.85556 0 3.35556 0 3.88889V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V3.88889C20 3.35556 19.8111 2.85556 19.4889 2.47778ZM10 16.1111L3.88889 10H7.77778V7.77778H12.2222V10H16.1111L10 16.1111ZM2.35556 2.22222L3.25556 1.11111H16.5889L17.6333 2.22222H2.35556Z"
      fill="#3D3D3D"
    />
  </svg>
);
