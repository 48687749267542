import * as React from "react";

export const notAvailable: JSX.Element = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.0203 23.6712H19.7228V29.348H14.0203V23.6712Z" fill="#8E8E8E" />
    <path
      d="M19.6321 18.2704C19.5322 18.2704 19.4512 18.1898 19.4512 18.0902L19.4512 12.8898C19.4512 12.7903 19.3701 12.7096 19.2702 12.7096L18.0841 12.7096C17.9842 12.7096 17.9031 12.7903 17.9031 12.8898L17.9031 19.6317C17.9031 19.7312 17.9842 19.8118 18.0841 19.8118L19.451 19.8118L19.4513 19.8115H24.2507C24.3507 19.8115 24.4317 19.8921 24.4317 19.9917L24.4317 25.1932C24.4317 25.2927 24.3507 25.3733 24.2507 25.3733H20.9783C20.8783 25.3733 20.7973 25.454 20.7973 25.5535V26.7342C20.7973 26.8337 20.8783 26.9144 20.9783 26.9144H25.7987C25.8987 26.9144 25.9797 26.8337 25.9797 26.7342L25.9797 18.4513C25.9797 18.3518 25.8987 18.2711 25.7987 18.2711H24.4343L24.4336 18.2704H19.6321Z"
      fill="#8E8E8E"
    />
    <path
      d="M18.4918 10.708C18.5634 10.6312 18.6855 10.6312 18.7571 10.708L20.6919 12.7832C20.7994 12.8985 20.7172 13.086 20.5592 13.086H16.6897C16.5317 13.086 16.4496 12.8985 16.5571 12.7832L18.4918 10.708Z"
      fill="#8E8E8E"
    />
    <path
      d="M20.858 13.0992C20.858 12.9001 20.696 12.7388 20.4961 12.7388H16.7914C16.5915 12.7388 16.4294 12.9001 16.4294 13.0992V15.0244C16.4294 15.1407 16.5241 15.235 16.6409 15.235C16.6984 15.235 16.7534 15.2117 16.7932 15.1704L18.3829 13.5262C18.5253 13.3789 18.7621 13.3789 18.9045 13.5262L20.4942 15.1704C20.5341 15.2117 20.5891 15.235 20.6466 15.235C20.7634 15.235 20.858 15.1407 20.858 15.0244V13.0992Z"
      fill="#8E8E8E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1635 28.8366 4.00004 20 4.00004C11.1634 4.00004 4 11.1635 4 20C4 28.8366 11.1634 36 20 36Z"
      fill="#8E8E8E"
    />
  </svg>
);

export const available: JSX.Element = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.0203 23.6712H19.7228V29.348H14.0203V23.6712Z" fill="black" />
    <path
      d="M19.6321 18.2704C19.5322 18.2704 19.4512 18.1898 19.4512 18.0902L19.4512 12.8898C19.4512 12.7903 19.3701 12.7096 19.2702 12.7096L18.0841 12.7096C17.9842 12.7096 17.9031 12.7903 17.9031 12.8898L17.9031 19.6317C17.9031 19.7312 17.9842 19.8118 18.0841 19.8118L19.451 19.8118L19.4513 19.8115H24.2507C24.3507 19.8115 24.4317 19.8921 24.4317 19.9917L24.4317 25.1932C24.4317 25.2927 24.3507 25.3733 24.2507 25.3733H20.9783C20.8783 25.3733 20.7973 25.454 20.7973 25.5535V26.7342C20.7973 26.8337 20.8783 26.9144 20.9783 26.9144H25.7987C25.8987 26.9144 25.9797 26.8337 25.9797 26.7342L25.9797 18.4513C25.9797 18.3518 25.8987 18.2711 25.7987 18.2711H24.4343L24.4336 18.2704H19.6321Z"
      fill="black"
    />
    <path
      d="M18.4918 10.708C18.5634 10.6312 18.6855 10.6312 18.7571 10.708L20.6919 12.7832C20.7994 12.8985 20.7172 13.086 20.5592 13.086H16.6897C16.5317 13.086 16.4496 12.8985 16.5571 12.7832L18.4918 10.708Z"
      fill="black"
    />
    <path
      d="M20.858 13.0992C20.858 12.9001 20.696 12.7388 20.4961 12.7388H16.7914C16.5915 12.7388 16.4294 12.9001 16.4294 13.0992V15.0244C16.4294 15.1407 16.5241 15.235 16.6409 15.235C16.6984 15.235 16.7534 15.2117 16.7932 15.1704L18.3829 13.5262C18.5253 13.3789 18.7621 13.3789 18.9045 13.5262L20.4942 15.1704C20.5341 15.2117 20.5891 15.235 20.6466 15.235C20.7634 15.235 20.858 15.1407 20.858 15.0244V13.0992Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1635 28.8366 4.00004 20 4.00004C11.1634 4.00004 4 11.1635 4 20C4 28.8366 11.1634 36 20 36Z"
      fill="#A20000"
    />
  </svg>
);

export const selected: JSX.Element = (
  <svg
    width="46"
    height="48"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path d="M17.0203 23.6712H22.7228V29.348H17.0203V23.6712Z" fill="black" />
      <path
        d="M22.6321 18.2704C22.5322 18.2704 22.4512 18.1898 22.4512 18.0902L22.4512 12.8898C22.4512 12.7903 22.3701 12.7096 22.2702 12.7096L21.0841 12.7096C20.9842 12.7096 20.9031 12.7903 20.9031 12.8898L20.9031 19.6317C20.9031 19.7312 20.9842 19.8118 21.0841 19.8118L22.451 19.8118L22.4513 19.8115H27.2507C27.3507 19.8115 27.4317 19.8921 27.4317 19.9917L27.4317 25.1932C27.4317 25.2927 27.3507 25.3733 27.2507 25.3733H23.9783C23.8783 25.3733 23.7973 25.454 23.7973 25.5535V26.7342C23.7973 26.8337 23.8783 26.9144 23.9783 26.9144H28.7987C28.8987 26.9144 28.9797 26.8337 28.9797 26.7342L28.9797 18.4513C28.9797 18.3518 28.8987 18.2711 28.7987 18.2711H27.4343L27.4336 18.2704H22.6321Z"
        fill="black"
      />
      <path
        d="M21.4918 10.708C21.5634 10.6312 21.6855 10.6312 21.7571 10.708L23.6919 12.7832C23.7994 12.8985 23.7172 13.086 23.5592 13.086H19.6897C19.5317 13.086 19.4496 12.8985 19.5571 12.7832L21.4918 10.708Z"
        fill="black"
      />
      <path
        d="M23.858 13.0992C23.858 12.9001 23.696 12.7388 23.4961 12.7388H19.7914C19.5915 12.7388 19.4294 12.9001 19.4294 13.0992V15.0244C19.4294 15.1407 19.5241 15.235 19.6409 15.235C19.6984 15.235 19.7534 15.2117 19.7932 15.1704L21.3829 13.5262C21.5253 13.3789 21.7621 13.3789 21.9045 13.5262L23.4942 15.1704C23.5341 15.2117 23.5891 15.235 23.6466 15.235C23.7634 15.235 23.858 15.1407 23.858 15.0244V13.0992Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 40C34.0457 40 43 31.0457 43 20C43 8.9543 34.0457 0 23 0C11.9543 0 3 8.9543 3 20C3 31.0457 11.9543 40 23 40ZM23 36C31.8366 36 39 28.8366 39 20C39 11.1635 31.8366 4.00004 23 4.00004C14.1634 4.00004 7 11.1635 7 20C7 28.8366 14.1634 36 23 36Z"
        fill="#A20000"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="46"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
