import * as React from 'react';

export const disabled: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM19.9999 36.0006C28.8365 36.0006 35.9999 28.8372 35.9999 20.0006C35.9999 11.1641 28.8365 4.00064 19.9999 4.00064C11.1634 4.00064 3.99992 11.1641 3.99992 20.0006C3.99992 28.8372 11.1634 36.0006 19.9999 36.0006Z"
            fill="#8E8E8E"
        />
        <path d="M16.1091 16.8699H12.1792V26.0657H16.1091V16.8699Z" fill="#8E8E8E" />
        <path d="M27.7417 14.4351H23.812V26.0675H27.7417V14.4351Z" fill="#8E8E8E" />
        <path d="M21.9257 12H17.9956V26.0689H21.9257V12Z" fill="#8E8E8E" />
        <path d="M28 27.6504H12V28.4925H28V27.6504Z" fill="#8E8E8E" />
    </svg>
);

export const enabled: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM19.9999 36.0006C28.8365 36.0006 35.9999 28.8372 35.9999 20.0006C35.9999 11.1641 28.8365 4.00064 19.9999 4.00064C11.1634 4.00064 3.99992 11.1641 3.99992 20.0006C3.99992 28.8372 11.1634 36.0006 19.9999 36.0006Z"
            fill="#009AA6"
            fillOpacity="0.4"
        />
        <path d="M16.1091 16.8699H12.1792V26.0657H16.1091V16.8699Z" fill="#3D3D3D" />
        <path d="M27.7419 14.4351H23.8123V26.0675H27.7419V14.4351Z" fill="#3D3D3D" />
        <path d="M21.9257 12H17.9956V26.0689H21.9257V12Z" fill="#3D3D3D" />
        <path d="M28 27.6504H12V28.4925H28V27.6504Z" fill="#3D3D3D" />
    </svg>
);

export const hovered: JSX.Element = (
    <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM19.9999 36.0006C28.8365 36.0006 35.9999 28.8372 35.9999 20.0006C35.9999 11.1641 28.8365 4.00064 19.9999 4.00064C11.1634 4.00064 3.99992 11.1641 3.99992 20.0006C3.99992 28.8372 11.1634 36.0006 19.9999 36.0006Z"
                fill="#009AA6"
                fillOpacity="0.4"
            />
            <path d="M16.1091 16.8699H12.1792V26.0657H16.1091V16.8699Z" fill="#3D3D3D" />
            <path d="M27.7419 14.4351H23.8123V26.0675H27.7419V14.4351Z" fill="#3D3D3D" />
            <path d="M21.9257 12H17.9956V26.0689H21.9257V12Z" fill="#3D3D3D" />
            <path d="M28 27.6504H12V28.4925H28V27.6504Z" fill="#3D3D3D" />
        </g>
        <defs>
            <filter id="filter0_d" x="0" y="0" width="46" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="5" />
                <feGaussianBlur stdDeviation="1.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
);
