import React, { useState } from "react";
import { Notification } from "./notification";
import { ArchivedNotification } from "./archived-notification";
import { INotificationViewModel } from "../../../data-providers/notifications";

export interface INotificationsContainerProps {
  notifications: INotificationViewModel[];
  onArchivedNotification: (id: number) => void;
  onRestoreNotifications: (notificationId: number) => void;
}

export const NotificationsContainer: React.FC<INotificationsContainerProps> = ({
  notifications,
  onArchivedNotification,
  onRestoreNotifications,
}) => {
  const [archivedNotificiationId, setArchivedNotificationId] = useState<number>(
    0
  );
  const [
    shouldShowArchivedNotification,
    setShouldShowArchivedNotification,
  ] = useState(false);

  const hideNotification = (id: number) => {
    onArchivedNotification(id);
    setArchivedNotificationId(id);
    setShouldShowArchivedNotification(true);
  };

  const undoArchivization = (notificationId: number) => {
    onRestoreNotifications(notificationId);
  };

  const archivedNotificationsComponent = (
    <ArchivedNotification
      key={"archived_" + archivedNotificiationId}
      isOpen={shouldShowArchivedNotification}
      notificationId={archivedNotificiationId}
      onUndo={undoArchivization}
      onClose={() => setShouldShowArchivedNotification(false)}
    />
  );

  const displayableNotifications =
    notifications && notifications.length > 0
      ? notifications.map((not: INotificationViewModel) => {
          return (
            <Notification
              key={"notification_" + not.notificationId}
              showShortLabel={true}
              showShadow={true}
              showCancelIcon={true}
              onHide={hideNotification}
              notification={not}
            />
          );
        })
      : null;

  return (
    <>
      {displayableNotifications}
      {archivedNotificationsComponent}
    </>
  );
};
