import React, { Component, FormEvent } from "react";
import onClickOutside from "react-onclickoutside";

export interface IClickOutsideComponentProps {
  clickOutsideHandler?: () => void;
}

class ClickOutsideComponent extends Component<IClickOutsideComponentProps> {
  public static defaultProps: Partial<IClickOutsideComponentProps> = {};
  handleClickOutside = (evt: FormEvent) => {
    this.props.clickOutsideHandler && this.props.clickOutsideHandler();
  };
  public render() {
    return (
      <div style={{ background: "none" }} className="click-outside-wrapper">
        {this.props.children}
      </div>
    );
  }
}

export const ClickOutsideWrapper = onClickOutside(ClickOutsideComponent);
