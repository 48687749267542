import React from "react";
import styled from "styled-components/macro";
import {
  ISelection,
  IOption,
  OptionType,
} from "../../../../domain/portal-domain";
import { EmptySearchResults } from "./empty-search-results";
import { SearchResults as SearchResultsComponent } from "./search-results";
import { mainTheme, animations } from "../../../../styles/themes/main-theme";
import { close } from "../../../../ui-icons/illustrations";

const SearchResults = styled.div`
  background: ${mainTheme.colors.mainBackground};
  border-top: 1px solid ${mainTheme.colors.lineColor};
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
`;

const Large = styled.div`
  margin-top: ${mainTheme.margins.bigger};
  margin-bottom: 7rem;
  position: absolute;
  width: 100%;
  max-height: ${(styles: IStyleProps) => styles.innerHeight - 160 + "px"};
  overflow-y: auto;
  overflow-x: hidden;
  background: ${mainTheme.colors.white};
  box-sizing: border-box;
  box-shadow: 0px 1px 4px ${mainTheme.colors.shadow},
    0px 0px 1px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  li:last-of-type {
    margin-bottom: ${mainTheme.margins.big};
  }
`;

const ModalContainer = styled.div`
  background: ${mainTheme.colors.white};
  position: relative;
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0);
  opacity: 0.3;
  z-index: 1;
  animation: ${animations.profileMenu.fadeIn} ease 0.5s;
`;

export interface IStyleProps {
  innerHeight: number;
}

export interface ISearchResultsContainerProps {
  currentSelection: ISelection;
  onSelectedOption: (option: IOption) => void;
}

export const SearchResultsContainer: React.FC<ISearchResultsContainerProps> = React.memo(
  ({ currentSelection, onSelectedOption }) => {
    const content =
      currentSelection.filteredOptions.length > 0 ? (
        <SearchResultsComponent
          currentSelection={currentSelection}
          onSelectedOption={onSelectedOption}
        />
      ) : (
        <EmptySearchResults
          currentSelection={currentSelection}
          onSelectedOption={onSelectedOption}
        />
      );

    const onClick = () => {
      onSelectedOption({ type: OptionType.NONE } as IOption);
    };

    return (
      <>
        <Overlay onClick={onClick}></Overlay>
        <SearchResults>
          <Large
            innerHeight={window.innerHeight}
            className="container-lg pl-0 pr-0"
          >
            <ModalContainer>
              <CloseIcon
                onClick={() => {
                  onSelectedOption({ type: OptionType.NONE } as IOption);
                }}
              >
                {close}
              </CloseIcon>
              {content}
            </ModalContainer>
          </Large>
        </SearchResults>
      </>
    );
  }
);
