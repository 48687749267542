import React, { useState } from "react";
import styled from "styled-components/macro";
import { mainTheme } from "../../../../styles/themes/main-theme";
import { close } from "../../../../ui-icons/illustrations";

const Container = styled.div`
  background: ${mainTheme.colors.white};
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  padding: 20px 15px 20px 20px;
`;

const Header = styled.div`
  font-size: ${mainTheme.fontSizes.big};
  font-weight: 700;
  color: ${mainTheme.colors.error};
  padding-bottom: 20px;
`;

const Message = styled.div`
  font-size: ${mainTheme.fontSizes.big};
  max-width: ${mainTheme.widths.alertMessage};
`;

const CloseButton = styled.a`
  display: block;
  float: right;
  color: ${mainTheme.colors.grey};
  cursor: pointer;
`;

export interface IAlertMessageProps {
  installationNotExisting: boolean;
}

export const AlertMessage: React.FC<IAlertMessageProps> = ({
  installationNotExisting,
}) => {
  const [showOptions, setShowOptions] = useState(true);
  let headerMessage;
  let bodyMessage;

  if (installationNotExisting) {
    headerMessage = "ERROR: INSTALLATION NOT FOUND";
    bodyMessage =
      "Select the right installation by using the drop down menu below.";
  }
  else {
    setShowOptions(false);
  }

  const closeClickHandler = () => {
    if (showOptions) {
      setShowOptions(false);
    } else {
      setShowOptions(true);
    }
  };

  return (
    <>
      {showOptions && (
        <Container>
          <div>
            <CloseButton onClick={closeClickHandler}>{close}</CloseButton>
            <Header>{headerMessage}</Header>
          </div>
          <Message>{bodyMessage}</Message>
        </Container>
      )}
    </>
  );
};
