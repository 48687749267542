/**
 * Function return array with list of uniq values.
 */
export function uniq<T, K extends keyof T>(
  array: T[],
  customObjAccessor?: (obj: T) => T[K]
) {
  const objAccessor = customObjAccessor ? customObjAccessor : (obj: T) => obj;
  return array.reduce((prev: any, curr: any) => {
    return prev.some((i: any) => objAccessor(i) === objAccessor(curr))
      ? prev
      : [...prev, curr];
  }, []);
}
