import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { IOption, ISearchResult, IUser } from '../../domain/portal-domain';
import { chevronDown } from '../../ui-icons/illustrations';
import { ClickOutsideWrapper } from '../click-outside/click-outside';
import { uniq } from '../../utils/arrays/uniq';
import Scrollspy from 'react-scrollspy';
import { mainTheme } from '../../styles/themes/main-theme';
import { sorter } from '../../data-providers/helpers';
import { getSearchResultForRecentSearches } from '../../data-providers/search-helpers';
import { setRecentSearch } from '../../data-providers/local-storage/recent-searches';

type IGroupedDropdownOption = { [key: string]: IOption[] };

export interface IStyleProps {
    width: string;
}

const Container = styled.div`
    padding-bottom: ${mainTheme.margins.bigger};
    max-width: ${mainTheme.widths.dropdownNormalAndUp};
    @media ${mainTheme.responsiveBreakpoints.phone} {
        width: ${mainTheme.widths.dropdownPhone};
    }
    @media ${mainTheme.responsiveBreakpoints.tablet} {
        width: ${mainTheme.widths.dropdownTablet};
    }
    @media ${mainTheme.responsiveBreakpoints.smallScreen} {
        width: ${mainTheme.widths.dropdownTablet};
    }
`;

const Header = styled.div`
    background-color: ${mainTheme.colors.white};
    padding-left: ${mainTheme.margins.big};
    font-weight: bold;
    width: 100%;
    font-size: ${mainTheme.fontSizes.big};
    line-height: 19px;
    display: flex;
    align-items: center;
    color: ${mainTheme.colors.lightGrey};
    box-shadow: 0px 0px 1px ${mainTheme.colors.black};
    border-radius: 4px;
`;

const IntroText = styled.span`
    color: ${mainTheme.colors.inactiveInput};
    font-weight: normal;
    padding-right: ${mainTheme.margins.small};
`;

const ExpandIcon = styled.span`
    cursor: pointer;
    margin: ${mainTheme.margins.normal} ${mainTheme.margins.big};
    margin-left: auto;
`;
const ListContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
`;

const OptionContainer = styled.div`
    position: absolute;
    background-color: ${mainTheme.colors.white};
    margin-top: 4px;
    width: 100%;
    box-shadow: 0px 1px 4px ${mainTheme.colors.shadow}, 0px 0px 1px ${mainTheme.colors.shadowInner};
    border-radius: 4px;
    display: flex;
    flex-grow: 1;
    max-height: 38vh;
    overflow-y: auto;

    .scrollspy {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 12px;
        overflow: auto;
        height: 100%;

        .current {
            background-color: ${mainTheme.colors.grey};
            border-radius: 50%;
            color: ${mainTheme.colors.white};
            margin: 0 5px;

            li {
                color: ${mainTheme.colors.white};
            }
        }

        .selected {
            color: ${mainTheme.colors.white};
        }

        @media ${mainTheme.responsiveBreakpoints.phone} {
            padding-right: 5px;
            right: -20px;
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 100%;
            width: calc(100% + 20px);
        }
    }
`;

const Input = styled.input`
    cursor: pointer;
    border: none;
    color: ${mainTheme.colors.bridgePrimary};
    font-weight: bold;
    padding-left: 3px;
    width: ${(styles: IStyleProps) => styles.width};
    &:focus {
        border-style: none;
        outline: none;
    }
`;

const OptionList = styled.ul`
    cursor: pointer;
    flex-grow: 1;
    overflow: auto;
    .option {
        padding: 0 5px;
        &.selected {
            background-color: ${mainTheme.colors.hoverBackground};
        }
    }

    @media ${mainTheme.responsiveBreakpoints.phone} {
        padding-right: 15px;
        right: -15px;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 100%;
        width: 250px;
    }
`;

const ScrollWrapper = styled.div`
    overflow: auto;
    width: 100%;
    margin-bottom: 1rem;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        overflow: hidden;
        width: 240px;
    }
`;

const LetterList = styled.ul`
    margin-bottom: 1rem;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        overflow: hidden;
    }
`;

const ListItem = styled.li`
    padding: ${mainTheme.margins.big} ${mainTheme.margins.bigger};
    list-style: none;
    color: ${mainTheme.colors.grey};
    display: flex;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover {
        background: ${mainTheme.colors.hoverBackground};
        color: ${mainTheme.colors.grey};
    }

    @media ${mainTheme.responsiveBreakpoints.phone} {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 250px;
    }
`;

const SelectedListItem = styled(ListItem)`
    background: ${mainTheme.colors.hoverBackground};
`;

const FlexElement = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media ${mainTheme.responsiveBreakpoints.phone} {
        max-width: 100%;
    }
`;

const Label = styled.div`
    padding-bottom: ${mainTheme.margins.big};
`;

const Option = styled.div`
    font-weight: 400;
    white-space: nowrap;
`;

const SubOption = styled.div`
    padding-left: 5px;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const DropdownLetter = styled.li`
    font-weight: bold;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${mainTheme.colors.bridgePrimary};
`;
const RecentSearchSeparator = styled.div`
    margin: 0 ${mainTheme.margins.bigger};
    border-bottom: 1px solid ${mainTheme.colors.menuUnderline};
`;

const RecentListItem = styled(ListItem)`
    color: ${mainTheme.colors.lightGrey};
`;

const RecentSelectedListItem = styled(SelectedListItem)`
    color: ${mainTheme.colors.lightGrey};
`;
export interface IDropdownSearchProps {
    scope: 'company' | 'installation';
    options: IOption[];
    label: string;
    user: IUser;
    selectedOption: IOption;
    onSelectionHandler: (option: IOption) => void;
}

export const DropdownSearch: React.FC<IDropdownSearchProps> = React.memo(
    ({ scope, options, label, selectedOption, user, onSelectionHandler }) => {
        const inputElement = useRef<HTMLInputElement | undefined>() as any;
        const sectionsContainer = useRef<HTMLDivElement | undefined>() as any;
        const [showOptions, setShowOptions] = useState(false);
        const [userInput, setUserInput] = useState('');
        const [currentLetter, setCurrentLetter] = useState('');
        const [filteredOptions, setFilteredOptions] = useState<IOption[]>([]);
        const [activeOption, setActiveOption] = useState(-1);
        const [recentSearches, setRecentSearches] = useState([] as ISearchResult[]);
        const [isUserTypingToggle, setUserTypingToggle] = useState(false);

        useEffect(() => {
            setFilteredOptions(options);
            if (selectedOption.key) {
                let userText = selectedOption.value;
                setUserInput(userText);
            } else {
                setUserInput('');
            }

            const searches: ISearchResult[] = getSearchResultForRecentSearches(user.email);
            let filteredSearches = [];
            filteredSearches = searches.filter((s) => {
                return options.find((o) => {
                    return o.type === s.type && o.id === s.id;
                });
            });
            if (JSON.stringify(filteredSearches) !== JSON.stringify(recentSearches)) {
                setRecentSearches(filteredSearches);
            }

            if (showOptions && (mainTheme.deviceBreakpoints.isPhone || mainTheme.deviceBreakpoints.isTablet)) {
                const elementId = scope + 'Dropdown';
                let pos = findPos(document.getElementById(elementId));

                setTimeout(() => {
                    if (pos) {
                        pos = pos - 40;
                        window.scroll({ top: pos, left: 0, behavior: 'smooth' });
                    }
                }, 50);
            }
        }, [options, selectedOption, recentSearches, user, showOptions, scope]);

        useEffect(() => {
            const selectedItem = document.getElementById('selectedItem');
            if (selectedItem && activeOption) {
                const parentNode = selectedItem.parentNode as HTMLElement;
                setCurrentLetter(parentNode.id);
                selectedItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                });
            }
        }, [activeOption]);

        const toggling = () => {
            if (showOptions) {
                setShowOptions(false);
                setActiveOption(-1);
            } else {
                setCurrentLetter('');
                setShowOptions(true);
                if (inputElement.current) {
                    inputElement.current.focus();
                }
            }
        };

        const findPos = (obj: any) => {
            var curtop = 0;
            if (obj.offsetParent) {
                do {
                    curtop += obj.offsetTop;
                } while ((obj = obj.offsetParent));
                return curtop;
            }
        };

        const onOptionClicked = (option: IOption) => () => {
            setFilteredOptions(options);
            setShowOptions(false);
            setUserInput(option.value);
            onSelectionHandler(option);
            setRecentSearch(option, user.email);
            setActiveOption(-1);
        };

        const onChange = (e: React.FormEvent<HTMLInputElement>) => {
            const userInput = e.currentTarget.value;

            const isUserTyping = userInput.length > 0 ? true : false;
            setUserTypingToggle(isUserTyping);

            const filteredOptions = options.filter((option) => option.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1);
            setFilteredOptions(filteredOptions);
            setShowOptions(true);
            setUserInput(userInput);
            setActiveOption(-1);
        };

        const onKeyDown = (e: React.KeyboardEvent) => {
            if (showOptions) {
                let option: IOption;
                const allOptions = [];
                if (!isUserTypingToggle) allOptions.push(...recentSearchesOptions);
                allOptions.push(...filteredOptions);
                option = allOptions[activeOption];

                if (option && e.key === 'Enter') {
                    setActiveOption(-1);
                    setShowOptions(false);
                    setUserInput('');
                    onSelectionHandler(option);
                    setRecentSearch(option, user.email);
                } else if (e.key === 'Enter') {
                    setActiveOption(-1);
                    setShowOptions(false);
                } else if (e.key === 'ArrowUp') {
                    if (activeOption === 0) {
                        return;
                    }
                    setActiveOption(activeOption - 1);
                } else if (e.key === 'ArrowDown') {
                    if (activeOption === allOptions.length - 1) {
                        return;
                    }
                    setActiveOption(activeOption + 1);
                }
            } else if (e.key === 'ArrowDown') {
                setShowOptions(true);
            }
        };

        const handleScrollInTo = (id: string) => {
            const el = document.getElementById(id);
            if (el && sectionsContainer && sectionsContainer.current) {
                sectionsContainer.current.scrollTo({
                    top: el.offsetTop,
                    behavior: 'smooth',
                });
            }
            setCurrentLetter(id);
        };

        const onUpdateHandler = (item: HTMLElement) => {
            const letter = item.id;
            const el = document.getElementById('letter-' + letter);
            if (el) {
                if (mainTheme.deviceBreakpoints.isPhone) {
                    const listEl = el.parentNode as HTMLElement;
                    if (listEl) {
                        listEl.scrollTop = el.offsetTop - 120;
                    }
                } else {
                    el.scrollIntoView();
                }
            }
        };

        const onMouseOver = (e: React.MouseEvent) => {
            const el = document.getElementById('selectedItem');
            if (el) {
                el.style.background = 'none';
            }
        };

        const onMouseLeave = (e: React.MouseEvent) => {
            const el = document.getElementById('selectedItem');
            if (el) {
                el.style.background = mainTheme.colors.hoverBackground;
            }
        };

        const introText =
            !userInput.length && filteredOptions.length > 0 ? (
                <>
                    <IntroText>{'Type to filter ' + (scope === 'company' ? 'companies' : 'installations')}</IntroText>
                </>
            ) : null;

        const inputField =
            filteredOptions.length > 0 ? (
                <Input
                    width={userInput.length > 0 ? '80%' : '5px'}
                    ref={inputElement}
                    type="text"
                    onChange={onChange}
                    autoComplete="off"
                    value={userInput}
                    placeholder=""
                    onKeyDown={onKeyDown}
                />
            ) : (
                <Input
                    type="text"
                    width="80%"
                    onChange={onChange}
                    value={userInput}
                    autoComplete="off"
                    placeholder="No options to choose from"
                />
            );

        const sortedOptions = filteredOptions.sort(sorter());

        const groupedOptions = sortedOptions.reduce((prev: IGroupedDropdownOption, curr) => {
            const letter = curr.value.substr(0, 1).toUpperCase();
            if (prev[letter]) {
                return {
                    ...prev,
                    [letter]: [...prev[letter], curr],
                };
            } else {
                return {
                    ...prev,
                    [letter]: [curr],
                };
            }
        }, {});
        const firstLetters = uniq(sortedOptions.map((i) => i.value.substr(0, 1).toUpperCase()));

        let recentSearchesOptions = recentSearches.filter((option) => option.type === scope.toUpperCase());

        recentSearchesOptions = recentSearchesOptions.slice(0, 3);

        const attributes = { id: 'list-of-sections', ref: sectionsContainer };

        let activeIndex = 0;
        return (
            <ClickOutsideWrapper
                clickOutsideHandler={() => {
                    setShowOptions(false);
                    setActiveOption(-1);
                }}>
                <Container>
                    <Label>{label}</Label>
                    <Header id={scope + 'Dropdown'} onClick={toggling}>
                        {inputField}
                        {introText}
                        <ExpandIcon onClick={toggling}>{chevronDown}</ExpandIcon>
                    </Header>
                    {showOptions && filteredOptions.length > 0 && (
                        <ListContainer>
                            <OptionContainer>
                                <ScrollWrapper {...(!mainTheme.deviceBreakpoints.isPhone ? attributes : {})}>
                                    <OptionList
                                        {...(mainTheme.deviceBreakpoints.isPhone ? attributes : {})}
                                        onMouseOver={onMouseOver}
                                        onMouseLeave={onMouseLeave}>
                                        {recentSearchesOptions.length > 0 && !isUserTypingToggle && (
                                            <section id="recentSearches">
                                                {recentSearchesOptions.map((option) => {
                                                    let tempActiveIndex = activeIndex;
                                                    activeIndex = activeIndex + 1;
                                                    let subOptionElement;

                                                    if (option.rootName) {
                                                        subOptionElement = <SubOption>{'(' + option.rootName + ')'}</SubOption>;
                                                    }
                                                    if (tempActiveIndex === activeOption) {
                                                        return (
                                                            <RecentSelectedListItem
                                                                onClick={onOptionClicked(option)}
                                                                key={Math.random()}
                                                                id="selectedItem">
                                                                <FlexElement>
                                                                    <Option>{option.value}</Option>
                                                                    {subOptionElement}
                                                                </FlexElement>
                                                            </RecentSelectedListItem>
                                                        );
                                                    } else {
                                                        return (
                                                            <RecentListItem onClick={onOptionClicked(option)} key={Math.random()}>
                                                                <FlexElement>
                                                                    <Option>{option.value}</Option>
                                                                    {subOptionElement}
                                                                </FlexElement>
                                                            </RecentListItem>
                                                        );
                                                    }
                                                })}
                                                <RecentSearchSeparator />
                                            </section>
                                        )}
                                        {Object.keys(groupedOptions).map((k) => (
                                            <section id={k} key={k}>
                                                {groupedOptions[k].map((option, index) => {
                                                    let tempActiveIndex = activeIndex;
                                                    activeIndex = activeIndex + 1;
                                                    let subOptionElement;
                                                    if (option.rootName) {
                                                        subOptionElement = <SubOption>{'(' + option.rootName + ')'}</SubOption>;
                                                    }
                                                    if (tempActiveIndex === activeOption) {
                                                        return (
                                                            <SelectedListItem
                                                                onClick={onOptionClicked(option)}
                                                                key={Math.random()}
                                                                id="selectedItem">
                                                                <FlexElement>
                                                                    <Option>{option.value}</Option>
                                                                    {subOptionElement}
                                                                </FlexElement>
                                                            </SelectedListItem>
                                                        );
                                                    } else {
                                                        return (
                                                            <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                                                                <FlexElement>
                                                                    <Option>{option.value}</Option>
                                                                    {subOptionElement}
                                                                </FlexElement>
                                                            </ListItem>
                                                        );
                                                    }
                                                })}
                                            </section>
                                        ))}
                                    </OptionList>
                                </ScrollWrapper>
                                <LetterList id="list-of-letters">
                                    <Scrollspy
                                        className="scrollspy"
                                        items={firstLetters}
                                        onUpdate={onUpdateHandler}
                                        currentClassName={currentLetter.length === 0 ? 'current' : ''}
                                        componentTag="div"
                                        rootEl={'#list-of-sections'}>
                                        {firstLetters.map((fl: any) => (
                                            <section id={'letter-' + fl} key={fl}>
                                                <DropdownLetter
                                                    key={fl}
                                                    id={fl}
                                                    className={fl === currentLetter ? 'current' : ''}
                                                    onClick={() => handleScrollInTo(fl)}>
                                                    {fl}
                                                </DropdownLetter>
                                            </section>
                                        ))}
                                    </Scrollspy>
                                </LetterList>
                            </OptionContainer>
                        </ListContainer>
                    )}
                </Container>
            </ClickOutsideWrapper>
        );
    }
);
