import 'core-js';
import 'whatwg-fetch';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { Layout } from './components/layout';
import { Home } from './components/home';
import { BrowserInfo } from './components/containers/module-selector/browser-info';
import { ICompany, ICurrentUser, IOption, ISearchResult, IUser, OptionType } from './domain/portal-domain';
import './styles/app-base.scss';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import {
    archiveNotification,
    fetchNotifications,
    unarchiveNotification,
    fetchCurrentCompaniesAndInstallationsForActor,
} from './data-providers/fetches';
import { getCompanyOption, getInstallationOptionsForCompany } from './data-providers/helpers';
import { USER_ADMIN_URL } from './domain/consts';
import { INotificationViewModel } from './data-providers/notifications';
import { ActiveUserApiAdapter } from './data-providers/api/active-user-api-adapter';
import { getUserWithRoles } from './data-providers/role-service';
import { Spinner } from './components/spinner';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';
import { usePostLoginRedirect } from './post-login-redirect';
import { SentryFacade } from './utils/sentry-facade';
import { shouldURLsBeString } from './utils/env';
import { useLogoutUrl } from './logout-url-handler';

const Loader = styled.div`
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

interface IRooting {
    companyId: string;
    installationId: string;
}

interface Selection {
    company: IOption;
    installation: IOption;
    filteredOptions: ISearchResult[];
    searchText: string;
}

const emptySelection: Selection = {
    company: {} as IOption,
    installation: {} as IOption,
    filteredOptions: [] as ISearchResult[],
    searchText: '',
};

export interface IAppProps {
    msalInstance: IPublicClientApplication;
}

export const App: React.FC<IAppProps> = (props: IAppProps) => {
    const history = useHistory();
    const location = useLocation();
    const activeUserApi = ActiveUserApiAdapter.getInstance();

    const [user, setUser] = useState<IUser>({
        name: '',
        email: '',
        roles: {},
    } as IUser);
    const [allNotifications, setAllNotifications] = useState([] as INotificationViewModel[]);
    const [notShownNotifications, setNotShownNotifications] = useState([] as INotificationViewModel[]);
    const [isAppLoading, setIsAppLoading] = useState(true);
    const [isNotSupportedBrowser, setIsNotSupportedBrowser] = useState(false);

    const [allCompaniesWithInstallations, setAllCompaniesWithInstallations] = useState(undefined as ICompany[] | undefined);
    const [companyNotExisting, setCompanyNotExisting] = useState(false);
    const [installationNotExisting, setInstallationNotExisting] = useState(false);

    const [currentSelection, setCurrentSelection] = useState(emptySelection);
    const isAuthenticated = useIsAuthenticated();

    const [authResult, setAuthResult] = useState(undefined as AuthenticationResult);

    usePostLoginRedirect(authResult);
    useLogoutUrl();

    useEffect(() => {
        props.msalInstance.handleRedirectPromise().then((response: AuthenticationResult) => {
            if (response !== null) {
                setAuthResult(response);
            }
        });

        fetchCurrentCompaniesAndInstallationsForActor().then((companies: ICompany[]) => {
            setAllCompaniesWithInstallations(companies);
        });
        activeUserApi.fetchCurrentUser().then((currentuser: ICurrentUser | null) => {
            if (currentuser === null) return;

            const user: IUser = getUserWithRoles(currentuser);

            fetchNotifications(user.id).then((notifications: INotificationViewModel[]) => {
                setAllNotifications(filterNotificationsToScope(notifications, currentSelection));
                setNotShownNotifications(filterNotificationsToScope(notifications, currentSelection).filter((x) => !x.wasNotified));
            });
            SentryFacade.setUser(user.email);
            setUser(user);
        });

        if (installationNotExisting) {
            history.replace('/' + currentSelection.company.key);
        }
    }, [props.msalInstance, activeUserApi, currentSelection, history, installationNotExisting, isAuthenticated]);

    useEffect(() => {
        if (allCompaniesWithInstallations && allCompaniesWithInstallations.length === 1) {
            if (location.pathname === '/') {
                history.replace('/' + allCompaniesWithInstallations[0].staticName);
            }
        }
    });

    const select = (selection: any) => {
        setIsAppLoading(true);
        const updatedSelection = {
            ...currentSelection,
            ...selection,
        };

        setCurrentSelection(updatedSelection);
    };

    const onNotSupportedBrowser = () => {
        setIsNotSupportedBrowser(true);
    };

    const redirect = (companyId?: number | string, installationId?: number | string) => {
        let url = '/';
        if (companyId) {
            url += companyId;
        }
        if (installationId) {
            url += '/' + installationId;
        }
        history.push(url);
    };

    const onSelectedOption = (option: IOption) => {
        if (option.type === OptionType.NONE) {
            select(emptySelection);
            redirect();
        } else if (option.type === OptionType.COMPANY) {
            select({
                company: option,
                installation: {} as IOption,
            });
            redirect(option.id);
        } else {
            let companyOption = option.rootId
                ? getCompanyOption(allCompaniesWithInstallations || ([] as ICompany[]), option.rootId)
                : currentSelection.company;
            select({
                company: companyOption,
                installation: option,
            });
            setInstallationNotExisting(false);
            redirect(companyOption.id, option.id);
        }
    };

    const onArchivedNotification = (id: number): void => {
        archiveNotification(id, user.id).then(async () => {
            const newNotifications = await fetchNotifications(user.id);
            setAllNotifications(filterNotificationsToScope(newNotifications, currentSelection));
            setNotShownNotifications(filterNotificationsToScope(newNotifications, currentSelection).filter((x) => !x.wasNotified));
        });
    };

    const onRestoreNotifications = (notificationId: number): void => {
        unarchiveNotification(user.id, notificationId).then(async () => {
            const newNotifications = await fetchNotifications(user.id);
            setAllNotifications(filterNotificationsToScope(newNotifications, currentSelection));
            setNotShownNotifications(filterNotificationsToScope(newNotifications, currentSelection).filter((x) => !x.wasNotified));
        });
    };

    const filterNotificationsToScope = (notifications: INotificationViewModel[], currentSelection: Selection): INotificationViewModel[] => {
        return notifications.filter(
            (x) =>
                x.isGlobal ||
                x.scopeCompanies.includes(currentSelection.company.id) ||
                x.scopeInstallations.includes(currentSelection.installation.id)
        );
    };

    const routing = (companyId: string, installationId: string): void => {
        if (user.email && allCompaniesWithInstallations && isAppLoading) {
            if (!companyId) {
                setIsAppLoading(false);
                return;
            }

            let companyName;
            let companyNumber;
            let installationName;
            let installationNumber;
            const company =
                Number(companyId) && Number(companyId) > 0
                    ? allCompaniesWithInstallations.find((x) => x.id.toString() === companyId)
                    : allCompaniesWithInstallations.find((x) => x.staticName === companyId);

            const companyOption = getCompanyOption(allCompaniesWithInstallations, company.id);
            companyName = company.staticName;
            companyNumber = company.id;
            if (!!installationId) {
                const installationOptions = getInstallationOptionsForCompany(company);
                const installationOption =
                    !!Number(installationId) && Number(installationId) > 0 && !!Number(companyId) && Number(companyId) > 0
                        ? installationOptions.find((x) => x.id.toString() === installationId)
                        : installationOptions.find((x) => x.key === installationId);

                installationName = installationOption.key;
                installationNumber = installationOption.id;
                if (!installationOption) {
                    setInstallationNotExisting(true);
                } else {
                    setCurrentSelection({
                        ...currentSelection,
                        company: companyOption || currentSelection.company,
                        installation: installationOption || currentSelection.installation,
                    });
                }
            } else if (company) {
                setCurrentSelection({
                    ...currentSelection,
                    company: companyOption || currentSelection.company,
                    installation: {} as IOption,
                });
            } else {
                setCompanyNotExisting(true);
            }

            if (shouldURLsBeString() && (!!Number(companyId) || !!Number(installationId))) {
                redirect(companyName, installationName || '');
            } else if (!shouldURLsBeString() && (!Number(companyId) || !Number(installationId))) {
                //URLs should be numeric
                redirect(companyNumber, installationNumber || null);
            }

            setIsAppLoading(false);
        }
    };

    return (
        <AuthenticatedTemplate>
            {isAppLoading ? (
                <Loader>
                    <Spinner text="Loading..." />
                </Loader>
            ) : null}

            {isNotSupportedBrowser ? (
                <BrowserInfo></BrowserInfo>
            ) : (
                <Layout
                    currentSelection={currentSelection}
                    allCompaniesWithInstallations={allCompaniesWithInstallations || ([] as ICompany[])}
                    user={user}
                    notifications={allNotifications}
                    isLoading={isAppLoading}
                    onSelectedOption={onSelectedOption}
                    onOptionsFiltered={(filteredOptions: ISearchResult[]) => {
                        setCurrentSelection({
                            ...currentSelection,
                            filteredOptions,
                        });
                    }}
                    onSearchTextChanged={(searchText: string) => {
                        setCurrentSelection({
                            ...currentSelection,
                            searchText,
                        });
                    }}
                    onArchivedNotification={onArchivedNotification}
                    companyNotExisting={companyNotExisting}>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={(props: RouteComponentProps<IRooting>) => {
                                routing('', '');
                                return <></>;
                            }}
                        />
                        <Route exact path={USER_ADMIN_URL}>
                            <Redirect to={USER_ADMIN_URL} />
                        </Route>
                        <Route
                            exact
                            path="/:companyId"
                            render={(props: RouteComponentProps<IRooting>) => {
                                routing(props.match.params.companyId, '');
                                return <></>;
                            }}
                        />
                        <Route
                            exact
                            path="/:companyId/:installationId"
                            render={(props: RouteComponentProps<IRooting>) => {
                                routing(props.match.params.companyId, props.match.params.installationId);
                                return <></>;
                            }}
                        />
                    </Switch>
                    <Home
                        currentSelection={currentSelection}
                        allCompaniesWithInstallations={allCompaniesWithInstallations || ([] as ICompany[])}
                        user={user}
                        notifications={notShownNotifications}
                        onSelectedOption={onSelectedOption}
                        onArchivedNotification={onArchivedNotification}
                        onRestoreNotifications={onRestoreNotifications}
                        companyNotExisting={companyNotExisting}
                        onNotSupportedBrowser={onNotSupportedBrowser}
                        installationNotExisting={false}
                    />
                </Layout>
            )}
        </AuthenticatedTemplate>
    );
};
