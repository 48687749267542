import { IOption as IDomainOption } from '../../../domain/portal-domain';
import { IModule, Module, ModuleType } from './module';

export const getModuleIcon = (
    appId: number,
    label: string,
    availableOptions: IDomainOption[],
    shouldAppBeStringInUrl: boolean,
    companyId?: number | string,
    installationId?: number | string,
    isSelected?: boolean
): IModule | undefined => {
    const option = availableOptions.find((x) => x.id === appId);
    let appName = option?.value.toLowerCase() || label;
    if (companyId && !installationId) {
        return new Module(appName, option, getCompanyUrl(appName, companyId)).select(isSelected);
    } else if (installationId) {
        return new Module(appName, option, getInstallationUrl(appId, appName, shouldAppBeStringInUrl, companyId, installationId)).select(
            isSelected
        );
    } else {
        return new Module(appName);
    }
};

const getCompanyUrl = (appName: string, companyId?: number | string): string | undefined => {
    if (!companyId) {
        return;
    }

    if (appName.toLowerCase() === ModuleType.ANALYTICS) {
        appName = ModuleType.DASHBOARD;
    }

    const { origin } = global.window.location;
    const url = `${origin}/${appName}/${companyId}`;
    return url;
};

const getInstallationUrl = (
    appId: number | string,
    appName: string,
    shouldAppBeStringInUrl: boolean,
    companyId?: number | string,
    installationId?: number | string
): string | undefined => {
    if (!companyId || !installationId) {
        return;
    }

    if (appName.toLowerCase() === ModuleType.ANALYTICS) {
        return `/${ModuleType.DASHBOARD}/${companyId}`;
    }

    if (appName.toLowerCase() === ModuleType.DASHBOARD) {
        return `/${ModuleType.DASHBOARD}/${companyId}`;
    }

    if (appName.toLowerCase() === ModuleType.AI_INSPECTION) {
        return `/${appName}/${companyId}/${installationId}/`;
    }

    if (appName.toLowerCase() === ModuleType.VIEW_3D) {
        return `/aiinspection/${companyId}/${installationId}/`;
    }

    if (appName.toLowerCase() === ModuleType.EVA) {
        appName = ModuleType.EVENTS_ADMIN;
    }

    const url = shouldAppBeStringInUrl ? `/${companyId}/${installationId}/${appName}` : `/${companyId}/${installationId}/${appId}`;
    return url;
};
