import { IApplication, IOption as IModuleOption, IRoles, ModuleOption } from '../../domain/portal-domain';
import { ApiAdapter } from './api-adapter';
import { EmptyResponseDataError, FetchError } from './api-errors';
import { hasUserAnalyticsPermissions } from '../role-service';
import { getAuthorizationOptions } from '../fetches';

export interface IModulesQueryResponse {
    [installationUri: string]: IApplication[];
}
export class ModulesApiAdapter extends ApiAdapter {
    private static instance: ModulesApiAdapter;

    public static getInstance = (): ModulesApiAdapter => {
        if (!ModulesApiAdapter.instance) {
            ModulesApiAdapter.instance = new ModulesApiAdapter();
        }
        return ModulesApiAdapter.instance;
    };

    private constructor() {
        super('User');
    }

    private getInstallationUri = (companyId: number, installationId: number): string => `${companyId}/${installationId}`;

    public async getAvailableInstallationModules(companyId: number, installationId: number): Promise<IModuleOption[]> {
        try {
            var authOptions = await getAuthorizationOptions();
            const response = await fetch(
                `${this.apiAddress}/CurrentApplications?companyId=${companyId}&installationId=${installationId}`,
                authOptions
            );
            const installations: IModulesQueryResponse = await response.json();
            if (!installations) {
                throw new EmptyResponseDataError('available installation modules');
            }
            const installationUri = this.getInstallationUri(companyId, installationId);
            const module: IApplication[] = installations[installationUri];
            const moduleOptions = module.map((m) => new ModuleOption(m.name, m.id));

            return moduleOptions;
        } catch (e) {
            throw new FetchError('Modules API', e);
        }
    }

    public async getAllApplicationsForInstallation(companyId: number, installationId: number): Promise<IApplication[]> {
        try {
            var authOptions = await getAuthorizationOptions();
            let response: Response;
            if (companyId && installationId) {
                response = await fetch(
                    `${this.apiAddress}/GetAllApplicationsForInstallation?companyId=${companyId}&installationId=${installationId}`,
                    authOptions
                );
            } else if (companyId) {
                response = await fetch(`${this.apiAddress}/GetAllApplicationsForCompany?companyId=${companyId}`, authOptions);
            } else {
                return [];
            }
                
            const applications: IApplication[] = await response.json();

            return applications.filter((x) => x.id !== 8);
        } catch (e) {
            throw new FetchError('Applications API', e);
        }
    }

    public async getAvailableCompanyModules(companyId: string, userRoles: IRoles): Promise<IModuleOption[]> {
        if (!hasUserAnalyticsPermissions(userRoles)) {
            return [];
        }

        try {
            var authOptions = await getAuthorizationOptions();
            const response = await fetch(`${this.apiAddress}/CompanyApplications?companyLegacyId=${companyId}`, authOptions);
            const companyApplications: IModulesQueryResponse = await response.json();

            if (!companyApplications) {
                throw new EmptyResponseDataError('available company modules');
            }
            const moduleNames: IApplication[] = companyApplications[companyId] || [];
            const moduleOptions = moduleNames.map((m) => new ModuleOption(m.name, m.id));
            return moduleOptions;
        } catch (e) {
            throw new FetchError('Modules API', e);
        }
    }
}
