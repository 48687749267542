import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { union, bridge } from '../../ui-icons/illustrations';
import { userIcon } from '../../ui-icons/navbar-icons';
import { ProfileCollapse } from './profile-collapse';
import { NotificationsContainer } from './notifications/notifications-container';
import { ICompany, IOption, ISearchResult, ISelection, IUser } from '../../domain/portal-domain';
import { ClickOutsideWrapper } from '../click-outside/click-outside';
import { mainTheme } from '../../styles/themes/main-theme';
import { InputSearchComponent } from '../containers/input-search/input-search-component';
import { getAllCompaniesOptions, getAllInstallationOptions } from '../../data-providers/helpers';
import { INotificationViewModel } from '../../data-providers/notifications';
import { AuthHelper } from '@axbit/bridge-auth';

const Brand = styled.a`
    padding: 0px;
    max-height: 40px;
    svg {
        margin-top: -1px;
    }
`;

const Header = styled.div`
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        flex-flow: row wrap;
        height: 100px;
    }
`;

const Branding = styled.div`
    display: flex;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        order: 0;
        width: 50%;
    }
`;

const ToggleMenuWrapper = styled.div`
    display: flex;
    text-align: end;
    justify-content: flex-end;
    order: 2;
    max-height: 40px;
    @media ${mainTheme.responsiveBreakpoints.phone} {
        order: 1;
        width: 33%;
    }
`;

const InputSearchWrapper = styled.div`
    margin-top: 10px;
    width: 125vw;
    margin-right: ${mainTheme.margins.big};
    margin-left: ${mainTheme.containerMargins.big};
    margin-right: ${mainTheme.containerMargins.big};
    order: 1;
    z-index: 2;
    background-color: ${mainTheme.colors.white};
    @media ${mainTheme.responsiveBreakpoints.phone} {
        order: 2;
        min-width: 104%;
        margin-left: ${mainTheme.margins.normal};
        margin-right: ${mainTheme.margins.normal};
    }
`;

const ToggleMenu = styled.div`
    display: flex;
    align-items: center;
    justify-items: flex-end;
    margin-right: -38px;
`;

const UserName = styled.div`
    font-style: normal;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    font-size: ${mainTheme.fontSizes.big};
    line-height: ${mainTheme.lineHeights.h1};
    color: ${mainTheme.colors.grey};
    padding-right: ${mainTheme.margins.small};
`;

const BurgerMenu = styled.button`
    padding: 0rem ${mainTheme.margins.big};
    border-style: none;
    height: 40px;
    &:focus {
        border-style: none;
        outline: none;
    }
`;

const BridgeLogoContainer = styled.span`
    margin-left: 10px;
    line-height: 36px;
    font-size: ${mainTheme.fontSizes.big};
    font-weight: 700;
    color: ${mainTheme.colors.grey};
    ${Brand}:hover & {
        svg path {
            fill: ${mainTheme.colors.bridgePrimary};
        }
    }
`;

const Underline = styled.div`
    border-bottom: 2px solid ${mainTheme.colors.grey};
    margin-top: 4px;
`;

const Icon = styled.div`
    position: relative;
    height: 40px;
    margin-left: -7px;
`;

const Notificator = styled.div`
    position: absolute;
    top: 11px;
    right: -3px;
    font-size: ${mainTheme.fontSizes.tiny};
    background-color: ${mainTheme.colors.error};
    border-radius: 100%;
    width: 14px;
    height: 14px;
    padding-top: 2px;
    font-weight: bold;
    color: ${mainTheme.colors.white};
`;

export interface INavMenuRowProps {
    user: IUser;
    notifications: INotificationViewModel[];
    currentSelection: ISelection;
    allCompaniesWithInstallations: ICompany[];
    expandedMenuCallback: (isExpanded: boolean) => void;
    onSelectedOption: (option: IOption) => void;
    onOptionsFiltered: (options: ISearchResult[]) => void;
    onSearchTextChanged: (searchText: string) => void;
    onArchivedNotification: (id: number) => void;
}

export const NavMenuRow: React.FC<INavMenuRowProps> = ({
    user,
    notifications,
    currentSelection,
    allCompaniesWithInstallations,
    expandedMenuCallback,
    onSelectedOption,
    onOptionsFiltered,
    onSearchTextChanged,
    onArchivedNotification,
}) => {    
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const toggleNotificationsOpen = () => {
        setIsNotificationsOpen(!isNotificationsOpen);
    };

    const isProfileOpenedHandler = (state: boolean) => {
        setIsProfileOpen(state);
        if (state) {
            expandedMenuCallback(state);
        } else {
            setTimeout(() => {
                expandedMenuCallback(state);
            }, 300);
        }
    };
    const changePassword = () => AuthHelper.getInstance().changePassword();
    const logOut = () => AuthHelper.getInstance().logOut();
    const installations: IOption[] = getAllInstallationOptions(allCompaniesWithInstallations);
    const companies: IOption[] = getAllCompaniesOptions(allCompaniesWithInstallations);

    const getUserNameInitials = (name: String) => {
        let parts = name.split(' ');
        let initials = '';
        for (let i = 0; i < parts.length; i++) {
            if (parts[i].length > 0 && parts[i] !== '') {
                initials += parts[i][0] + '. ';
            }
        }
        return initials.toUpperCase();
    };

    const userNameInitials = getUserNameInitials(user.name);

    return (
        <>
            <Header>
                <Branding>
                    <Brand className={'navbar-brand'} href="/">
                        {union}
                        <BridgeLogoContainer>{bridge}</BridgeLogoContainer>
                    </Brand>
                </Branding>
                <ToggleMenuWrapper>
                    <ClickOutsideWrapper key="1" handleClickOutside={() => isProfileOpenedHandler(false)}>
                        <ToggleMenu>
                            <UserName>{userNameInitials}</UserName>
                            <BurgerMenu
                                aria-label="Toggle Profile"
                                type="button"
                                className={'navbar-toggler'}
                                onClick={() => isProfileOpenedHandler(!isProfileOpen)}>
                                <Icon>
                                    <span>{userIcon}</span>
                                    {notifications.length > 0 ? <Notificator>{notifications.length}</Notificator> : null}
                                </Icon>
                                {isProfileOpen ? <Underline className="underline"></Underline> : null}
                            </BurgerMenu>
                            <ProfileCollapse
                                changePasswordUrl={changePassword}
                                onShowNotifications={toggleNotificationsOpen}
                                onLogout={logOut}
                                notifications={notifications}
                                isOpen={isProfileOpen}
                                user={user}
                            />

                            <ClickOutsideWrapper key="2" handleClickOutside={() => setIsNotificationsOpen(false)}>
                                <NotificationsContainer
                                    key={'note_container' + notifications.length}
                                    isOpen={isNotificationsOpen}
                                    onHide={toggleNotificationsOpen}
                                    notifications={notifications}
                                    onArchivedNotification={onArchivedNotification}
                                />
                            </ClickOutsideWrapper>
                        </ToggleMenu>
                    </ClickOutsideWrapper>
                </ToggleMenuWrapper>
                <InputSearchWrapper>
                    <InputSearchComponent
                        disabled={isProfileOpen}
                        installationOptions={installations}
                        currentSelection={currentSelection}
                        companyOptions={companies}
                        user={user}
                        onSelectedOption={onSelectedOption}
                        onOptionsFiltered={onOptionsFiltered}
                        onSearchTextChanged={onSearchTextChanged}
                    />
                </InputSearchWrapper>
            </Header>
        </>
    );
};
