import React, { SyntheticEvent } from "react";
import styled from "styled-components/macro";
import { chevronLeft } from "../../../ui-icons/illustrations";
import { mainTheme } from "../../../styles/themes/main-theme";
import { Notification } from "./../../containers/notifications/notification";
import { INotificationViewModel } from "../../../data-providers/notifications";

const MenuContainer = styled.div`
  position: absolute;
  text-align: left;
  background-color: ${mainTheme.colors.white};
  top: 60px;
  right: -2rem;
  z-index: 3;
  width: ${mainTheme.widths.sideMenuMax};
  min-height: 93.7vh;
  box-shadow: 0px 0px 5px ${mainTheme.colors.shadow};
  border-radius: 3px;
  @media ${mainTheme.responsiveBreakpoints.phone} {
    width: ${mainTheme.widths.sideMenuMobile};
  }
`;

const MenuHeader = styled.div`
  position: relative;
  font-size: ${mainTheme.fontSizes.big};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${mainTheme.colors.grey};
  padding: ${mainTheme.margins.biggest} 0 0 ${mainTheme.margins.biggest};
`;

const HorizontalLine = styled.div`
  border-bottom: 2px solid ${mainTheme.colors.menuUnderline};
  margin: ${mainTheme.margins.normal} ${mainTheme.margins.biggest};
`;

const ShorterHorizontalLine = styled.div`
  border-bottom: 2px solid ${mainTheme.colors.menuUnderline};
  margin: ${mainTheme.margins.normal} ${mainTheme.margins.bigger};
`;

const Bolded = styled.span`
  font-weight: bold;
  padding-right: 0.3rem;
  white-space: nowrap;
`;

const Notificator = styled.div`
  position: absolute;
  top: 20px;
  right: 130px;
  font-size: 10px;
  padding-top: 1px;
  background-color: ${mainTheme.colors.error};
  border-radius: 100%;
  width: 15px;
  height: 15px;
  font-weight: bold;
  color: ${mainTheme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Notifications = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const Flexed = styled.a`
  display: flex;
`;

const LinkItemMargined = styled.span`
  text-decoration-line: none;
  color: ${mainTheme.colors.bridgePrimary} !important;
  margin-left: ${mainTheme.margins.big};
  margin-right: ${mainTheme.margins.small};
`;

const BoldedSeparator = styled(Bolded)`
  margin-top: -2px;
`;

const Chevron = styled.span`
  display: flex;
  margin: auto;
  justify-content: space-between;
`;

export interface INotificationsProps {
  isOpen: boolean;
  notifications: INotificationViewModel[];
  onHide: () => void;
  onArchivedNotification: (id: number) => void;
}

export const NotificationsContainer: React.FC<INotificationsProps> = ({
  isOpen,
  notifications,
  onArchivedNotification,
  onHide,
}) => {
  const handleReturn = (e: SyntheticEvent) => {
    e.preventDefault();
    onHide();
  };

  const onNotificationClosed = (id: number) => {
    onArchivedNotification(id);
  };

  const notes = notifications.map((not: INotificationViewModel) => {
    return (
      <>
        <Notification
          key={"not_" + not.notificationId}
          showShortLabel={false}
          showShadow={false}
          showCancelIcon={false}
          notification={not}
          onHide={onNotificationClosed}
        />
        <ShorterHorizontalLine key={"line_" + not.notificationId} />
      </>
    );
  });
  return isOpen ? (
    <>
      <MenuContainer key={"menu_container" + notifications.length}>
        <ul className="navbar-nav flex-grow">
          <MenuHeader>
            <Flexed href="/" onClick={handleReturn}>
              <Chevron>{chevronLeft}</Chevron>
              <LinkItemMargined>PROFILE</LinkItemMargined>
            </Flexed>
            <BoldedSeparator>|</BoldedSeparator>
            <Bolded>NOTIFICATIONS</Bolded>
            <Notificator>{notifications.length}</Notificator>
          </MenuHeader>
          <HorizontalLine />
          <Notifications key={notifications.length}>{notes}</Notifications>
        </ul>
      </MenuContainer>
    </>
  ) : null;
};
