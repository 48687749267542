import * as React from "react";

export const notAvailable: JSX.Element = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1635 28.8366 4.00004 20 4.00004C11.1635 4.00004 4.00002 11.1635 4.00002 20C4.00002 28.8366 11.1635 36 20 36Z"
      fill="#8E8E8E"
    />
    <path d="M18.2496 7H17.1971V14.1296H18.2496V7Z" fill="#8E8E8E" />
    <path
      d="M18.2496 27.1685C19.3959 27.1685 20.3252 26.2393 20.3252 25.093C20.3252 23.9467 19.3959 23.0174 18.2496 23.0174C17.1033 23.0174 16.1741 23.9467 16.1741 25.093C16.1741 26.2393 17.1033 27.1685 18.2496 27.1685Z"
      fill="#8E8E8E"
    />
    <path d="M20.2412 9.08282H21.2826V13.649H20.2412V9.08282Z" fill="#8E8E8E" />
    <path
      d="M23.9262 9.08282H23.2853V16.0522H23.9262V9.08282Z"
      fill="#8E8E8E"
    />
    <path
      d="M21.7633 13.9694L17.5175 15.4114L18.1584 17.1738L22.324 15.4114L21.7633 13.9694Z"
      fill="#8E8E8E"
    />
    <path d="M15.3546 12.207H14.5535V16.613H15.3546V12.207Z" fill="#8E8E8E" />
    <path
      d="M15.8353 17.8146C15.8353 18.2571 15.4766 18.6157 15.0342 18.6157C14.5917 18.6157 14.2331 18.2571 14.2331 17.8146C14.2331 17.3722 14.5917 17.0136 15.0342 17.0136C15.4766 17.0136 15.8353 17.3722 15.8353 17.8146Z"
      fill="#8E8E8E"
    />
    <path
      d="M16.7035 33.5669H24.138L22.3357 28.16C22.3357 28.16 26.5034 26.6956 26.6161 25.6818C26.7289 24.668 22.8989 20.2749 22.8989 20.2749C22.8989 20.2749 22.4432 19.5293 21.7724 20.2749C21.1016 21.0205 24.138 24.668 24.138 24.668C24.138 24.668 18.69 27.8218 16.7035 29.3991C14.7169 30.9764 14 33.5669 14 33.5669H15.6897L16.3501 32.1636L16.7035 33.5669Z"
      fill="#8E8E8E"
    />
  </svg>
);

export const available: JSX.Element = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1635 28.8366 4.00004 20 4.00004C11.1635 4.00004 4.00002 11.1635 4.00002 20C4.00002 28.8366 11.1635 36 20 36Z"
      fill="#FF9613"
    />
    <path d="M18.2496 7H17.1971V14.1296H18.2496V7Z" fill="#3D3D3D" />
    <path
      d="M18.2496 27.1685C19.3959 27.1685 20.3252 26.2393 20.3252 25.093C20.3252 23.9467 19.3959 23.0174 18.2496 23.0174C17.1033 23.0174 16.1741 23.9467 16.1741 25.093C16.1741 26.2393 17.1033 27.1685 18.2496 27.1685Z"
      fill="#3D3D3D"
    />
    <path d="M20.2412 9.08282H21.2826V13.649H20.2412V9.08282Z" fill="#3D3D3D" />
    <path
      d="M23.9262 9.08282H23.2853V16.0522H23.9262V9.08282Z"
      fill="#3D3D3D"
    />
    <path
      d="M21.7633 13.9694L17.5175 15.4114L18.1584 17.1738L22.324 15.4114L21.7633 13.9694Z"
      fill="#3D3D3D"
    />
    <path d="M15.3546 12.207H14.5535V16.613H15.3546V12.207Z" fill="#3D3D3D" />
    <path
      d="M15.8353 17.8146C15.8353 18.2571 15.4766 18.6157 15.0342 18.6157C14.5917 18.6157 14.2331 18.2571 14.2331 17.8146C14.2331 17.3722 14.5917 17.0136 15.0342 17.0136C15.4766 17.0136 15.8353 17.3722 15.8353 17.8146Z"
      fill="#3D3D3D"
    />
    <path
      d="M16.7035 33.5669H24.138L22.3357 28.16C22.3357 28.16 26.5034 26.6956 26.6161 25.6818C26.7289 24.668 22.8989 20.2749 22.8989 20.2749C22.8989 20.2749 22.4432 19.5293 21.7724 20.2749C21.1016 21.0205 24.138 24.668 24.138 24.668C24.138 24.668 18.69 27.8218 16.7035 29.3991C14.7169 30.9764 14 33.5669 14 33.5669H15.6897L16.3501 32.1636L16.7035 33.5669Z"
      fill="#3D3D3D"
    />
  </svg>
);

export const selected: JSX.Element = (
  <svg
    width="46"
    height="48"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 40C34.0457 40 43 31.0457 43 20C43 8.9543 34.0457 0 23 0C11.9543 0 3 8.9543 3 20C3 31.0457 11.9543 40 23 40ZM23 36C31.8366 36 39 28.8366 39 20C39 11.1635 31.8366 4.00004 23 4.00004C14.1635 4.00004 7.00002 11.1635 7.00002 20C7.00002 28.8366 14.1635 36 23 36Z"
        fill="#FF9613"
      />
      <path d="M21.2496 7H20.1971V14.1296H21.2496V7Z" fill="#3D3D3D" />
      <path
        d="M21.2496 27.1685C22.3959 27.1685 23.3252 26.2393 23.3252 25.093C23.3252 23.9467 22.3959 23.0174 21.2496 23.0174C20.1033 23.0174 19.1741 23.9467 19.1741 25.093C19.1741 26.2393 20.1033 27.1685 21.2496 27.1685Z"
        fill="#3D3D3D"
      />
      <path
        d="M23.2412 9.08282H24.2826V13.649H23.2412V9.08282Z"
        fill="#3D3D3D"
      />
      <path
        d="M26.9262 9.08282H26.2853V16.0522H26.9262V9.08282Z"
        fill="#3D3D3D"
      />
      <path
        d="M24.7633 13.9694L20.5175 15.4114L21.1584 17.1738L25.324 15.4114L24.7633 13.9694Z"
        fill="#3D3D3D"
      />
      <path d="M18.3546 12.207H17.5535V16.613H18.3546V12.207Z" fill="#3D3D3D" />
      <path
        d="M18.8353 17.8146C18.8353 18.2571 18.4766 18.6157 18.0342 18.6157C17.5917 18.6157 17.2331 18.2571 17.2331 17.8146C17.2331 17.3722 17.5917 17.0136 18.0342 17.0136C18.4766 17.0136 18.8353 17.3722 18.8353 17.8146Z"
        fill="#3D3D3D"
      />
      <path
        d="M19.7035 33.5669H27.138L25.3357 28.16C25.3357 28.16 29.5034 26.6956 29.6161 25.6818C29.7289 24.668 25.8989 20.2749 25.8989 20.2749C25.8989 20.2749 25.4432 19.5293 24.7724 20.2749C24.1016 21.0205 27.138 24.668 27.138 24.668C27.138 24.668 21.69 27.8218 19.7035 29.3991C17.7169 30.9764 17 33.5669 17 33.5669H18.6897L19.3501 32.1636L19.7035 33.5669Z"
        fill="#3D3D3D"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="46"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
