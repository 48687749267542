import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { IOption, ISearchResult, ISelection, IUser, OptionType } from '../../../domain/portal-domain';
import { searchIcon } from '../../../ui-icons/illustrations';
import { ClickOutsideWrapper } from '../../click-outside/click-outside';
import { setRecentSearch } from '../../../data-providers/local-storage/recent-searches';
import { getSearchResultForRecentSearches, getSearchResults } from '../../../data-providers/search-helpers';
import { IOptionElement, OptionElements } from './option-elements';
import { mainTheme } from '../../../styles/themes/main-theme';

interface IStyleProps {
    isOverlay: boolean;
}

const Container = styled.div`
    background-color: ${(styles: IStyleProps) => (styles.isOverlay ? 'rgba(0, 0, 0, 0.3)' : mainTheme.colors.white)};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    float: right;
`;

const SearchIcon = styled.span`
    cursor: pointer;
    margin-left: -32px;
    margin-top: -12px;
`;

const ListContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
`;

const List = styled.ul`
    margin-top: 2.8rem;
    position: absolute;
    width: 267px;
    margin-left: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: ${mainTheme.colors.white};
    box-sizing: border-box;
    box-shadow: 0px 1px 4px ${mainTheme.colors.shadow}, 0px 0px 1px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    li:last-of-type {
        margin-bottom: ${mainTheme.margins.big};
    }
`;

const Input = styled.input`
    border: none;
    padding-left: 3px;
    min-width: 200px;
    background-color: inherit;
    padding-bottom: 12px;
    width: 267px;
    &:focus {
        outline: none;
        border-bottom: solid 2px ${mainTheme.colors.grey};
    }

    ::placeholder {
        font-weight: 400;
    }
`;

export interface IInputSearchProps {
    currentSelection: ISelection;
    installationOptions: IOption[];
    companyOptions: IOption[];
    user: IUser;
    disabled: boolean;
    onSelectedOption: (option: IOption) => void;
    onOptionsFiltered: (options: ISearchResult[]) => void;
    onSearchTextChanged: (searchText: string) => void;
}

export const InputSearchComponent: React.FC<IInputSearchProps> = ({
    currentSelection,
    installationOptions,
    companyOptions,
    disabled,
    user,
    onSelectedOption,
    onOptionsFiltered,
    onSearchTextChanged,
}) => {
    let element: IOptionElement;
    const [showOptions, setShowOptions] = useState(false);
    const [currentOption, setCurrentOption] = useState({} as IOption);
    const [recentSearches, setRecentSearches] = useState([] as ISearchResult[]);
    const [userInput, setUserInput] = useState('');
    const [activeOption, setActiveOption] = useState(-1);
    const [filteredOptions, setFilteredOptions] = useState([] as ISearchResult[]);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        const searches: ISearchResult[] = getSearchResultForRecentSearches(user.email);

        setFilteredOptions(getSearchResults(companyOptions, installationOptions, ''));

        if (currentOption.type === OptionType.COMPANY) {
            if (currentSelection.company.key !== currentOption.key) {
                setUserInput(currentSelection.searchText);
            }
        } else if (currentOption.type === OptionType.INSTALLATION) {
            if (currentSelection.installation.key !== currentOption.key) {
                setUserInput(currentSelection.searchText);
            }
        } else {
            setUserInput(currentSelection.searchText);
        }

        if (JSON.stringify(searches) !== JSON.stringify(recentSearches)) {
            setRecentSearches(searches);
        }
    }, [currentSelection, currentOption, recentSearches, installationOptions, companyOptions, user]);

    const toggling = () => setShowOptions(!showOptions);

    const onOptionClicked = (option: IOption) => {
        setActiveOption(-1);
        setFilteredOptions([]);
        setShowOptions(false);
        setUserInput('');
        setCurrentOption(option);
        onSelectedOption(option as IOption);
        setRecentSearch(option, user.email);
        setIsSelected(true);
        onSearchTextChanged('');
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (showOptions) {
            let options: ISearchResult[];
            let option: ISearchResult;
            if (!isSelected && userInput.length > 0) {
                options = filteredOptions;
            } else {
                options = recentSearches;
            }

            option = options[activeOption];

            if (option && e.key === 'Enter') {
                setActiveOption(-1);
                setShowOptions(false);
                setUserInput('');
                setCurrentOption(option);
                onSelectedOption(option as IOption);
                setRecentSearch(option, user.email);
                setIsSelected(true);
                onOptionsFiltered([]);
                onSearchTextChanged('');
            } else if (e.key === 'Enter') {
                setActiveOption(-1);
                onSearchTextChanged(userInput);
                setIsSelected(false);
                setShowOptions(false);
                onOptionsFiltered(options);
            } else if (e.key === 'ArrowUp') {
                if (activeOption === 0) {
                    return;
                }
                setActiveOption(activeOption - 1);
            } else if (e.key === 'ArrowDown') {
                if (activeOption === options.length - 1) {
                    return;
                }
                setActiveOption(activeOption + 1);
            }
        } else if (e.key === 'ArrowDown') {
            setShowOptions(true);
        }
    };

    const getOptions = (searchString: string): ISearchResult[] => {
        let userText = searchString ? searchString : '';
        const companies = companyOptions.filter((optionName) => optionName.value.toLowerCase().indexOf(userText.toLowerCase()) > -1);
        const installations = installationOptions.filter(
            (optionName) => optionName.value.toLowerCase().indexOf(userText.toLowerCase()) > -1
        );

        return getSearchResults(companies, installations, userText);
    };

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const userText = e.currentTarget.value;
        setActiveOption(-1);
        setFilteredOptions(getOptions(userText));
        setShowOptions(true);
        setUserInput(userText);
        setIsSelected(false);
    };

    const onMouseOver = (e: React.MouseEvent) => {
        const el = document.getElementById('selectedSearchItem');
        if (el) {
            el.style.background = 'none';
        }
    };

    const onMouseLeave = (e: React.MouseEvent) => {
        const el = document.getElementById('selectedSearchItem');
        if (el) {
            el.style.background = mainTheme.colors.hoverBackground;
        }
    };

    let isRecentSearches: boolean = isSelected || userInput.length === 0;
    if (!isRecentSearches) {
        element = {
            label: '',
            options: filteredOptions,
        };
    } else {
        element = { label: '', options: recentSearches };
    }

    let placeHolderText;
    if (companyOptions.length === 1) {
        placeHolderText = 'Type installation';
    } else {
        placeHolderText = 'Search company or installation';
    }

    return (
        <ClickOutsideWrapper handleClickOutside={() => setShowOptions(false)}>
            <Container isOverlay={currentSelection.searchText.length > 0}>
                <Header onClick={toggling}>
                    <Input
                        ref={(input) => input && input.focus()}
                        type="text"
                        disabled={disabled}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={userInput}
                        placeholder={placeHolderText}
                    />
                    <SearchIcon
                        onClick={(e) => {
                            let event = e as unknown as React.KeyboardEvent;
                            event.key = 'Enter';
                            setShowOptions(true);
                            onKeyDown(event);
                        }}>
                        {searchIcon}
                    </SearchIcon>
                </Header>
                {showOptions && filteredOptions.length > 0 && (
                    <ListContainer>
                        <List onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
                            <OptionElements
                                isRecentSearches={isRecentSearches}
                                element={element}
                                activeOption={activeOption}
                                onOptionClicked={onOptionClicked}
                            />
                        </List>
                    </ListContainer>
                )}
            </Container>
        </ClickOutsideWrapper>
    );
};
