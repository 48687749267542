import React from "react";
import styled from "styled-components/macro";
import { IOption } from "../../../../domain/portal-domain";
import { mainTheme } from "../../../../styles/themes/main-theme";

const BiggerText = styled.div`
  font-size: ${mainTheme.fontSizes.bigger};
  padding-bottom: ${mainTheme.margins.normal};
  padding-top: ${mainTheme.margins.normal};
`;

const BoldedText = styled.div`
  font-weight: bold;
`;

const Separator = styled.div`
  margin: ${mainTheme.margins.biggest} 0;
  border-top: 1px solid ${mainTheme.colors.separator};
`;

const Label = styled.div`
  font-size: ${mainTheme.fontSizes.bigger};
  font-style: italic;
  line-height: 150%;
`;

const SubLabel = styled.div`
  padding-left: 5px;
  margin-top: 2px;
  white-space: nowrap;
`;

export interface ISelectionElementProps {
  option: IOption;
  label: string;
}

export const SelectionElement: React.FC<ISelectionElementProps> = React.memo(
  ({ label, option }) => {
    const mainLabel = option.rootId
      ? "'" + option.value
      : "'" + option.value + "'";
    const subLabel = option.rootId ? "(" + option.rootName + ")'" : "";
    return (
      <>
        <BiggerText>{label}</BiggerText>
        <Label>
          <BoldedText>{mainLabel}</BoldedText>
          <SubLabel>{subLabel}</SubLabel>
        </Label>
        <Separator />
      </>
    );
  }
);
