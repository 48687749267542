import React from "react";
import styled from "styled-components/macro";
import {
  ISelection,
  IOption,
  OptionType,
} from "../../../../domain/portal-domain";
import { telescope } from "../../../../ui-icons/illustrations";
import { mainTheme } from "../../../../styles/themes/main-theme";

const BiggerText = styled.span`
  font-size: ${mainTheme.fontSizes.bigger};
`;

const BoldedText = styled(BiggerText)`
  font-weight: bold;
  font-style: italic;
  padding-left: 6px;
`;

const BigText = styled.span`
  font-size: ${mainTheme.fontSizes.big};
`;

const Link = styled.a`
  font-size: ${mainTheme.fontSizes.big};
  padding-left: 6px;
  cursor: pointer;
  text-decoration: underline;
  color: ${mainTheme.colors.bridgePrimary};
  &:hover {
    background: ${mainTheme.colors.hoverBackground};
    color: ${mainTheme.colors.grey};
  }
`;

const Icon = styled.span`
  display: flex;
  margin: ${mainTheme.containerMargins.small} auto;
  justify-content: center;
`;

const Header = styled.div`
  display: block;
  padding: ${mainTheme.containerMargins.normal};
  padding-bottom: ${mainTheme.containerMargins.small};
`;

const Info = styled.div`
  display: block;
  padding: ${mainTheme.containerMargins.normal};
  padding-bottom: ${mainTheme.containerMargins.small};
  padding-top: ${mainTheme.margins.small};
`;

export interface IEmptySearchResultsProps {
  currentSelection: ISelection;
  onSelectedOption: (option: IOption) => void;
}

export const EmptySearchResults: React.FC<IEmptySearchResultsProps> = React.memo(
  ({ currentSelection, onSelectedOption }) => {
    const onClickHandler = () => {
      onSelectedOption({ type: OptionType.NONE } as IOption);
    };

    return (
      <>
        <Header>
          <BiggerText>{"Sorry! No results found on the input"}</BiggerText>
          <BoldedText>{currentSelection.searchText}</BoldedText>
          <BiggerText>{"."}</BiggerText>
        </Header>
        <Info>
          <BigText>
            {
              "Check if the spelling is right, or you can try selecting desired company or installation by using the"
            }
          </BigText>
          <Link onClick={onClickHandler}>{"landing page"}</Link>
          <BigText>{"."}</BigText>
        </Info>
        <Icon>{telescope}</Icon>
      </>
    );
  }
);
