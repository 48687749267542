import { ICompany, ICurrentCompaniesAndInstallations, IOption, OptionType, ISearchResult } from '../domain/portal-domain';
import { removeByKey } from './local-storage/recent-searches';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const getCompaniesWithInstallations = (currCompAndInsta: ICurrentCompaniesAndInstallations[]): ICompany[] => {
    if (!currCompAndInsta) {
        return [];
    }
    let companies: ICompany[] = [];
    currCompAndInsta.forEach((element) => {
        if (!element.published) {
            removeByKey(element.staticId, OptionType.INSTALLATION);
            return;
        }
        if (!element.company.published) {
            removeByKey(element.company.staticId, OptionType.COMPANY);
            return;
        }
        let companyIndex = companies.findIndex((x) => x.staticName === element.company.staticId);
        if (companyIndex < 0) {
            companies.push({
                displayName: element.company.displayName,
                staticName: element.company.staticId,
                installations: [],
                id: element.company.id,
            });
            companyIndex = companies.findIndex((x) => x.staticName === element.company.staticId);
        }

        companies[companyIndex].installations.push({
            displayName: element.displayName,
            staticName: element.staticId,
            id: element.id,
        });
    });

    return companies;
};

export const getAllCompaniesOptions = (companies: ICompany[]): IOption[] => {
    return companies
        .map((comp) => {
            return {
                id: comp.id,
                key: comp.staticName,
                value: comp.displayName,
                type: OptionType.COMPANY,
            };
        })
        .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
};

export const getCompanyOption = (companies: ICompany[], companyId: number): IOption => {
    let company = companies.find((x) => x.id === companyId) || ({} as ICompany);

    return {
        id: company.id,
        key: company.staticName,
        value: company.displayName,
        type: OptionType.COMPANY,
    } as IOption;
};

const distinct = (value: IOption, index: number, self: IOption[]) => {
    return self.findIndex((x) => x.key === value.key) === index;
};

export const getAllInstallationOptions = (companies: ICompany[]): IOption[] => {
    const options: IOption[] = [];
    companies.forEach((comp) => {
        let elements: IOption[] = comp.installations.map((inst) => {
            return {
                id: inst.id,
                key: inst.staticName,
                value: inst.displayName,
                rootName: comp.displayName,
                rootId: comp.id,
                type: OptionType.INSTALLATION,
            };
        });
        options.push(...elements.filter(distinct));
    });
    return options.sort((a, b) => a.value?.toLowerCase().localeCompare(b.value?.toLowerCase()));
};

export const getInstallationOptionsForCompany = (company: ICompany): IOption[] => {
    return company.installations
        .map((inst) => {
            return {
                id: inst.id,
                key: inst.staticName,
                value: inst.displayName,
                type: OptionType.INSTALLATION,
                rootName: company.displayName,
                rootId: company.id,
            };
        })
        .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
};

export const passwordMatcherValidation = (pass: string): boolean => {
    const minLength = 7;
    let count = 0;
    const digitsValidatior = /^.*\d.*$/;
    const characterValidator = /^.*[a-zA-Z].*$/;
    const specialCharValidator = /^.*[*.!@#$%^&(){}[\]:";'<>,.?/~`_+\-=|\\].*$/;

    if (pass.length >= minLength) {
        if (digitsValidatior.test(pass)) {
            count++;
        }
        if (characterValidator.test(pass)) {
            count++;
        }
        if (specialCharValidator.test(pass)) {
            count++;
        }
    }

    return count >= 3;
};

export const sorter = () => {
    const alphabet = '*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyzæøå';
    return (a: IOption, b: IOption) => {
        const left = a.value.toLowerCase();
        const right = b.value.toLowerCase();

        var index_a = alphabet.indexOf(left[0]),
            index_b = alphabet.indexOf(right[0]);

        if (index_a === index_b) {
            if (left < right) {
                return -1;
            } else if (left > right) {
                return 1;
            }
            return 0;
        } else {
            return index_a - index_b;
        }
    };
};

export const sortSearchResult = () => {
    const alphabet = '*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyzæøå';
    return (a: ISearchResult, b: ISearchResult) => {
        const left = a.searchValue.toLowerCase();
        const right = b.searchValue.toLowerCase();

        var index_a = alphabet.indexOf(left[0]),
            index_b = alphabet.indexOf(right[0]);

        if (index_a === index_b) {
            if (left < right) {
                return -1;
            } else if (left > right) {
                return 1;
            }
            return 0;
        } else {
            return index_a - index_b;
        }
    };
};

export const getFromatedDate = (date: Date): string => {
    const year = date.getFullYear();
    const dayOfMonth = date.getDate();
    const monthIndex = date.getMonth();
    const monthName = months[monthIndex];
    return dayOfMonth + '. ' + monthName + ' ' + year;
};

export const browserDetect = (): string => {
    let browserAgent = navigator.userAgent;
    let browserName = navigator.appName;
    let browserVersion = '' + parseFloat(navigator.appVersion);
    let browserMajorVersion = parseInt(navigator.appVersion, 10);
    let Offset, OffsetVersion, ix;

    // For Chrome
    if ((OffsetVersion = browserAgent.indexOf('Chrome')) !== -1) {
        browserName = 'Chrome';
        browserVersion = browserAgent.substring(OffsetVersion + 7);
    }

    // For Microsoft internet explorer
    else if ((OffsetVersion = browserAgent.indexOf('MSIE')) !== -1) {
        browserName = 'Microsoft Internet Explorer';
        browserVersion = browserAgent.substring(OffsetVersion + 5);
    }

    // For Firefox
    else if ((OffsetVersion = browserAgent.indexOf('Firefox')) !== -1) {
        browserName = 'Firefox';
    }
    // For Opera
    else if ((OffsetVersion = browserAgent.indexOf('OPR/')) !== -1) {
        browserName = 'Opera';
    }

    // For Safari
    else if ((OffsetVersion = browserAgent.indexOf('Safari')) !== -1) {
        browserName = 'Safari';
        browserVersion = browserAgent.substring(OffsetVersion + 7);
        if ((OffsetVersion = browserAgent.indexOf('Version')) !== -1) browserVersion = browserAgent.substring(OffsetVersion + 8);
    }

    // For other browser "name/version" is at the end of userAgent
    else if ((Offset = browserAgent.lastIndexOf(' ') + 1) < (OffsetVersion = browserAgent.lastIndexOf('/'))) {
        browserName = browserAgent.substring(Offset, OffsetVersion);
        browserVersion = browserAgent.substring(OffsetVersion + 1);
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }

    if ((ix = browserVersion.indexOf(';')) !== -1) browserVersion = browserVersion.substring(0, ix);
    if ((ix = browserVersion.indexOf(' ')) !== -1) browserVersion = browserVersion.substring(0, ix);

    browserMajorVersion = parseInt('' + browserVersion, 10);
    if (isNaN(browserMajorVersion)) {
        browserVersion = '' + parseFloat(navigator.appVersion);
        browserMajorVersion = parseInt(navigator.appVersion, 10);
    }
    return ' ' + browserName + ' ' + browserMajorVersion;
};

export function isNapApp(appId: number): boolean {
    return appId >= 100;
}