import { ICurrentUser } from '../../domain/portal-domain';
import { getAuthorizationOptions } from '../fetches';
import { ApiAdapter } from './api-adapter';
import { EmptyResponseDataError, FetchError } from './api-errors';

export interface IChangePasswordResponse {
    isSuccessful: boolean;
    status: string;
    message: string;
}

export class ActiveUserApiAdapter extends ApiAdapter {
    private static instance: ActiveUserApiAdapter;

    public static getInstance = (): ActiveUserApiAdapter => {
        if (!ActiveUserApiAdapter.instance) {
            ActiveUserApiAdapter.instance = new ActiveUserApiAdapter();
        }
        return ActiveUserApiAdapter.instance;
    };

    private constructor() {
        super('User');
    }

    public async fetchCurrentUser(): Promise<ICurrentUser | null> {
        try {
            var authOptions = await getAuthorizationOptions();
            const response = await fetch(`${this.apiAddress}/CurrentUser`, authOptions);
            if (response.status === 401) {
                return null;
            }

            const userData = await response.json();
            if (!userData) {
                throw new EmptyResponseDataError('active user data');
            }

            return userData;
        } catch (e) {
            throw new FetchError('Active User API', e);
        }
    }
}
