import * as React from 'react';

export const mail: JSX.Element = (
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 11.416L29.8153 1.15672e-06L0.184699 0L15 11.416Z" fill="#3D3D3D" />
        <path
            d="M0 1.6827L14.2661 12.6755C14.7101 13.0177 15.2898 13.0177 15.7339 12.6755L30 1.68269V19.4839C30 20.3212 29.4097 21 28.6816 21H1.31837C0.590257 21 0 20.3212 0 19.4839V1.6827Z"
            fill="#3D3D3D"
        />
    </svg>
);

export const address: JSX.Element = (
    <svg width="20" height="31" viewBox="0 0 20 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8112 11.8306C19.9354 11.2072 20.0005 10.5628 20.0005 9.9034C20.0005 4.4339 15.5232 0 10.0002 0C4.47726 0 0 4.4339 0 9.9034C0 10.5629 0.0650996 11.2074 0.189282 11.8308H0.182628C1.74746 18.9169 4.01008 23.084 9.47947 30.2736H9.48196C9.59299 30.4408 9.783 30.551 9.99874 30.551C10.2145 30.551 10.4045 30.4408 10.5155 30.2736H10.5172C15.9867 23.0838 18.2494 18.9167 19.8142 11.8306H19.8112ZM9.99868 29.3112H9.99846V19.8068H9.99868V29.3112ZM9.99986 11.9928C11.4761 11.9928 12.6728 10.7961 12.6728 9.31983C12.6728 7.8436 11.4761 6.64688 9.99986 6.64688C8.52363 6.64688 7.32691 7.8436 7.32691 9.31983C7.32691 10.7961 8.52363 11.9928 9.99986 11.9928Z"
            fill="#3D3D3D"
        />
    </svg>
);

export const linkedIn: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="20" cy="20" rx="20" ry="20" fill="#1177B7" />
        <path
            d="M16.0319 27H13.1694V17.0669H16.0319V27ZM13 14.5056C13 14.0649 13.1468 13.7038 13.4404 13.4223C13.734 13.1408 14.1151 13 14.5837 13C15.0523 13 15.4334 13.1408 15.727 13.4223C16.0206 13.7038 16.1674 14.0649 16.1674 14.5056C16.1674 14.9462 16.0206 15.3073 15.727 15.5889C15.4334 15.8704 15.0523 16.0111 14.5837 16.0111C14.1151 16.0111 13.734 15.8704 13.4404 15.5889C13.1468 15.3073 13 14.9462 13 14.5056Z"
            fill="#F6F6F6"
        />
        <path
            d="M20.4189 17.0669L20.5121 18.2328C21.1444 17.3331 22.0167 16.8833 23.129 16.8833C24.0832 16.8833 24.7946 17.1923 25.2632 17.8105C25.7375 18.4286 25.9831 19.3589 26 20.6013V27H23.1375V20.7298C23.1375 20.228 23.0443 19.8608 22.858 19.6282C22.6717 19.3895 22.3329 19.2702 21.8417 19.2702C21.2827 19.2702 20.8678 19.5089 20.5967 19.9862V27H17.7427V17.0669H20.4189Z"
            fill="#F6F6F6"
        />
    </svg>
);

export const help: JSX.Element = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10C0.5 4.75329 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.75329 19.5 10Z"
            stroke="#00818a"
        />
        <path
            d="M8.99363 11.1391C8.99363 10.5504 9.06369 10.0867 9.20382 9.74798C9.34395 9.40524 9.57113 9.10887 9.88535 8.85887C10.1996 8.60887 10.4374 8.38105 10.5987 8.1754C10.7643 7.96573 10.8471 7.73589 10.8471 7.48589C10.8471 6.87702 10.5711 6.57258 10.0191 6.57258C9.76433 6.57258 9.55839 6.66129 9.40127 6.83871C9.24416 7.01613 9.16136 7.25605 9.15287 7.55847H7C7.00849 6.75202 7.27601 6.125 7.80255 5.67742C8.32909 5.22581 9.06794 5 10.0191 5C10.966 5 11.6985 5.20766 12.2166 5.62298C12.7389 6.03831 13 6.62903 13 7.39516C13 7.72984 12.9299 8.03427 12.7898 8.30847C12.6497 8.58266 12.4246 8.8629 12.1146 9.14919L11.3822 9.79637C11.1741 9.98589 11.0297 10.1815 10.949 10.3831C10.8684 10.5806 10.8238 10.8327 10.8153 11.1391H8.99363ZM8.73248 12.9778C8.73248 12.6794 8.84501 12.4355 9.07006 12.246C9.29936 12.0524 9.58386 11.9556 9.92357 11.9556C10.2633 11.9556 10.5456 12.0524 10.7707 12.246C11 12.4355 11.1146 12.6794 11.1146 12.9778C11.1146 13.2762 11 13.5222 10.7707 13.7157C10.5456 13.9052 10.2633 14 9.92357 14C9.58386 14 9.29936 13.9052 9.07006 13.7157C8.84501 13.5222 8.73248 13.2762 8.73248 12.9778Z"
            fill="#00818a"
        />
    </svg>
);
export const feedbackIcon: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1987 18.1379C13.1987 17.8067 13.4673 17.5381 13.7986 17.5381H23.3963C23.7276 17.5381 23.9961 17.8067 23.9961 18.1379C23.9961 18.4692 23.7276 18.7378 23.3963 18.7378H13.7986C13.4673 18.7378 13.1987 18.4692 13.1987 18.1379Z"
            fill="#00818A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.5953 24.7363C27.264 24.7363 26.9955 24.4678 26.9955 24.1365L26.9955 18.1379C26.9955 17.8066 27.264 17.5381 27.5953 17.5381C27.9266 17.5381 28.1952 17.8066 28.1952 18.1379L28.1952 24.1365C28.1952 24.4678 27.9266 24.7363 27.5953 24.7363Z"
            fill="#00818A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1987 12.739C13.1987 12.4077 13.4673 12.1392 13.7986 12.1392H22.1966C22.5278 12.1392 22.7964 12.4077 22.7964 12.739C22.7964 13.0703 22.5278 13.3389 22.1966 13.3389H13.7986C13.4673 13.3389 13.1987 13.0703 13.1987 12.739Z"
            fill="#00818A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.1987 21.7371C13.1987 21.4058 13.4673 21.1372 13.7986 21.1372H20.9968C21.3281 21.1372 21.5967 21.4058 21.5967 21.7371C21.5967 22.0684 21.3281 22.3369 20.9968 22.3369H13.7986C13.4673 22.3369 13.1987 22.0684 13.1987 21.7371Z"
            fill="#00818A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.9957 11.3082L26.5501 12.011C26.3947 12.2561 26.1516 12.4327 25.8705 12.5048L25.0643 12.7114L25.5951 13.3524C25.7802 13.5759 25.873 13.8617 25.8547 14.1513L25.802 14.9818L26.5757 14.6752C26.8455 14.5682 27.1459 14.5682 27.4157 14.6752L28.1893 14.9818L28.1367 14.1513C28.1184 13.8617 28.2112 13.5759 28.3963 13.3524L28.9271 12.7114L28.1209 12.5048C27.8398 12.4327 27.5967 12.2561 27.4413 12.011L26.9957 11.3082ZM27.249 10.1393C27.1312 9.95356 26.8602 9.95356 26.7424 10.1393L25.8408 11.5613C25.7999 11.6258 25.736 11.6723 25.662 11.6913L24.031 12.1093C23.8179 12.1639 23.7341 12.4217 23.8744 12.5911L24.9482 13.888C24.9969 13.9468 25.0214 14.022 25.0165 14.0982L24.9101 15.7786C24.8962 15.9981 25.1155 16.1574 25.3199 16.0763L26.8852 15.4558C26.9562 15.4277 27.0352 15.4277 27.1062 15.4558L28.6714 16.0763C28.8759 16.1574 29.0952 15.9981 29.0813 15.7786L28.9748 14.0982C28.97 14.022 28.9944 13.9468 29.0432 13.888L30.117 12.5911C30.2572 12.4217 30.1735 12.1639 29.9604 12.1093L28.3294 11.6913C28.2554 11.6723 28.1915 11.6258 28.1506 11.5613L27.249 10.1393Z"
            fill="#00818A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5531 27.6116L15.628 30.065C14.8258 30.5664 13.786 29.9855 13.7924 29.0395L13.8013 27.7224L15.001 27.7306L14.9921 29.0477L18.9173 26.5942L19.5531 27.6116Z"
            fill="#00818A"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3994 12.1392H22.1968V13.3389H11.3994C10.7368 13.3389 10.1997 13.876 10.1997 14.5386V25.336C10.1997 25.9986 10.7368 26.5357 11.3994 26.5357H14.2105C14.2644 26.5486 14.3182 26.5645 14.3707 26.5842C14.5531 26.6524 14.7066 26.7588 14.8149 26.9271C14.9012 27.0612 14.9823 27.271 14.9977 27.6018L14.9974 27.7354H11.3994C10.0743 27.7354 9 26.6611 9 25.336V14.5386C9 13.2134 10.0743 12.1392 11.3994 12.1392ZM17.1931 27.7354H25.7959C27.1211 27.7354 28.1954 26.6611 28.1954 25.336V18.1377H26.9957V25.336C26.9957 25.9986 26.4585 26.5357 25.7959 26.5357H19.2457C19.1041 26.5493 18.998 26.5699 18.9096 26.5954C18.8622 26.6908 18.7863 26.7766 18.679 26.8412L17.1931 27.7354Z"
            fill="#00818A"
        />
    </svg>
);
export const verticalLine: JSX.Element = (
    <svg width="1" height="22" viewBox="0 0 1 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.5" y1="0.850342" x2="0.500001" y2="21.1497" stroke="#B0B0B0" />
    </svg>
);
export const chevronDown2: JSX.Element = (
    <svg width="16" height="16" viewBox="5 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.3633 12.3786C12.1657 12.5885 11.8321 12.5882 11.6348 12.3781L8.32797 8.85637C7.8918 8.39186 7.15451 8.39056 6.71671 8.85353C6.31418 9.27919 6.31301 9.94477 6.71403 10.3719L11.6344 15.6119C11.8318 15.8222 12.1658 15.8222 12.3633 15.612L17.2902 10.3671C17.6892 9.94236 17.6893 9.28061 17.2904 8.85578C16.8548 8.39194 16.1184 8.3914 15.6821 8.8546L12.3633 12.3786Z"
            fill="#3D3D3D"
        />
    </svg>
);

export const chevronUp2 = (
    <svg width="16" height="16" viewBox="5 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.6367 11.6214C11.8343 11.4115 12.1679 11.4118 12.3652 11.6219L15.672 15.1436C16.1082 15.6081 16.8455 15.6094 17.2833 15.1465C17.6858 14.7208 17.687 14.0552 17.286 13.6281L12.3656 8.3881C12.1682 8.17781 11.8342 8.17777 11.6367 8.38803L6.70978 13.6329C6.31078 14.0576 6.31072 14.7194 6.70963 15.1442C7.14517 15.6081 7.88164 15.6086 8.31786 15.1454L11.6367 11.6214Z"
            fill="#009AA6"
        />
    </svg>
);
