import { IHierarchyRole, IRole, ICurrentUser, IUser, IRoles } from '../domain/portal-domain';

const globalAdminRole = {
    name: 'administrator',
    hierarchyDisplayName: 'global',
    path: '',
};
const analyticsRoleNames = ['onshoremanagerall', 'administrator', 'analyticsmanager'];

const mapToRole = (role: IHierarchyRole): IRole => ({
    name: role.name,
    hierarchyDisplayName: role.hierarchyAssignmentDisplayPath,
    path: role.hierarchyAssignmentPath,
});

export const getUserWithRoles = (currentUser: ICurrentUser): IUser => {
    const globalRoles: IRole[] = currentUser.isGlobalAdmin ? [globalAdminRole] : [];
    const companyGroupRoles: IRole[] = currentUser.userRoles?.companyGroupsRoles?.map(mapToRole);
    const companyRoles: IRole[] = currentUser.userRoles?.companiesRoles?.map(mapToRole);
    const installationRoles: IRole[] = currentUser.userRoles?.installationRoles?.map(mapToRole);

    const roles = {
        global: globalRoles,
        companyGroup: companyGroupRoles,
        company: companyRoles,
        installation: installationRoles,
    } as IRoles;

    return {
        id: currentUser.id,
        name: (currentUser.firstName || '') + ' ' + (currentUser.lastName || ''),
        email: currentUser.email,
        isGlobalAdministrator: currentUser.isGlobalAdmin,
        isCompanyAdministrator: currentUser.isCompanyAdmin,
        roles,
    };
};

export const hasUserAnalyticsPermissions = (userRoles: IRoles): boolean => {
    if(userRoles.global?.length) {
        const globalRole = userRoles.global.find((role: IRole) => analyticsRoleNames.includes(role.name));
        if (globalRole) {
            return true;
        }
    }
    if(userRoles.companyGroup?.length) {
        const companyGroupRole = userRoles.companyGroup.find((role: IRole) => analyticsRoleNames.includes(role.name));
        if (companyGroupRole) {
            return true;
        }
    }
    if(userRoles.company?.length) {
        const companyRole = userRoles.company.find((role: IRole) => analyticsRoleNames.includes(role.name));
        if (companyRole) {
            return true;
        }
    }
    return false;
};
