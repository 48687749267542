import * as log from 'loglevel';
import { isEnvStaging, isEnvProdTest, isEnvProduction } from './env';

export default function setLoggingLevel() {
    let logLevel: log.LogLevelDesc;
    /*
        Available levels:
        "trace" - use log.trace() for very minor (distracting for other teammates) logs
        "debug" - use log.debug() for common debug logs
        "info", - use log.info()  for important logs like "job has been saved in database", "examination has been updated" etc
        "warn", - use log.warn()  for important logs but with non-positive status like "job has NOT been saved in database", "examination has NOT been updated" etc
        "error" - use log.error() for errors
    */

    if (isEnvProduction()) {
        logLevel = log.levels.INFO; // only important info will be shown
    } else if(isEnvProdTest()) {
        logLevel = log.levels.INFO;
    } else if(isEnvStaging()) {
        logLevel = log.levels.DEBUG; // only debug information will be shown
    } else {
        logLevel = log.levels.TRACE; // for development show everything, change it locally as you wish
    }

    /*
        This disables all logging below the given level, so that after a log.setLevel("warn") call log.warn("something") or log.error("something") will output messages,
        but log.info("something") will not.
    */
    log.setLevel(logLevel);
}