import React from 'react';
import styled from 'styled-components/macro';
import { linkedIn, help, feedbackIcon } from '../ui-icons/footer-icons';
import { axessDigitalLogo } from '../ui-icons/illustrations';
import { mainTheme } from '../styles/themes/main-theme';
import Emitter from './nav-menu/fresh-service/emitter';
import { getGeneralSurveyUrl } from './nav-menu/user-survey-url';

const FooterContainer = styled.div`
    background: ${mainTheme.colors.mainBackground};
    margin-top: auto;
`;

const Card = styled.div`
    color: ${mainTheme.colors.grey};
    background-color: ${mainTheme.colors.white};
    border-top: 1px solid ${mainTheme.colors.lineColor};
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    align-items: center;
    @media ${mainTheme.breakpoints.footerMaxWidth} {
        flex-flow: column;
    }
`;

const Left = styled.div`
    display: inherit;
`;

const Right = styled.div`
    display: inherit;
    margin-left: auto;
    @media ${mainTheme.breakpoints.footerMaxWidth} {
        margin: auto;
    }
`;

const Link = styled.a`
    font-size: ${mainTheme.fontSizes.big};
    color: ${mainTheme.colors.grey};
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: ${mainTheme.margins.bigger} ${mainTheme.margins.biggest};
    :hover {
        text-decoration: none;
    }
`;

const LinkedInLink = styled.a`
    font-size: ${mainTheme.fontSizes.big};
    color: ${mainTheme.colors.grey};
    display: flex;
    align-items: center;
    padding: ${mainTheme.margins.bigger} ${mainTheme.margins.biggest};
    :hover {
        text-decoration: none;
        color: ${mainTheme.colors.grey};
    }
`;

const Icon = styled.div`
    text-align: center;
    margin-right: 10px;
`;

const ShortText = styled.div`
    @media ${mainTheme.responsiveBreakpoints.phone} {
        display: none;
    }
    @media ${mainTheme.responsiveBreakpoints.normalScreen} {
        display: none;
    }
    @media ${mainTheme.responsiveBreakpoints.bigScreen} {
        display: none;
    }
`;

const LongText = styled.div`
    @media ${mainTheme.responsiveBreakpoints.phone} {
        display: none;
    }
    @media ${mainTheme.responsiveBreakpoints.tablet} {
        display: none;
    }
    @media ${mainTheme.responsiveBreakpoints.smallScreen} {
        display: none;
    }
`;

const SupportLink = styled.a`
    font-size: ${mainTheme.fontSizes.big};
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: ${mainTheme.margins.bigger} ${mainTheme.margins.normal};
    :hover {
        text-decoration: none;
    }
    color: ${mainTheme.colors.grey};
    padding-left: 40px;
    & ${Icon} svg path:nth-child(1) {
        stroke: ${mainTheme.colors.bridgePrimary};
    }

    & ${Icon} svg path:nth-child(2) {
        fill: ${mainTheme.colors.bridgePrimary};
    }
`;
const Help = styled.span`
    margin-top: 2px;
    color: ${mainTheme.colors.bridgePrimary};
    font-weight: bold;
`;
export interface IFooterProps {
    companyNotExisting?: boolean;
}
const generalSurveyUrl = getGeneralSurveyUrl();
export const Footer: React.FC<IFooterProps> = () => {
    return (
        <FooterContainer>
            <Card>
                <Left>
                    <SupportLink onClick={() => Emitter.emit('CALL_TO_OPEN_FS_DIALOG', true)}>
                        <Icon>
                            <span>{help}</span>
                        </Icon>
                        <ShortText>
                            <span>Help</span>
                        </ShortText>
                        <LongText>
                            <Help>Help and support</Help>
                        </LongText>
                    </SupportLink>
                    <SupportLink onClick={() => window.open(generalSurveyUrl, '_blank')}>
                        <Icon>
                            <span>{feedbackIcon}</span>
                        </Icon>
                        <ShortText>
                            <span>Feedback</span>
                        </ShortText>
                        <LongText>
                            <Help>Feedback</Help>
                        </LongText>
                    </SupportLink>
                </Left>
                <Right>
                    <LinkedInLink href="https://www.linkedin.com/company/axess-digital/?originalSubdomain=no" target="_blank">
                        <Icon>
                            <span>{linkedIn}</span>
                        </Icon>
                        <ShortText>
                            <span>LinkedIn</span>
                        </ShortText>
                        <LongText>
                            <span>LinkedIn</span>
                        </LongText>
                    </LinkedInLink>
                    <Icon>
                        <Link href="https://www.axessgroup.com/digital/products/" target="_blank">
                            <span>{axessDigitalLogo}</span>
                        </Link>
                    </Icon>
                </Right>
            </Card>
        </FooterContainer>
    );
};
