import React from 'react';
import styled from 'styled-components/macro';
import { mainTheme } from '../../../styles/themes/main-theme';
import { removeImage, stapleIcon, successIcon } from '../../../ui-icons/navbar-icons';
import { IUser } from '../../../domain/portal-domain';

const FD_ENDPOINT = 'axessdigital';
const PATH = '/api/v2/tickets';
const URL = 'https://' + FD_ENDPOINT + '.freshservice.com' + PATH;

const Label1 = styled.label`
    position: absolute;
    top: 80px;
    left: 40px;
    height: 40px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: ${mainTheme.colors.lightGrey};
`;
const Input1 = styled.input`
    position: absolute;
    top: 120px;
    left: 40px;
    height: 40px;
    padding: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${mainTheme.colors.grey};
    background: ${mainTheme.colors.white};
    border: 0.5px solid ${mainTheme.colors.inactiveInput};
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
        outline: none;
        border-color: ${mainTheme.colors.bridgePrimary};
    }
`;

const Label2 = styled.label`
    position: absolute;
    top: 160px;
    left: 40px;
    height: 40px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: ${mainTheme.colors.lightGrey};
`;

const Input2 = styled.input`
    position: absolute;
    top: 200px;
    left: 40px;
    height: 40px;
    padding: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: ${mainTheme.colors.grey};
    position: absolute;
    background: ${mainTheme.colors.white};
    border: 0.5px solid ${mainTheme.colors.inactiveInput};
    box-sizing: border-box;
    border-radius: 4px;

    &:focus {
        outline: none;
        border-color: ${mainTheme.colors.bridgePrimary};
    }
`;

const Label3 = styled.label`
    position: absolute;
    top: 240px;
    left: 40px;
    height: 40px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: ${mainTheme.colors.lightGrey};
`;

const TextArea = styled.textarea`
    position: absolute;
    top: 278px;
    left: 40px;

    font-family: 'Roboto';
    margin: 2px;
    padding-left: 10px;
    resize: none;
    overflow-y: auto;
    overflow-x: hidden;

    &:focus {
        outline: none;
        border-color: ${mainTheme.colors.bridgePrimary};
    }

    border: 0.5px solid ${mainTheme.colors.inactiveInput};
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-track {
        background: ${mainTheme.colors.white};
    }

    &::-webkit-scrollbar-thumb {
        background: ${mainTheme.colors.bridgescrollbar};
        border-radius: 5px;
        border: 1px solid ${mainTheme.colors.bridgescrollbar};
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${mainTheme.colors.bridgescrollbar};
        border-radius: 5px;
        border: 1px solid ${mainTheme.colors.bridgescrollbar};
    }
`;

const Label4 = styled.label`
    position: absolute;
    top: 400px;
    left: 40px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 14px;
    input[type='file'] {
        display: none;
    }
    display: inline-block;
    color: ${mainTheme.colors.bridgePrimary};
    cursor: pointer;
`;

const Input3 = styled.input`
    display: none;
    font-family: 'Roboto';
`;

const ImageContainer = styled.div`
    position: absolute;
    top: 440px;
    left: 40px;
    max-height: 140px;
    height: 140px;
    width: 430px;
    max-width: 430px;

    height: 150px;
    display: -webkit-box;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: scroll;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-track {
        background: ${mainTheme.colors.white};
    }

    &::-webkit-scrollbar-thumb {
        background: ${mainTheme.colors.bridgescrollbar};
        border-radius: 5px;
        border: 1px solid ${mainTheme.colors.bridgescrollbar};
    }

    &::-webkit-scrollbar-thumb:hover {
        background: ${mainTheme.colors.bridgescrollbar};
        border-radius: 5px;
        border: 1px solid ${mainTheme.colors.bridgescrollbar};
    }
`;
const Img = styled.img`
    border: 1px solid ${mainTheme.colors.inactiveInput};
    box-sizing: border-box;
    border-radius: 7px;
    z-index: -1;
`;

const Label5 = styled.label`
    font-family: 'Roboto';
    margin: 2px;
    padding: 2px;
    color: ${mainTheme.colors.error};
    font-weight: bold;
`;

const Btn = styled.button`
    color: ${mainTheme.colors.white};

    &.active {
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
        width: 92px;
        height: 40px;
        margin: 20px 4px 20px 40px;
        border-radius: 5px;
        background-color: ${mainTheme.colors.bridgePrimary};
        border: 1px solid ${mainTheme.colors.bridgePrimary};
        color: ${mainTheme.colors.white};
        cursor: pointer;
        pointer-events: visible;
    }

    &.inactive {
        font-size: 14px;
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
        width: 92px;
        height: 40px;
        margin: 20px 4px 20px 40px;
        border-radius: 5px;
        background-color: ${mainTheme.colors.inactiveInput};
        border: 1px solid ${mainTheme.colors.inactiveInput};
        color: ${mainTheme.colors.white};
        pointer-events: none;
    }
`;

const Form = styled.form`
    font-family: 'Roboto';
`;
const Footer = styled.div`
    width: 493px;
    height: 89px;
    display: flex;
    position: absolute;
    bottom: 0px;
`;

const ImageFrame = styled.div`
    height: auto;
    cursor: pointer;
    padding-right: 20px;
    vertical-align: top;
    padding-bottom: 10px;

    & svg {
        vertical-align: top;
        z-index: 1;
        float: right;
        position: relative;
        margin-left: -38px;
    }
`;

const HR = styled.hr`
    position: absolute;
    width: 456.7px;
    height: 0px;
    left: 21.88px;
    bottom: 88px;
    border: 0.5px solid ${mainTheme.colors.unactiveButtonBackground};
`;

const Span1 = styled.span`
    height: 40px;
    width: 272px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140.6%;
    text-decoration-line: underline;
    margin: 20px 20px 29px 33px;
    color: ${mainTheme.colors.lightGrey};
    cursor: pointer;
`;
const Span2 = styled.span`
    font-family: Roboto;
    padding-bottom: 0px;
    padding-left: 6px;
    padding-right: 0px;
    height: 49px;
    width: 92px;
`;

const SuccessMsg = styled.div`
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: 5px;
    padding: 80px 5px 80px 5px;
    color: ${mainTheme.colors.bridgePrimary};
    width: 98%;
`;

export interface FreshServicessAPIPOSTProps {
    userName: IUser;
}

export interface FreshServicesState {
    successMessage: string;
    errorMessage: string;
    api_call_error: string;
    subject: string;
    description: string;
    images: File[];
    imageURL: string[];
    activeButton: string;
    API_KEY: string;
}

class FreshServicessAPIPost extends React.Component<FreshServicessAPIPOSTProps, FreshServicesState> {
    private fileUpload: React.RefObject<HTMLInputElement>;

    constructor(props: FreshServicessAPIPOSTProps) {
        super(props);
        this.fileUpload = React.createRef();
        this.state = {
            errorMessage: '',
            successMessage: '',
            api_call_error: '',
            subject: '',
            description: '',
            images: [],
            imageURL: [],
            activeButton: '',
            API_KEY: '',
        };

        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this._removeImage = this._removeImage.bind(this);
    }

    componentDidMount(): void {
        fetch('secrets')
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                this.setState({ API_KEY: result.secret });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onImageChange(files: FileList): void {
        this.setState({ errorMessage: '' });
        //Ticket attachments. The total size of these attachments cannot exceed 15MB. https://api.freshservice.com/#tickets
        const maxAllowedSize = 15 * 1024 * 1024;
        const filesArray = Array.from(files);

        const currentSize = this.state.images.reduce((ac, curr) => ac + curr.size, 0);
        const totalFilesSize = filesArray.reduce((ac, curr) => ac + curr.size, currentSize);

        if (totalFilesSize > maxAllowedSize) {
            this.setState({ errorMessage: 'Your files exceeds limit of allowed 15MB' });
        } else {
            const images = [...this.state.images];
            const imagesUrl = [...this.state.imageURL];

            filesArray.forEach((f) => {
                imagesUrl.push(window.URL.createObjectURL(f));
                images.push(f);
            });
            this.setState({ images: images, imageURL: imagesUrl });
        }
    }

    async handleSubmit() {
        this.setState({ api_call_error: '', errorMessage: '' });
        let formData = new FormData();
        formData.append('email', this.props.userName.email);
        formData.append('subject', this.state.subject);
        formData.append('description', this.state.description);
        formData.append('priority', '2');
        formData.append('status', '2');
        formData.append('group_id', '27000097871');
        formData.append('category', 'N/A');
        formData.append('department_id', '27000062527');

        this.state.images.forEach((file) => formData.append('attachments[]', file));

        if (this.state.description === '' || this.state.subject === '') {
            this.setState({ errorMessage: 'Subject and Description are required fields!' });
        } else {
            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    Authorization: 'Basic ' + btoa(this.state.API_KEY + ':X'),
                },
                body: formData,
            });
            const data = await response.json();
            if (!data.ok) {
                let message: string = '';
                if (response.status !== 201) {
                    message = `An error has occurred: ${response.status} , ${response.statusText}`;
                }
                if (response.status === 201) {
                    this.setState({ successMessage: 'Ok' });
                }
                this.setState({ api_call_error: message, description: '', images: [], imageURL: [], subject: '' });
            } else {
                this.setState({
                    api_call_error: '',
                    errorMessage: '',
                    description: '',
                    images: [],
                    imageURL: [],
                    subject: '',
                    successMessage: 'Ok',
                });
            }
        }
    }

    _removeImage(index: number) {
        this.setState({
            images: this.state.images.filter((f, ind) => ind !== index),
            imageURL: this.state.imageURL.filter((f, ind) => ind !== index),
        });
    }

    handleSubjectChange(event: any) {
        this.setState({ subject: event.target.value });
    }
    handleDescriptionChange(event: any) {
        this.setState({ description: event.target.value });
    }

    render() {
        return (
            <div>
                {this.state.successMessage === '' ? (
                    <Form encType="multipart/form-data">
                        <Label1>Email</Label1>
                        <Input1 type="text" size={37} readOnly={true} disabled={true} value={this.props.userName.email} />
                        <Label2>Subject</Label2>
                        <Input2 type="text" size={37} autoFocus value={this.state.subject} onChange={this.handleSubjectChange} />
                        <Label3>Description</Label3>
                        <TextArea
                            className="fsTextArea"
                            aria-multiline
                            autoCorrect="true"
                            rows={4}
                            cols={50}
                            value={this.state.description}
                            onChange={this.handleDescriptionChange}
                        />
                        <Label4
                            onClick={(event) => {
                                event.stopPropagation();
                                this.fileUpload.current.click();
                            }}>
                            {stapleIcon} Add attachment(s)
                        </Label4>
                        <Input3
                            type="file"
                            accept="image/*"
                            name="file"
                            ref={this.fileUpload}
                            multiple={true}
                            onChange={() => {
                                this.onImageChange(this.fileUpload.current.files);
                                this.fileUpload.current.value = null;
                            }}
                        />
                        <ImageContainer>
                            {this.state.imageURL.length > 0 &&
                                this.state.imageURL.map((dt, index) => {
                                    return (
                                        <ImageFrame id={index.toString()} key={index} onClick={(event) => this._removeImage(index)}>
                                            {removeImage}
                                            <Img src={dt} width={180} height={180} />
                                        </ImageFrame>
                                    );
                                })}
                        </ImageContainer>
                        <Label5>{this.state.errorMessage}</Label5>
                        <Label5>{this.state.api_call_error}</Label5>
                        <HR />
                        <Footer>
                            <a href="https://www.freshworks.com/privacy/" target="_blank" rel="noopener noreferrer">
                                <Span1>Service Desk Software by Freshworks Privacy Policy</Span1>
                            </a>
                            <Span2>
                                <Btn
                                    className={this.state.subject !== '' && this.state.description !== '' ? 'active' : 'inactive'}
                                    type="button"
                                    value="Submit"
                                    onClick={this.handleSubmit}
                                    title="Submit">
                                    Submit
                                </Btn>
                            </Span2>
                        </Footer>
                    </Form>
                ) : (
                    <SuccessMsg>{successIcon}</SuccessMsg>
                )}
            </div>
        );
    }
}
export default FreshServicessAPIPost;
