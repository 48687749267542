import { error } from 'loglevel';
import { ModulesApiAdapter } from '../../../../data-providers/api/modules-api-adapter';
import { IApplication, IOption, IUser } from '../../../../domain/portal-domain';

const api = ModulesApiAdapter.getInstance();

export const loadModules = async (
    user: IUser,
    companyLegacyId: string,
    companyId: number,
    installationLegacyId?: string,
    installationId?: number
): Promise<IOption[]> => {
    let availableModules: IOption[] = [];

    try {
        const companyModules: IOption[] = await api.getAvailableCompanyModules(companyLegacyId, user.roles);
        availableModules = [...availableModules, ...companyModules];

        if (installationId) {
            const installationModules = await loadInstallationModules(companyId, installationId);
            availableModules = [...availableModules, ...installationModules];
        }
    } catch (e) {
        error('Cannot load company modules', e);
    }

    return availableModules;
};

export const loadInstallationModules = async (companyId: number, installationId: number): Promise<IOption[]> => {
    let availableModules: IOption[] = [];
    try {
        const installationModules: IOption[] = await api.getAvailableInstallationModules(companyId, installationId);
        if (installationModules) {
            availableModules = [...availableModules, ...installationModules];
        }
    } catch (e) {
        error('Cannot load installation modules', e);
    }

    return availableModules;
};

export const loadAllApllicationsForInstallation = async (companyId: number, installationId: number): Promise<IApplication[]> => {
    let availableApplications: IApplication[] = [];
    try {
        const installationApps: IApplication[] = await api.getAllApplicationsForInstallation(companyId, installationId);
        if (installationApps) {
            availableApplications = [...installationApps];
        }
    } catch (e) {
        error('Cannot load installation applications', e);
    }

    return availableApplications;
};
