import React from 'react';
import styled from 'styled-components';
import { ISelection, IUser, IOption } from '../../../../domain/portal-domain';
import { mainTheme } from '../../../../styles/themes/main-theme';
import { DropdownSearch } from '../../../dropdown/dropdown-search';
import { SelectionElement } from './selection-element';

const DropdownSearchContainer = styled.div`
    @media ${mainTheme.responsiveBreakpoints.normalAndBigScreen} {
        display: inline-block;
        width: ${mainTheme.widths.dropdownNormalAndUp};

        &:first-child {
            margin-right: ${mainTheme.containerMargins.big};
        }
    }
`;

const SingleCompanyContainer = styled.div`
    @media ${mainTheme.responsiveBreakpoints.phone} {
        width: ${mainTheme.widths.dropdownPhone};
    }
    @media ${mainTheme.responsiveBreakpoints.tablet} {
        width: ${mainTheme.widths.dropdownTablet};
    }
    @media ${mainTheme.responsiveBreakpoints.smallScreen} {
        width: ${mainTheme.widths.dropdownTablet};
    }
`;

const SingleCompany = styled.div`
    color: ${mainTheme.colors.grey};
    font-size: ${mainTheme.fontSizes.biggest};
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 25px;
    border-bottom: 1px solid ${mainTheme.colors.bridgePrimary};
    margin-bottom: ${mainTheme.containerMargins.normal};
`;

const SelectedElementWrapper = styled.div``;

const ScopeSelectors = styled.div`
    padding-bottom: 20px;
`;

interface ISelectionDropdown {
    user: IUser;
    currentSelection: ISelection;
    companyOptions: IOption[];
    installationOptions: IOption[];
    onSelectionHandler: (option: IOption) => void;
}

export const SelectionDropdown: React.FC<ISelectionDropdown> = ({
    currentSelection,
    user,
    companyOptions,
    installationOptions,
    onSelectionHandler,
}) => {
    if (currentSelection.installation.key) {
        return <SelectionElement label="You have selected the installation:" option={currentSelection.installation} />;
    } else if (currentSelection.company.key || companyOptions.length === 1) {
        let showCompany;
        if (companyOptions.length === 1) {
            currentSelection.company = companyOptions[0];
            showCompany = (
                <SingleCompanyContainer>
                    <SingleCompany>{companyOptions[0].value}</SingleCompany>
                </SingleCompanyContainer>
            );
        } else {
            showCompany = (
                <SelectedElementWrapper>
                    <SelectionElement label="You have selected the company:" option={currentSelection.company} />
                </SelectedElementWrapper>
            );
        }
        return (
            <>
                {showCompany}
                <ScopeSelectors>
                    <DropdownSearch
                        scope="installation"
                        label="Select installation"
                        user={user}
                        selectedOption={currentSelection.installation}
                        onSelectionHandler={onSelectionHandler}
                        options={installationOptions}></DropdownSearch>
                </ScopeSelectors>
            </>
        );
    } else {
        return (
            <ScopeSelectors>
                <DropdownSearchContainer>
                    <DropdownSearch
                        scope="company"
                        label="Select company"
                        user={user}
                        selectedOption={currentSelection.company}
                        onSelectionHandler={onSelectionHandler}
                        options={companyOptions}></DropdownSearch>
                </DropdownSearchContainer>
                <DropdownSearchContainer>
                    <DropdownSearch
                        scope="installation"
                        label="Select installation"
                        user={user}
                        selectedOption={currentSelection.installation}
                        onSelectionHandler={onSelectionHandler}
                        options={installationOptions}></DropdownSearch>
                </DropdownSearchContainer>
            </ScopeSelectors>
        );
    }
};
