import { IOption as IDomainOption } from '../../../domain/portal-domain';
import * as AnalyticsIcon from './icons/analytics';
import * as AiInspectionIcon from './icons/aiinspection';
import * as EDropsIcon from './icons/edrops';
import * as EquipIcon from './icons/equip';
import * as MhpIcon from './icons/mhp';
import * as NdtIcon from './icons/ndt';
import * as ProjectsIcon from './icons/projects';
import * as EventsAdminIcon from './icons/eventsadmin';

export enum ModuleType {
    DASHBOARD = 'dashboard',
    ANALYTICS = 'analytics',
    EDROPS = 'edrops',
    EQUIP = 'equip',
    MHP = 'mhp',
    NDT = 'ndt',
    PROJECTS = 'projects',
    EVENTS_ADMIN = 'eventsadmin',
    EVA = 'eva',
    AI_INSPECTION = 'aiinspection',
    VIEW_3D = '3dview',
}

export interface IModule {
    icon?: JSX.Element;
    hoverIcon?: JSX.Element;
    visible?: boolean;
    enabled?: boolean;
    isSelected?: boolean;

    readonly appName: string;
    readonly application?: IDomainOption;
    readonly url?: string;

    enable: () => IModule;
    select: (isSelected: boolean) => IModule;
    hide: () => IModule;
}

export class Module implements IModule {
    public icon?: JSX.Element;
    public hoverIcon?: JSX.Element;
    public visible?: boolean;
    public enabled?: boolean;
    public isSelected?: boolean;

    constructor(public readonly appName: string, public readonly application?: IDomainOption, public readonly url?: string) {
        this.visible = true;
        this.setIcons(appName, !!application);
    }

    private setIcons(appName: string, isActive: boolean, isSelected?: boolean) {
        switch (appName.toLowerCase()) {
            case ModuleType.ANALYTICS:
                if (isActive) {
                    this.icon = isSelected ? AnalyticsIcon.hovered : AnalyticsIcon.enabled;
                    this.hoverIcon = AnalyticsIcon.hovered;
                } else {
                    this.icon = AnalyticsIcon.disabled;
                    this.hoverIcon = AnalyticsIcon.disabled;
                }
                break;
            case ModuleType.DASHBOARD:
                if (isActive) {
                    this.icon = isSelected ? AnalyticsIcon.hovered : AnalyticsIcon.enabled;
                    this.hoverIcon = AnalyticsIcon.hovered;
                } else {
                    this.icon = AnalyticsIcon.disabled;
                    this.hoverIcon = AnalyticsIcon.disabled;
                }
                break;
            case ModuleType.EDROPS:
                if (isActive) {
                    this.icon = isSelected ? EDropsIcon.selected : EDropsIcon.available;
                    this.hoverIcon = EDropsIcon.selected;
                } else {
                    this.hoverIcon = EDropsIcon.notAvailable;
                    this.icon = EDropsIcon.notAvailable;
                }
                break;
            case ModuleType.EQUIP:
                if (isActive) {
                    this.icon = isSelected ? EquipIcon.selected : EquipIcon.available;
                    this.hoverIcon = EquipIcon.selected;
                } else {
                    this.icon = EquipIcon.notAvailable;
                    this.hoverIcon = EquipIcon.notAvailable;
                }
                break;
            case ModuleType.MHP:
                if (isActive) {
                    this.icon = isSelected ? MhpIcon.selected : MhpIcon.available;
                    this.hoverIcon = MhpIcon.selected;
                } else {
                    this.icon = MhpIcon.notAvailable;
                    this.hoverIcon = MhpIcon.notAvailable;
                }
                break;
            case ModuleType.NDT:
                if (isActive) {
                    this.icon = isSelected ? NdtIcon.selected : NdtIcon.available;
                    this.hoverIcon = NdtIcon.selected;
                } else {
                    this.icon = NdtIcon.notAvailable;
                    this.hoverIcon = NdtIcon.notAvailable;
                }
                break;
            case ModuleType.PROJECTS:
                if (isActive) {
                    this.icon = isSelected ? ProjectsIcon.selected : ProjectsIcon.available;
                    this.hoverIcon = ProjectsIcon.selected;
                } else {
                    this.icon = ProjectsIcon.notAvailable;
                    this.hoverIcon = ProjectsIcon.notAvailable;
                }
                break;
            case ModuleType.EVENTS_ADMIN:
                if (isActive) {
                    this.icon = isSelected ? EventsAdminIcon.selected : EventsAdminIcon.available;
                    this.hoverIcon = EventsAdminIcon.selected;
                } else {
                    this.hide();
                }
                break;
            case ModuleType.EVA:
                if (isActive) {
                    this.icon = isSelected ? EventsAdminIcon.selected : EventsAdminIcon.available;
                    this.hoverIcon = EventsAdminIcon.selected;
                } else {
                    this.hide();
                }
                break;
            case ModuleType.AI_INSPECTION:
                if (isActive) {
                    this.icon = isSelected ? AiInspectionIcon.hovered : AiInspectionIcon.enabled;
                    this.hoverIcon = AiInspectionIcon.hovered;
                } else {
                    this.icon = AiInspectionIcon.disabled;
                    this.hoverIcon = AiInspectionIcon.disabled;
                }
                break;
            case ModuleType.VIEW_3D:
                if (isActive) {
                    this.icon = isSelected ? AiInspectionIcon.hovered : AiInspectionIcon.enabled;
                    this.hoverIcon = AiInspectionIcon.hovered;
                } else {
                    this.icon = AiInspectionIcon.disabled;
                    this.hoverIcon = AiInspectionIcon.disabled;
                }
                break;
        }
    }

    public enable() {
        this.enabled = true;
        return this;
    }

    public select(isSelected?: boolean) {
        this.enable();
        this.isSelected = isSelected;
        this.setIcons(this.appName, !!this.application, isSelected);
        return this;
    }

    public hide() {
        this.visible = false;
        return this;
    }
}
