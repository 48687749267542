import { ICompany, ICurrentCompaniesAndInstallations, INotification } from '../domain/portal-domain';
import { getCompaniesWithInstallations } from '../data-providers/helpers';
import { mapNotifications, INotificationViewModel } from '../data-providers/notifications';
import { AuthHelper } from '@axbit/bridge-auth';
import { CurrentTokenStore } from './current-token-store';

export const fetchNotifications = async (userId: number): Promise<INotificationViewModel[]> => {
    var authOptions = await getAuthorizationOptions();
    return await fetch(`Notification/CurrentUserNotifications?userId=${userId}`, authOptions)
        .then((notifications) => {
            return notifications.json();
        })
        .then((notifications: INotification[]) => {
            return mapNotifications(notifications);
        })
        .catch((err) => {
            return [];
        });
};

export const archiveNotification = async (notificationId: number, userId: number): Promise<void> => {
    var authOptions = await getAuthorizationOptions();
    return await fetch(`Notification/ArchiveNotification?notificationId=${notificationId}&userId=${userId}`, authOptions)
        .then((response) => {
            return;
        })
        .catch((err) => {
            console.warn(err);
        });
};

export const unarchiveNotification = async (userId: number, notificationId: number): Promise<void> => {
    var authOptions = await getAuthorizationOptions();
    return await fetch(`Notification/UnarchiveNotification?userId=${userId}&notificationId=${notificationId}`, authOptions)
        .then((response) => {
            return;
        })
        .catch((err) => {
            console.warn(err);
        });
};

export const fetchCurrentCompaniesAndInstallations = async (): Promise<ICompany[]> => {
    var authOptions = await getAuthorizationOptions();
    return await fetch('User/CurrentCompaniesAndInstallations', authOptions)
        .then((currCompAndInsta) => {
            return currCompAndInsta.ok ? currCompAndInsta.json() : null;
        })
        .then((currCompAndInstaJson: ICurrentCompaniesAndInstallations[]) => {
            return getCompaniesWithInstallations(currCompAndInstaJson);
        })
        .catch((err) => {
            console.warn(err);
            return [];
        });
};

export const fetchCurrentCompaniesAndInstallationsForActor = async (): Promise<ICompany[]> => {
    var authOptions = await getAuthorizationOptions();
    return await fetch('User/CurrentCompaniesAndInstallationsForActor', authOptions)
        .then((currCompAndInsta) => {
            return currCompAndInsta.ok ?  currCompAndInsta.json() : null;
        })
        .then((currCompAndInstaJson: ICurrentCompaniesAndInstallations[]) => {
            return getCompaniesWithInstallations(currCompAndInstaJson);
        })
        .catch((err) => {
            console.warn(err);
            return [];
        });
};

export const getAuthorizationOptions = async (): Promise<RequestInit> => {
    const token = await AuthHelper.getInstance().getAccessTokenSilent();
    const tokenStore = new CurrentTokenStore();
    tokenStore.setCurrentToken(token || '');

    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    } as RequestInit;

    return options;
};
