import React from "react";
import styled from "styled-components/macro";
import {
  ISelection,
  IOption,
  OptionType,
} from "../../../../domain/portal-domain";
import { mainTheme } from "../../../../styles/themes/main-theme";
import { SearchResultsElement } from "./search-results-element";

const BiggerText = styled.span`
  font-size: ${mainTheme.fontSizes.bigger};
`;
const BoldedText = styled(BiggerText)`
  font-weight: bold;
  font-style: italic;
`;

const Separator = styled.div`
  margin-top: ${mainTheme.margins.biggest};
  margin-bottom: ${mainTheme.margins.normal};
  border-bottom: 2px solid ${mainTheme.colors.bridgePrimary};
  opacity: 0.15;
`;

const Header = styled.div`
  padding: ${mainTheme.containerMargins.normal};
  padding-bottom: ${mainTheme.margins.normal};
`;

const Body = styled.div``;

export interface ISearchResultsProps {
  currentSelection: ISelection;
  onSelectedOption: (option: IOption) => void;
}

export const SearchResults: React.FC<ISearchResultsProps> = React.memo(
  ({ currentSelection, onSelectedOption }) => {
    const companies = currentSelection.filteredOptions.filter(
      (x) => x.type === OptionType.COMPANY
    );
    const installations = currentSelection.filteredOptions.filter(
      (x) => x.type === OptionType.INSTALLATION
    );
    return (
      <>
        <Header>
          <BiggerText>
            {"Found " +
              currentSelection.filteredOptions.length +
              " results for the input "}
          </BiggerText>
          <BoldedText>{currentSelection.searchText}</BoldedText>
          <Separator />
        </Header>
        <Body>
          <SearchResultsElement
            label="companies"
            optionList={companies}
            onSelectedOption={onSelectedOption}
          />
          <SearchResultsElement
            label="installations"
            optionList={installations}
            onSelectedOption={onSelectedOption}
          />
        </Body>
      </>
    );
  }
);
