import { AuthenticationResult } from '@azure/msal-browser';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ModuleType } from './components/containers/module-selector/module';

/*
 * Inspection apps uses portal address as post login redirect URI.
 * This hook handles redirect to the correct URL after user comes back from authentication (B2C) end point.
 * The original requested URL is added to the authentication state, and received back from B2C after login.
 */
export function usePostLoginRedirect(result: AuthenticationResult): void {
    const history = useHistory();
    const resultState = result?.state;
    const isPortalLink = (urlSegments: string[]) => urlSegments.length > 0;

    const isModuleLink = (urlSegments: string[]) =>
        Object.values(ModuleType).reduce(
            (result, element) =>
                result ||
                urlSegments.some((x) => x.toLocaleLowerCase().replace('%23', '').replace('#', '') === element.toLocaleLowerCase()) ||
                (urlSegments.length > 2 ? !isNaN(Number(urlSegments[2].replace('%23', '').replace('#', ''))) : false),
            false
        );

    useEffect(() => {
        if (resultState) {
            const url = new URL(`${window.location.origin}`);
            const resultStateElements = resultState.split('#').filter((s) => s);
            url.pathname = resultStateElements[0] ?? '';
            url.hash = resultStateElements[1] ?? '';
            const urlSegments = url.pathname.split('/').filter((s) => s);
            if (urlSegments && urlSegments.length) {
                console.log('Redirecting to URL from pre-login:', resultState);
                if (isModuleLink(urlSegments)) {
                    // use window.open if redirecting out of portal
                    setTimeout(() => {
                        window.open(url.href, '_self');
                    }, 0);
                } else if (isPortalLink(urlSegments)) {
                    // use history.push if redirecting inside portal
                    history.push(url.pathname);
                }
            }
        }
    }, [resultState, history]);
}
