import * as React from "react";

export const notAvailable: JSX.Element = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8365 36 36 28.8366 36 20C36 11.1635 28.8365 4.00004 20 4.00004C11.1634 4.00004 3.99999 11.1635 3.99999 20C3.99999 28.8366 11.1634 36 20 36Z"
      fill="#8E8E8E"
    />
    <path
      d="M19.696 24.0735L8.75281 18.9059L19.696 13.7383L31.2472 18.9059L19.696 24.0735Z"
      fill="#8E8E8E"
    />
    <path
      d="M10.8762 22.8817L19.696 27.0466L29.0058 22.8817L31.2472 23.8844L19.696 29.052L8.75281 23.8844L10.8762 22.8817Z"
      fill="#8E8E8E"
    />
  </svg>
);

export const available: JSX.Element = (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM20 36C28.8365 36 36 28.8366 36 20C36 11.1635 28.8365 4.00004 20 4.00004C11.1634 4.00004 3.99999 11.1635 3.99999 20C3.99999 28.8366 11.1634 36 20 36Z"
      fill="#5B76E3"
    />
    <path
      d="M19.696 24.0735L8.75281 18.9059L19.696 13.7383L31.2472 18.9059L19.696 24.0735Z"
      fill="#3D3D3D"
    />
    <path
      d="M10.8762 22.8817L19.696 27.0466L29.0058 22.8817L31.2472 23.8844L19.696 29.052L8.75281 23.8844L10.8762 22.8817Z"
      fill="#3D3D3D"
    />
  </svg>
);

export const selected: JSX.Element = (
  <svg
    width="46"
    height="48"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 40C34.0457 40 43 31.0457 43 20C43 8.9543 34.0457 0 23 0C11.9543 0 3 8.9543 3 20C3 31.0457 11.9543 40 23 40ZM23 36C31.8365 36 39 28.8366 39 20C39 11.1635 31.8365 4.00004 23 4.00004C14.1634 4.00004 6.99999 11.1635 6.99999 20C6.99999 28.8366 14.1634 36 23 36Z"
        fill="#5B76E3"
      />
      <path
        d="M22.696 24.0735L11.7528 18.9059L22.696 13.7383L34.2472 18.9059L22.696 24.0735Z"
        fill="#3D3D3D"
      />
      <path
        d="M13.8762 22.8817L22.696 27.0466L32.0058 22.8817L34.2472 23.8844L22.696 29.052L11.7528 23.8844L13.8762 22.8817Z"
        fill="#3D3D3D"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="46"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
