import React from 'react';
import styled from 'styled-components/macro';
import { IOption, ISearchResult } from '../../../domain/portal-domain';
import { DrawOption } from './draw-option';
import { mainTheme } from '../../../styles/themes/main-theme';

const LabelElement = styled.span`
    display: block;
    font-weight: 300;
    color: ${mainTheme.colors.grey};
`;

const Labels = styled.div`
    height: 371px;
    overflow-y: auto;
    overflow-x: hidden;
`;

export interface IOptionElement {
    options: ISearchResult[];
    label: string;
}

export interface IOptionElementProps {
    element: IOptionElement;
    activeOption: number;
    onOptionClicked: (option: IOption) => void;
    isRecentSearches: boolean;
}

export const OptionElements: React.FC<IOptionElementProps> = ({ element, isRecentSearches, activeOption, onOptionClicked }) => {
    const { options, label } = element;
    return options && options.length > 0 ? (
        <Labels>
            <LabelElement>{label}</LabelElement>
            {options.map((option, index) => {
                return (
                    <DrawOption
                        isRecentSearch={isRecentSearches}
                        key={option.key + index + '_' + label}
                        option={option}
                        activeOption={activeOption}
                        index={index}
                        onOptionClicked={onOptionClicked}
                    />
                );
            })}
        </Labels>
    ) : (
        <LabelElement>{label}</LabelElement>
    );
};
