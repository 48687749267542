import React from "react";
import styled from "styled-components/macro";
import { getSearchResultText } from "../../../../data-providers/search-helpers";
import { IOption, ISearchResult } from "../../../../domain/portal-domain";
import { mainTheme } from "../../../../styles/themes/main-theme";

const BiggerText = styled.div`
  font-size: ${mainTheme.fontSizes.bigger};
  padding-left: ${mainTheme.containerMargins.normal};
  padding-bottom: ${mainTheme.margins.normal};
  padding-top: ${mainTheme.margins.normal};
`;

const Container = styled.div`
  padding: ${mainTheme.margins.small} 0;
`;

const Link = styled.a`
  display: flex;
  padding-top: ${mainTheme.margins.normal};
  padding-bottom: ${mainTheme.margins.normal};
  padding-left: ${mainTheme.containerMargins.biggest};
  font-size: ${mainTheme.fontSizes.big};
  cursor: pointer;
  text-decoration: underline;
  color: ${mainTheme.colors.bridgePrimary};
  &:hover {
    background: ${mainTheme.colors.hoverBackground};
    color: ${mainTheme.colors.grey};
  }
`;

const NormalText = styled.p`
  white-space: pre;
`;

const BoldedText = styled.p`
  font-weight: bold;
  white-space: pre;
`;

const SubLabel = styled.p`
  padding-left: 5px;
  white-space: nowrap;
`;

export interface ISearchResultsElementProps {
  optionList: ISearchResult[];
  label: string;
  onSelectedOption: (option: IOption) => void;
}

export const SearchResultsElement: React.FC<ISearchResultsElementProps> = React.memo(
  ({ label, optionList, onSelectedOption }) => {
    const onClickHandler = (option: ISearchResult) => {
      onSelectedOption(option as IOption);
    };
    return optionList.length && optionList.length > 0 ? (
      <>
        <BiggerText>{label.toUpperCase()}</BiggerText>
        {optionList.map((option) => {
          const searchResultText = getSearchResultText(option);
          return (
            <Container
              key={option.key + "_" + option.rootId}
              onClick={() => onClickHandler(option)}
            >
              <Link>
                <NormalText>{searchResultText.beforeText}</NormalText>
                <BoldedText>{searchResultText.searchedText}</BoldedText>
                <NormalText>{searchResultText.afterText}</NormalText>
                <SubLabel>{searchResultText.subElementText}</SubLabel>
              </Link>
            </Container>
          );
        })}
      </>
    ) : null;
  }
);
