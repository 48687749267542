import * as log from 'loglevel';

export const _CURRENT_TOKEN_KEY = 'token';

interface TokenEvent extends Event {
    result: string;
}

export class CurrentTokenStore {
    clearCurrentToken = (): Promise<boolean> => {
        log.debug(`Clearing current user from Bridge-Common indexedDB instance...`);
        return new Promise<boolean>((resolve, reject) => {
            this.openDb()
                .then((db) => {
                    var trans = db.transaction(['currenttoken'], 'readwrite');
                    trans.objectStore('currenttoken').delete(_CURRENT_TOKEN_KEY);
                    db.close();
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    setCurrentToken = (token: string): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            this.openDb()
                .then((db) => {
                    var trans = db.transaction(['currenttoken'], 'readwrite');
                    trans.objectStore('currenttoken').put({ key: _CURRENT_TOKEN_KEY, token: token });
                    db.close();
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    getCurrentToken = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            this.openDb()
                .then((db) => {
                    var trans = db.transaction(['currenttoken'], 'readwrite');
                    let request = trans.objectStore('currenttoken').get(_CURRENT_TOKEN_KEY);
                    request.onsuccess = () => {
                        resolve(request.result ? request.result.token : '');
                        db.close();
                    };
                    request.onerror = (event) => {
                        reject(event);
                        db.close();
                    };
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    openDb = (): Promise<IDBDatabase> => {
        return new Promise<IDBDatabase>((resolve, reject) => {
            let currentDBVersion = 2;
            var request = indexedDB.open('Bridge-Common', currentDBVersion);
            request.onsuccess = (event: Event) => {
                let db = (event.target as any).result;
                resolve(db);
            };
            request.onupgradeneeded = (event: Event) => {
                let db = (event.target as any).result as IDBDatabase;
                let currentTokenObject = db.objectStoreNames;
                if (!currentTokenObject.contains('currenttoken')) {
                    db.createObjectStore('currenttoken', { keyPath: 'key' });
                }
                if (!currentTokenObject.contains('currentuser')) {
                    db.createObjectStore('currentuser', { keyPath: 'key' });
                }
            };
            request.onerror = (event) => {
                reject(event);
            };
        });
    };
}
