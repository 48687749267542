import { IOption, ISearchResult, ISearchResultText } from '../domain/portal-domain';
import { getRecentSearchesFor } from '../data-providers/local-storage/recent-searches';
import { sortSearchResult } from './helpers';

export const getSearchResults = (companies: IOption[], installations: IOption[], searchString: string): ISearchResult[] => {
    const arrayOfResults = [...installations, ...companies];
    let result: ISearchResult[] =
        arrayOfResults.map((x) => {
            let valueSplits = x.value.split(' ');
            let splitIndex = -1;
            let indexOf = searchString && searchString.length > 0 ? x.value.toLowerCase().indexOf(searchString.toLowerCase()) : -1;
            let sortIndex = -1;
            if (valueSplits.length > 1) {
                valueSplits.forEach((split, splitNo) => {
                    let indexOfFindInSplit = split.toLowerCase().indexOf(searchString.toLowerCase());
                    if (indexOfFindInSplit > -1) {
                        sortIndex = (indexOfFindInSplit + 1) * 10 + splitNo;
                    }
                });
            } else {
                if (indexOf !== 0) {
                    sortIndex = 3000 + indexOf;
                }
            }

            return {
                ...x,
                startIndex: indexOf,
                endIndex: indexOf + searchString.length,
                splitIndex,
                sortIndex,
                searchValue: x.value.substr(indexOf),
            };
        }) || [];

    result = result.sort(sortSearchResult());
    result = result.sort((a, b) => {
        return a.sortIndex - b.sortIndex;
    });

    return result || [];
};

export const getSearchResultForRecentSearches = (userEmail: string): ISearchResult[] => {
    return (
        getRecentSearchesFor(userEmail)
            .reverse()
            .map((x) => {
                return {
                    ...x,
                    startIndex: -1,
                    endIndex: -1,
                    splitIndex: -1,
                    sortIndex: -1,
                    searchValue: '',
                };
            }) || []
    );
};

export const getSearchResultText = (option: ISearchResult): ISearchResultText => {
    let beforeText = '';
    let searchedText = '';
    let afterText = '';
    let subElementText = '';

    if (option.rootName) {
        subElementText = option.rootName;
    }

    if (option.startIndex < 0) {
        afterText = option.value;
    } else if (option.startIndex === 0) {
        searchedText = option.value.substring(option.startIndex, option.endIndex);
        afterText = option.value.substring(option.endIndex);
    } else if (option.startIndex > 0) {
        beforeText = option.value.substring(0, option.startIndex);
        searchedText = option.value.substring(option.startIndex, option.endIndex);
        afterText = option.value.substring(option.endIndex);
    }

    return {
        beforeText,
        searchedText,
        afterText,
        subElementText,
    };
};
