import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { IOption, ISelection } from '../../../domain/portal-domain';
import { mainTheme } from '../../../styles/themes/main-theme';
import { IModule, ModuleType } from './module';
import { getModuleIcon } from './module-routing-service';
import { isNapApp } from '../../../data-providers/helpers';

const ModuleText = styled.span`
    line-height: ${mainTheme.lineHeights.h2};
    font-size: ${mainTheme.fontSizes.normal};
    padding: ${mainTheme.margins.big} 0;
    user-select: none;
    text-align: center;
    white-space: nowrap;
`;

const ActiveText = styled(ModuleText)`
    color: ${mainTheme.colors.grey};
    font-weight: bold;
`;

const UnactiveText = styled(ModuleText)`
    color: ${mainTheme.colors.inactiveInput};
`;

const Icon = styled.div`
    display: inline-block;
`;

const Logo = styled.img`
    border-radius: 50px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    &:hover {
        margin-bottom: 5px;
        box-shadow: 0 0 10px 5px #cac6c6;
    }
`;

const DisabledLogo = styled.img`
    border-radius: 50px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    filter: grayscale(100%);
`;

const Option = styled.div<{ isActive: boolean | undefined }>`
    cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${mainTheme.margins.bigger};
    width: ${mainTheme.widths.moduleOption};
    min-height: ${mainTheme.heights.dropdownOption};
    margin-right: ${mainTheme.containerMargins.big};
    &:first-child {
        margin-left: 3px;
    }
    &:last-child {
        margin-right: 0px;
    }
    @media ${mainTheme.responsiveBreakpoints.phone} {
        margin-right: ${mainTheme.containerMargins.small};
    }
`;

const OptionUnderline = styled.div`
    border-bottom: 2px solid ${mainTheme.colors.grey};
    width: 90%;
    margin-top: -2px;
`;

export interface IModuleOption {
    currentSelection: ISelection;
    availableModules: IOption[];
    selectedModule: IOption;
    appId: number;
    label: string;
    logoUrl?: string;
    onSelect: (module: IOption) => void;
    onNotSupportedBrowser: (isNotSupported: boolean) => void;
}

export const ModuleOption: React.FC<IModuleOption> = React.memo(
    ({ availableModules, appId, label, logoUrl, currentSelection, selectedModule, onSelect, onNotSupportedBrowser }) => {
        useEffect(() => {
            const isDektop =
                mainTheme.deviceBreakpoints.isSmallScreen ||
                mainTheme.deviceBreakpoints.isNormalScreen ||
                mainTheme.deviceBreakpoints.isBigScreen;
            setIsDesktop(isDektop);
            if (selectedModule.id !== appId) {
                setSelectedOption({} as IOption);
            }
        }, [appId, selectedModule]);
        
        const [selectedOption, setSelectedOption] = useState({} as IOption);
        const [isDesktop, setIsDesktop] = useState(false);
        const [isHovered, setIsHovered] = useState(false);

        const isSelected = selectedOption.id === appId;
        let module: IModule;
        if (isNapApp(appId)) {
            module = getModuleIcon(
                appId,
                label,
                availableModules,
                false,
                currentSelection.company.key,
                currentSelection.installation.key,
                isSelected
            );
        } else {
            module = getModuleIcon(
                appId,
                label,
                availableModules,
                true,
                currentSelection.company.key,
                currentSelection.installation.key,
                isSelected
            );
        }

        const optionClickedHandler = (option?: IOption, url?: string) => {
            onSelect(option || ({} as IOption));
            let isServiceWorkerExist = false;
            let proceedValidator = false;
            if ('serviceWorker' in navigator) {
                isServiceWorkerExist = true;
            }

            if (
                option?.value.toLowerCase() === ModuleType.NDT.toLowerCase() ||
                option?.value.toLowerCase() === ModuleType.EQUIP.toLowerCase() ||
                option?.value.toLowerCase() === ModuleType.EDROPS.toLowerCase()
            ) {
                if (isServiceWorkerExist === false) {
                    proceedValidator = true;
                }
            }
            if (proceedValidator === false) {
                if (option) {
                    selectedOption.key ? setSelectedOption({} as IOption) : setSelectedOption(option);
                    setTimeout(() => {
                        window.open(url, '_self');
                    }, mainTheme.timeouts.navigation);
                } else {
                    setSelectedOption({} as IOption);
                }
            } else {
                onNotSupportedBrowser(true);
            }
        };

        const handleHover = (isHovered: boolean) => {
            setIsHovered(isHovered);
        };

        if (module && module.visible) {
            const displayIcon = isDesktop && isHovered ? module.hoverIcon : module.icon;
            return (
                !!module.application && 
                <Option
                    key={module.appName}
                    isActive={module.enabled}
                    onMouseOutCapture={() => handleHover(true)}
                    onMouseLeave={() => handleHover(false)}
                    onClick={() => optionClickedHandler(module.application, module.url)}>
                    <Icon key={module.appName}>{displayIcon}</Icon>
                    {appId >= 100 && (!!module.application ? <Logo src={logoUrl} /> : <DisabledLogo src={logoUrl} />)}
                    {module.enabled ? <ActiveText>{label}</ActiveText> : <UnactiveText>{label}</UnactiveText>}
                    {isSelected && <OptionUnderline />}
                </Option>
            );
        } else {
            return null;
        }
    }
);
