import * as React from 'react';
import styled from 'styled-components/macro';
import { mainTheme } from '../../../styles/themes/main-theme';
import { closeIcon } from '../../../ui-icons/navbar-icons';
import FreshServicessAPIPost from './fresh-service-createticket-component';
import FreshServicessAPISearch from './fresh-service-search-component';
import Modal from 'react-modal';
import Emitter from './emitter';
import { IUser } from '../../../domain/portal-domain';

const Row = styled.div`
    font-family: 'Roboto';
    display: flex;
    width: 800px;
    height: 700px;
`;
const Col1 = styled.div`
    font-family: 'Roboto';
    flex: 1.66665;
    width: 500px;
    height: 700px;
    background: ${mainTheme.colors.white};
`;
const Col2 = styled.div`
    font-family: 'Roboto';
    flex: 1;
    width: 300px;
    height: 700px;
    background: ${mainTheme.colors.mainBackground};
`;

const Close = styled.div`
    margin-left: 244px;
    margin-top: 20px;
    margin-right: 24px;
    margin-bottom: 0px;
    cursor: pointer;

    & svg {
        cursor: pointer;
    }
`;

const Header = styled.div`
    position: absolute;
    height: 29px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140.6%;
    color: ${mainTheme.colors.grey};
    padding-top: 40px;
    padding-left: 40px;
`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
    },
};
export interface FreshServicesDialogProps {
    userName: IUser;
}
export interface FreshServicesDialogState {
    modalIsOpen: boolean;
}

class FreshServicesDialog extends React.Component<FreshServicesDialogProps, FreshServicesDialogState> {
    constructor(props: FreshServicesDialogProps, state: FreshServicesDialogState) {
        super(props);
        this.state = {
            modalIsOpen: false,
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
    }

    componentDidMount() {
        Emitter.on('CALL_TO_OPEN_FS_DIALOG', () => this.setState({ modalIsOpen: true }));
        return () => {
            Emitter.off('CALL_TO_OPEN_FS_DIALOG', () => this.setState({ modalIsOpen: true }));
        };
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }
    afterOpenModal() {}
    render() {
        return (
            <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Help and Support">
                <Row>
                    <Col1>
                        <Header>Help and Support</Header>
                        <FreshServicessAPIPost userName={this.props.userName} />
                    </Col1>
                    <Col2>
                        <Close onClick={this.closeModal}>{closeIcon}</Close>
                        <FreshServicessAPISearch userName={this.props.userName} />
                    </Col2>
                </Row>
            </Modal>
        );
    }
}
export default FreshServicesDialog;
