import React from "react";
import styled from "styled-components/macro";
import { mainTheme } from "../../../styles/themes/main-theme";
import { lighthouse } from "../../../ui-icons/illustrations";

const MainBodyContainer = styled.div`
  background: ${mainTheme.colors.mainBackground};

  border-top: 1px solid ${mainTheme.colors.lineColor};
  min-height: fit-content;

  @media ${mainTheme.responsiveBreakpoints.bigScreen} {
    background: ${mainTheme.colors.darkerGrey};
    padding-left: ${mainTheme.margins.bigger};
    padding-right: ${mainTheme.margins.bigger};
  }
`;

const Container = styled.div`
  color: ${mainTheme.colors.grey};
  margin: 0 auto -97px;
  height: calc(100vh - 101px);

  @media ${mainTheme.responsiveBreakpoints.bigScreen} {
    background: ${mainTheme.colors.mainBackground};
    padding: 0 100px;
    width: ${mainTheme.widths.content};

    .row {
      margin: 0;
    }
    .row .col {
      padding: 0;
    }
  }
`;

const Separator = styled.div`
  margin-top: ${mainTheme.containerMargins.big};

  @media ${mainTheme.responsiveBreakpoints.tablet} {
    margin-top: ${mainTheme.containerMargins.biggest};
  }
  @media ${mainTheme.responsiveBreakpoints.smallScreen} {
    margin-top: ${mainTheme.containerMargins.huge};
  }
`;

const Header = styled.div`
  font-size: ${mainTheme.fontSizes.biggest};
  font-weight: 300;
  margin-bottom: ${mainTheme.containerMargins.small};
  padding: 0 ${mainTheme.containerMargins.huge};

  @media ${mainTheme.responsiveBreakpoints.phone} {
    padding: 0 ${mainTheme.containerMargins.big};
  }
  @media ${mainTheme.responsiveBreakpoints.bigScreen} {
    padding: 0;
  }
`;

const Message = styled.div`
  font-size: ${mainTheme.fontSizes.big};
  padding: 0 ${mainTheme.containerMargins.huge};

  @media ${mainTheme.responsiveBreakpoints.phone} {
    padding: 0 ${mainTheme.containerMargins.big};
  }
  @media ${mainTheme.responsiveBreakpoints.normalAndBigScreen} {
    width: 640px;
  }
  @media ${mainTheme.responsiveBreakpoints.bigScreen} {
    padding: 0;
  }
`;

const LandingPageLink = styled.a`
  color: ${mainTheme.colors.bridgePrimary};
  text-decoration: underline;

  &:hover {
    color: ${mainTheme.colors.bridgePrimary};
    text-decoration: none;
  }
`;

const LightHouse = styled.div`
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  position: absolute;
  width: 100%;

  div {
    margin: 0 auto;
    padding: ${mainTheme.containerMargins.small} 24% 160px 0;
  }
  @media ${mainTheme.responsiveBreakpoints.phoneHeight} {
    position: static;
  }
  @media ${mainTheme.responsiveBreakpoints.phone} {
    padding: 0 40px;

    div {
      padding: ${mainTheme.containerMargins.small} 38% 160px 0;
    }
    div svg {
      height: 100%;
      width: 100%;
    }
  }
  @media ${mainTheme.responsiveBreakpoints.normalScreen} {
    div {
      padding: ${mainTheme.containerMargins.small} 14% 160px 0;
    }
  }
  @media ${mainTheme.responsiveBreakpoints.bigScreen} {
    width: auto;

    div {
      padding: 0 0 160px 160px;
    }
  }
`;

export const PageNotFound: React.FC = () => {
  const isSmallerScreen =
    mainTheme.deviceBreakpoints.isPhone ||
    mainTheme.deviceBreakpoints.isTablet ||
    mainTheme.deviceBreakpoints.isSmallScreen;

  return (
    <MainBodyContainer>
      <Container className={`${isSmallerScreen ? "container-lg" : ""}`}>
        <div className="row">
          <div className="col">
            <Separator />
            <Header>404 - PAGE NOT FOUND</Header>
            <Message>
              Uh oh, we can’t seem to find the page you’re looking for. Go to
              the <LandingPageLink href="/">landing page</LandingPageLink> to
              navigate to the right destination.
            </Message>
          </div>
        </div>
        <LightHouse>
          <div>{lighthouse}</div>
        </LightHouse>
      </Container>
    </MainBodyContainer>
  );
};
