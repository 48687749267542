import React from 'react';
import styled from 'styled-components/macro';
import { IOption, ISelection, ICompany } from '../../domain/portal-domain';
import { NotificationsContainer } from './notifications/notifications-container';
import { mainTheme } from '../../styles/themes/main-theme';
import { INotificationViewModel } from '../../data-providers/notifications';

export interface IHeaderProps {
    currentSelection: ISelection;
    notifications: INotificationViewModel[];
    onSelectedOption: (option: IOption) => void;
    onArchivedNotification: (id: number) => void;
    onRestoreNotifications: (notificationId: number) => void;
    allCompaniesWithInstallations: ICompany[];
}

const HeaderContainer = styled.div`
    background: ${mainTheme.colors.white};
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
`;

export const Header: React.FC<IHeaderProps> = ({
    notifications,
    currentSelection,
    onSelectedOption,
    onArchivedNotification,
    onRestoreNotifications,
    allCompaniesWithInstallations,
}) => {
    return (
        <>
            <HeaderContainer>
                <NotificationsContainer
                    notifications={notifications}
                    onArchivedNotification={onArchivedNotification}
                    onRestoreNotifications={onRestoreNotifications}
                />
            </HeaderContainer>
        </>
    );
};
