import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import { ModuleSelector } from '../../module-selector';
import { IApplication, ICompany, IOption, ISelection, IUser } from '../../../../domain/portal-domain';
import { getAllCompaniesOptions, getAllInstallationOptions, getInstallationOptionsForCompany } from '../../../../data-providers/helpers';
import { mainTheme } from '../../../../styles/themes/main-theme';
import { AlertMessage } from '../error-messages/alert-message';
import { loadAllApllicationsForInstallation, loadModules } from './modules-service';
import { SelectionDropdown } from './selection-dropdown';

const MainBodyContainer = styled.div`
    background: ${mainTheme.colors.darkerGrey};
    padding-left: ${mainTheme.margins.bigger};
    padding-right: ${mainTheme.margins.bigger};
    min-height: fit-content;

    @media ${mainTheme.responsiveBreakpoints.smallScreens} {
        background: ${mainTheme.colors.mainBackground};
    }
`;

const Container = styled.div`
    padding-top: 30px;

    @media only screen and (max-width: 412px) {
        padding-top: 0px;
    }
    @media ${mainTheme.responsiveBreakpoints.normalAndBigScreen} {
        position: relative;
        margin: 0 auto;
        background: ${mainTheme.colors.mainBackground};
        padding: 30px 100px;
        width: ${mainTheme.widths.content};
        margin-top: 2px;
        height: calc(100vh - 115px);

        .row {
            margin: 0;
        }
        .row .col {
            padding: 0;
        }
    }
`;

const ModuleSelectors = styled.div`
    padding-right: ${mainTheme.margins.biggest};
    margin-bottom: ${mainTheme.margins.biggest};
`;

const Separator = styled.div`
    margin-top: ${mainTheme.containerMargins.big};
    @media ${mainTheme.responsiveBreakpoints.tablet} {
        margin-top: ${mainTheme.containerMargins.biggest};
    }
    @media ${mainTheme.responsiveBreakpoints.smallScreen} {
        margin-top: ${mainTheme.containerMargins.huge};
    }
`;

const Content = styled.div`
    width: max-content;
    margin: 0 auto;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export interface ISelectionFormProps {
    user: IUser;
    currentSelection: ISelection;
    companiesWithInstallations: ICompany[];
    installationNotExisting: boolean;

    onSelectedOption: (option: IOption) => void;
    onNotSupportedBrowser: (isNotSupported: boolean) => void;
}

export const SelectionForm: React.FC<ISelectionFormProps> = React.memo(
    ({ currentSelection, companiesWithInstallations, installationNotExisting, user, onSelectedOption, onNotSupportedBrowser }) => {
        const [modules, setModules] = useState<IOption[]>([]);
        const [allAppsInInstallation, setAllAppsInInstallation] = useState<IApplication[]>([]);
        const [selectionState, setSelectionState] = useState<ISelection>({} as ISelection);
        const [isLoadingModules, setIsLoadingModules] = useState(false);

        const load = useCallback(async () => {
            const isCompanyChanged = !!currentSelection.company.key && selectionState.company?.key !== currentSelection.company.key;
            const isInstallationChanged = selectionState.installation?.key !== currentSelection.installation.key;

            if (!currentSelection.company?.id || isCompanyChanged || isInstallationChanged) {
                setIsLoadingModules(true);
                setSelectionState(currentSelection);
                const modules = await loadModules(
                    user,
                    currentSelection.company.key,
                    currentSelection.company.id,
                    currentSelection.installation.key,
                    currentSelection.installation.id
                );
                const allApps = await loadAllApllicationsForInstallation(currentSelection.company.id, currentSelection.installation.id);
                setModules(modules);
                setAllAppsInInstallation(allApps);
                setIsLoadingModules(false);
            }
        }, [currentSelection, selectionState, user]);

        useEffect(() => {
            load();
        }, [load]);

        const companies: IOption[] = getAllCompaniesOptions(companiesWithInstallations);
        let installations: IOption[] = getAllInstallationOptions(companiesWithInstallations);

        let selectedCompany: ICompany | undefined = companiesWithInstallations.find((x) => x.staticName === currentSelection.company.key);
        if (selectedCompany) {
            installations = getInstallationOptionsForCompany(selectedCompany);
        }

        const isSmallerScreen =
            mainTheme.deviceBreakpoints.isPhone || mainTheme.deviceBreakpoints.isTablet || mainTheme.deviceBreakpoints.isSmallScreen;

        return (
            <MainBodyContainer>
                {installationNotExisting && (
                    <div className="row">
                        <AlertMessage installationNotExisting={installationNotExisting} />
                    </div>
                )}
                <Container className={`${isSmallerScreen ? 'container-lg' : ''}`}>
                    <Row>
                        <Separator />
                        <Content>
                            <SelectionDropdown
                                user={user}
                                currentSelection={currentSelection}
                                companyOptions={companies}
                                installationOptions={installations}
                                onSelectionHandler={onSelectedOption}
                            />
                            <ModuleSelectors>
                                <ModuleSelector
                                    currentSelection={currentSelection}
                                    availableModules={modules}
                                    allAppsInInstallation={allAppsInInstallation}
                                    isLoadingModules={isLoadingModules}
                                    onNotSupportedBrowser={onNotSupportedBrowser}
                                    user={user}
                                />
                            </ModuleSelectors>
                        </Content>
                    </Row>
                </Container>
            </MainBodyContainer>
        );
    }
);
