import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { IOption, ISearchResult } from '../../../domain/portal-domain';
import { mainTheme } from '../../../styles/themes/main-theme';
import { getSearchResultText } from '../../../data-providers/search-helpers';

const FlexElement = styled.div`
    justify-content: flex-start;
    align-items: center;
`;

const ListItem = styled.li`
    cursor: pointer;
    list-style: none;
    color: ${mainTheme.colors.grey};
    padding: ${mainTheme.margins.small} 0;
    display: flex;
    margin-left: 16px;
    &:hover {
        background: ${mainTheme.colors.hoverBackground};
        color: ${mainTheme.colors.grey};
    }
`;

const NormalText = styled.p`
    white-space: pre;
`;
const BoldedText = styled.p`
    font-weight: bold;
    white-space: pre;
    color: ${mainTheme.colors.bridgePrimary};
`;

const Label = styled.div`
    display: flex;
    margin-top: 2px;
    padding-left: 3px;
    font-size: 16px;
    color: ${mainTheme.colors.grey};
`;

const SubLabel = styled.div`
    padding-left: 5px;
    margin-top: 2px;
    font-weight: 300;
    white-space: nowrap;
    font-size: 14px;
    color: rgba(176, 176, 176, 1);
    font-weight: 400;
`;

const SelectedListItem = styled.li`
    background: ${mainTheme.colors.hoverBackground};
    color: ${mainTheme.colors.grey};
    display: flex;
    padding: ${mainTheme.margins.small} 0;
    &:hover {
        background: ${mainTheme.colors.hoverBackground};
        color: ${mainTheme.colors.grey};
    }
`;

export interface IDrawOptionProps {
    isRecentSearch: boolean;
    option: ISearchResult;
    activeOption: number;
    index: number;
    onOptionClicked: (option: IOption) => void;
}

export const DrawOption: React.FC<IDrawOptionProps> = ({ isRecentSearch, option, activeOption, index, onOptionClicked }) => {
    let icon;

    useEffect(() => {
        const selectedItem = document.getElementById('selectedSearchItem');
        if (selectedItem && activeOption) {
            selectedItem.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
            });
        }
    }, [activeOption]);

    const searchResultText = getSearchResultText(option);

    const selectHandler = (option: ISearchResult) => {
        onOptionClicked(option as IOption);
    };

    if (index === activeOption) {
        return (
            <SelectedListItem onClick={() => selectHandler(option)} key={Math.random()} id="selectedSearchItem">
                <FlexElement>
                    {icon}
                    <Label>
                        <NormalText>{searchResultText.beforeText}</NormalText>
                        <BoldedText>{searchResultText.searchedText}</BoldedText>
                        <NormalText>{searchResultText.afterText}</NormalText>
                    </Label>
                    <SubLabel>{searchResultText.subElementText}</SubLabel>
                </FlexElement>
            </SelectedListItem>
        );
    } else {
        return (
            <ListItem onClick={() => onOptionClicked(option)} key={Math.random()}>
                <FlexElement>
                    {icon}
                    <Label>
                        <NormalText>{searchResultText.beforeText}</NormalText>
                        <BoldedText>{searchResultText.searchedText}</BoldedText>
                        <NormalText>{searchResultText.afterText}</NormalText>
                    </Label>
                    <SubLabel>{searchResultText.subElementText}</SubLabel>
                </FlexElement>
            </ListItem>
        );
    }
};
