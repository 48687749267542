import * as React from 'react';

export const fish: JSX.Element = (
    <svg width="356" height="119" viewBox="0 0 356 119" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.2"
            d="M105.678 25.5902C69.665 29.1394 0 3.65018 0 3.65018C0 3.65018 79.1111 40.4321 122.799 33.0112C166.488 25.5902 190.693 11.7166 232.61 8.4899C273.93 5.30914 303.86 13.8819 353.822 28.193L356 28.8167C313.8 9.96899 277.479 -1.1898 232.61 0.101056C187.741 1.39191 141.692 22.0411 105.678 25.5902Z"
            fill="#00818A"
        />
        <g opacity="0.5">
            <path
                d="M267.376 55.7462C262.136 56.6818 252 49.9623 252 49.9623C252 49.9623 263.511 59.6588 269.867 57.7025C276.224 55.7462 279.746 52.0888 285.844 51.2381C291.943 50.3875 296.337 52.7255 303.797 56.5967C297.657 51.628 292.373 48.6863 285.844 49.0266C279.316 49.3669 272.616 54.8105 267.376 55.7462Z"
                fill="#00818A"
            />
            <path
                d="M268.579 61.2538C263.339 60.3182 253.203 67.0377 253.203 67.0377C253.203 67.0377 264.713 57.3412 271.07 59.2975C277.426 61.2538 280.948 64.9112 287.047 65.7619C293.146 66.6125 297.539 64.2745 305 60.4033C298.86 65.372 293.575 68.3137 287.047 67.9734C280.519 67.6331 273.818 62.1895 268.579 61.2538Z"
                fill="#00818A"
            />
            <path
                d="M298.214 57.3356C298.214 58.2751 297.445 59.0368 296.496 59.0368C295.547 59.0368 294.778 58.2751 294.778 57.3356C294.778 56.3961 295.547 55.6345 296.496 55.6345C297.445 55.6345 298.214 56.3961 298.214 57.3356Z"
                fill="#00818A"
            />
        </g>
        <g opacity="0.3">
            <path
                d="M58.8331 57.1956C56.1637 57.6387 51 54.4558 51 54.4558C51 54.4558 56.8639 59.0489 60.1021 58.1222C63.3404 57.1956 65.1345 55.4631 68.2415 55.0602C71.3485 54.6572 73.5865 55.7647 77.3874 57.5985C74.2594 55.2449 71.5673 53.8514 68.2415 54.0126C64.9157 54.1738 61.5024 56.7524 58.8331 57.1956Z"
                fill="#00818A"
            />
            <path
                d="M59.4457 59.8044C56.7763 59.3613 51.6126 62.5442 51.6126 62.5442C51.6126 62.5442 57.4765 57.9511 60.7147 58.8778C63.953 59.8044 65.7472 61.5369 68.8541 61.9398C71.9611 62.3428 74.1992 61.2353 78 59.4015C74.872 61.7551 72.1799 63.1486 68.8541 62.9874C65.5284 62.8262 62.1151 60.2476 59.4457 59.8044Z"
                fill="#00818A"
            />
            <path
                d="M74.5429 57.9484C74.5429 58.3935 74.1511 58.7543 73.6677 58.7543C73.1844 58.7543 72.7925 58.3935 72.7925 57.9484C72.7925 57.5034 73.1844 57.1426 73.6677 57.1426C74.1511 57.1426 74.5429 57.5034 74.5429 57.9484Z"
                fill="#00818A"
            />
        </g>
        <g opacity="0.7">
            <path
                d="M158.66 99.0069C150.256 100.533 134 89.57 134 89.57C134 89.57 152.46 105.391 162.655 102.199C172.849 99.0069 178.498 93.0396 188.279 91.6517C198.06 90.2638 205.106 94.0785 217.071 100.395C207.224 92.2879 198.749 87.4882 188.279 88.0435C177.809 88.5987 167.063 97.4803 158.66 99.0069Z"
                fill="#00818A"
            />
            <path
                d="M160.588 107.993C152.185 106.467 135.929 117.43 135.929 117.43C135.929 117.43 154.389 101.609 164.583 104.801C174.778 107.993 180.426 113.96 190.207 115.348C199.989 116.736 207.034 112.922 219 106.605C209.153 114.712 200.677 119.512 190.207 118.957C179.737 118.401 168.992 109.52 160.588 107.993Z"
                fill="#00818A"
            />
            <path
                d="M208.117 101.6C208.117 103.133 206.883 104.376 205.361 104.376C203.84 104.376 202.606 103.133 202.606 101.6C202.606 100.067 203.84 98.8247 205.361 98.8247C206.883 98.8247 208.117 100.067 208.117 101.6Z"
                fill="#00818A"
            />
        </g>
    </svg>
);

export const telescope: JSX.Element = (
    <svg width="160" height="131" viewBox="0 0 160 131" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="91.5859" width="25.2762" height="9.87247" transform="rotate(-29.6071 0 91.5859)" fill="#00818A" fillOpacity="0.05" />
        <rect
            x="2.50854"
            y="95.7695"
            width="26.8898"
            height="4.86222"
            transform="rotate(-29.6071 2.50854 95.7695)"
            fill="#00818A"
            fillOpacity="0.05"
        />
        <rect
            x="22.2378"
            y="76.5078"
            width="22.4455"
            height="13.9851"
            transform="rotate(-29.6071 22.2378 76.5078)"
            fill="#00818A"
            fillOpacity="0.05"
        />
        <rect x="22.0142" y="79.1133" width="1.67351" height="9.8147" transform="rotate(-29.6071 22.0142 79.1133)" fill="#00818A" />
        <rect x="41.1953" y="65.6172" width="1.67351" height="14.0345" transform="rotate(-29.6071 41.1953 65.6172)" fill="#00818A" />
        <path
            d="M71.0166 46.0977C71.8202 45.641 72.8418 45.9223 73.2984 46.7259L80.4475 59.3069C80.9041 60.1105 80.6229 61.1321 79.8193 61.5887L71.0166 46.0977Z"
            fill="#00818A"
        />
        <rect
            x="41.625"
            y="62.7383"
            width="33.821"
            height="18.0264"
            transform="rotate(-29.6071 41.625 62.7383)"
            fill="#00818A"
            fillOpacity="0.05"
        />
        <rect
            x="45.6167"
            y="71.3945"
            width="34.4603"
            height="8.5217"
            transform="rotate(-29.6071 45.6167 71.3945)"
            fill="#00818A"
            fillOpacity="0.05"
        />
        <rect
            x="25.6597"
            y="82.4414"
            width="21.7344"
            height="7.01855"
            transform="rotate(-29.6071 25.6597 82.4414)"
            fill="#00818A"
            fillOpacity="0.05"
        />
        <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.9231 87.5585C39.8573 87.2004 38.1229 85.8757 37.2028 84.0671L48.5946 77.5938C49.2366 78.6074 49.6083 79.8092 49.6083 81.0978C49.6083 83.9812 47.7472 86.4299 45.1608 87.3083L70.1343 129.85L67.2834 129.85L45.0455 92.2811V130.419H42.1944V93.1981L20.2417 130.42L17.1055 130.419L41.9231 87.5585Z"
            fill="#00818A"
        />
        <path
            opacity="0.7"
            d="M132.154 34.5377C132.304 34.0771 132.956 34.0771 133.106 34.5377L134.438 38.6388C134.505 38.8448 134.697 38.9843 134.914 38.9843L139.226 38.9843C139.71 38.9843 139.911 39.6041 139.52 39.8888L136.031 42.4233C135.856 42.5507 135.782 42.7764 135.849 42.9824L137.182 47.0834C137.332 47.544 136.804 47.9271 136.412 47.6424L132.924 45.1078C132.749 44.9805 132.511 44.9805 132.336 45.1078L128.848 47.6424C128.456 47.9271 127.928 47.544 128.078 47.0834L129.411 42.9824C129.478 42.7764 129.404 42.5507 129.229 42.4233L125.74 39.8888C125.349 39.6041 125.55 38.9843 126.034 38.9843L130.346 38.9843C130.563 38.9843 130.755 38.8448 130.822 38.6388L132.154 34.5377Z"
            fill="#00818A"
        />
        <path
            opacity="0.45"
            d="M153.252 11.7252C153.402 11.2646 154.054 11.2646 154.203 11.7252L155.024 14.2503C155.091 14.4563 155.283 14.5958 155.499 14.5958H158.154C158.639 14.5958 158.84 15.2156 158.448 15.5003L156.3 17.0608C156.125 17.1881 156.052 17.4138 156.119 17.6198L156.939 20.1448C157.089 20.6055 156.561 20.9886 156.17 20.7039L154.022 19.1433C153.846 19.016 153.609 19.016 153.434 19.1433L151.286 20.7039C150.894 20.9886 150.367 20.6055 150.517 20.1448L151.337 17.6198C151.404 17.4138 151.331 17.1881 151.155 17.0608L149.008 15.5003C148.616 15.2156 148.817 14.5958 149.301 14.5958H151.956C152.173 14.5958 152.365 14.4563 152.432 14.2503L153.252 11.7252Z"
            fill="#00818A"
        />
        <path
            opacity="0.25"
            d="M123.316 1.46352C123.466 1.00287 124.118 1.00287 124.267 1.46353L124.512 2.21553C124.579 2.42154 124.771 2.56102 124.987 2.56102H125.778C126.262 2.56102 126.464 3.18083 126.072 3.46553L125.432 3.93029C125.257 4.05761 125.184 4.2833 125.25 4.48931L125.495 5.24131C125.644 5.70196 125.117 6.08503 124.725 5.80033L124.086 5.33556C123.91 5.20824 123.673 5.20824 123.498 5.33556L122.858 5.80033C122.466 6.08503 121.939 5.70196 122.089 5.24131L122.333 4.48931C122.4 4.28329 122.327 4.05761 122.151 3.93029L121.512 3.46553C121.12 3.18083 121.321 2.56102 121.806 2.56102H122.596C122.813 2.56102 123.005 2.42154 123.072 2.21553L123.316 1.46352Z"
            fill="#00818A"
        />
    </svg>
);

export const close: JSX.Element = (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.85678 5.37389L12 10.5954L17.1432 5.37389L18.8576 7.1144L13.7144 12.3359L18.8576 17.5575L17.1432 19.298L12 14.0765L6.85678 19.298L5.14238 17.5575L10.2856 12.3359L5.14238 7.1144L6.85678 5.37389Z"
            fill="#3D3D3D"
        />
    </svg>
);

export const minimize: JSX.Element = (
    <svg width="15" height="3" viewBox="0 0 15 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1.5" y1="1.5" x2="13.5" y2="1.5" stroke="#3D3D3D" strokeWidth="3" strokeLinecap="round" />
    </svg>
);

export const union: JSX.Element = (
    <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9843 7.82055C15.0627 7.82055 16.0421 7.40961 16.7653 6.74034L22.7514 11.3564C22.1147 12.3439 21.7467 13.5112 21.7467 14.7618C21.7467 18.3128 24.7135 21.1915 28.3732 21.1915C32.033 21.1915 34.9998 18.3128 34.9998 14.7618C34.9998 11.2108 32.033 8.33218 28.3732 8.33218C26.5402 8.33218 24.8809 9.05436 23.6813 10.2215L17.6384 5.56166C17.8796 5.05998 18.0144 4.5004 18.0144 3.91028C18.0144 1.75069 16.2101 0 13.9843 0C11.7586 0 9.95428 1.75069 9.95428 3.91028C9.95428 6.06986 11.7586 7.82055 13.9843 7.82055ZM13.9844 6.61794C15.5253 6.61794 16.7745 5.40592 16.7745 3.91083C16.7745 2.41573 15.5253 1.20372 13.9844 1.20372C12.4436 1.20372 11.1944 2.41573 11.1944 3.91083C11.1944 5.40592 12.4436 6.61794 13.9844 6.61794ZM7.98991 20.3985L21.788 16.0587L22.2137 17.333L8.41561 21.6727L7.98991 20.3985ZM8.43878 22.4113C8.43878 24.6724 6.54969 26.5053 4.21939 26.5053C1.88909 26.5053 0 24.6724 0 22.4113C0 20.1503 1.88909 18.3173 4.21939 18.3173C6.54969 18.3173 8.43878 20.1503 8.43878 22.4113ZM7.13914 22.4113C7.13914 23.9766 5.83132 25.2456 4.21803 25.2456C2.60475 25.2456 1.29692 23.9766 1.29692 22.4113C1.29692 20.8459 2.60475 19.577 4.21803 19.577C5.83132 19.577 7.13914 20.8459 7.13914 22.4113ZM32.9614 14.7611C32.9614 17.2195 30.9074 19.2124 28.3737 19.2124C25.8401 19.2124 23.7861 17.2195 23.7861 14.7611C23.7861 12.3027 25.8401 10.3098 28.3737 10.3098C30.9074 10.3098 32.9614 12.3027 32.9614 14.7611Z"
            fill="#169BA6"
        />
    </svg>
);

export const bridge: JSX.Element = (
    <svg width="58" height="12" viewBox="0 0 58 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 11.8396V0.160428H4.2758C5.75696 0.160428 6.88041 0.433155 7.64614 0.97861C8.41187 1.51872 8.79474 2.31283 8.79474 3.36096C8.79474 3.93316 8.64103 4.4385 8.33362 4.87701C8.02621 5.31016 7.59863 5.62834 7.05088 5.83155C7.67688 5.98128 8.16874 6.28342 8.52645 6.73797C8.88976 7.19251 9.07141 7.74866 9.07141 8.40642C9.07141 9.52941 8.69693 10.3797 7.94796 10.9572C7.199 11.5348 6.13144 11.8289 4.7453 11.8396H0ZM2.51518 6.75401V9.90642H4.66985C5.26231 9.90642 5.72343 9.77273 6.05319 9.50535C6.38855 9.23262 6.55623 8.85829 6.55623 8.38235C6.55623 7.31283 5.97774 6.77005 4.82076 6.75401H2.51518ZM2.51518 5.05348H4.37641C5.64518 5.03209 6.27956 4.54813 6.27956 3.6016C6.27956 3.07219 6.11747 2.69251 5.79329 2.46257C5.4747 2.22727 4.96887 2.10963 4.2758 2.10963H2.51518V5.05348Z"
            fill="#3D3D3D"
        />
        <path
            d="M15.4851 7.56417H13.4814V11.8396H10.9662V0.160428H15.5019C16.9439 0.160428 18.0562 0.467915 18.8387 1.08289C19.6212 1.69786 20.0124 2.56684 20.0124 3.68984C20.0124 4.48663 19.8308 5.15241 19.4675 5.68717C19.1098 6.21658 18.5648 6.63904 17.8326 6.95455L20.4735 11.7273V11.8396H17.7739L15.4851 7.56417ZM13.4814 5.61497H15.5103C16.1419 5.61497 16.6309 5.46257 16.9774 5.15775C17.324 4.84759 17.4973 4.42246 17.4973 3.88235C17.4973 3.33155 17.3324 2.8984 17.0026 2.58289C16.6784 2.26738 16.1782 2.10963 15.5019 2.10963H13.4814V5.61497Z"
            fill="#3D3D3D"
        />
        <path d="M24.6068 11.8396H22.0916V0.160428H24.6068V11.8396Z" fill="#3D3D3D" />
        <path
            d="M26.9459 11.8396V0.160428H30.7019C31.7751 0.160428 32.7336 0.393048 33.5776 0.858289C34.4272 1.31818 35.0895 1.97594 35.5646 2.83155C36.0397 3.68182 36.2772 4.64973 36.2772 5.73529V6.27273C36.2772 7.35829 36.0425 8.32353 35.573 9.16845C35.1091 10.0134 34.4523 10.6684 33.6028 11.1337C32.7532 11.5989 31.7946 11.8342 30.7271 11.8396H26.9459ZM29.4611 2.10963V9.90642H30.6768C31.6605 9.90642 32.4123 9.59893 32.9321 8.98396C33.4519 8.36898 33.7174 7.4893 33.7285 6.34492V5.72727C33.7285 4.54011 33.4714 3.64171 32.9572 3.03209C32.443 2.41711 31.6912 2.10963 30.7019 2.10963H29.4611Z"
            fill="#3D3D3D"
        />
        <path
            d="M47.7716 10.3636C47.3189 10.8824 46.6789 11.2861 45.8517 11.5749C45.0245 11.8583 44.1078 12 43.1018 12C42.0454 12 41.1176 11.7807 40.3183 11.3422C39.5246 10.8984 38.9098 10.2567 38.4738 9.41711C38.0435 8.57754 37.8227 7.59091 37.8115 6.45722V5.6631C37.8115 4.49733 38.0155 3.4893 38.4235 2.63904C38.8371 1.78342 39.4296 1.13102 40.2009 0.681818C40.9778 0.227273 41.8861 0 42.9257 0C44.3733 0 45.5052 0.331551 46.3212 0.994652C47.1372 1.65241 47.6207 2.6123 47.7716 3.87433H45.3235C45.2117 3.20588 44.963 2.71658 44.5773 2.40642C44.1973 2.09626 43.6719 1.94118 43.0012 1.94118C42.146 1.94118 41.4948 2.24866 41.0477 2.86364C40.6006 3.47861 40.3742 4.39305 40.3686 5.60695V6.35294C40.3686 7.57754 40.6117 8.50267 41.098 9.12834C41.5843 9.75401 42.2969 10.0668 43.2359 10.0668C44.1805 10.0668 44.854 9.87433 45.2564 9.4893V7.47594H42.9676V5.70321H47.7716V10.3636Z"
            fill="#3D3D3D"
        />
        <path
            d="M57.1616 6.77808H52.3325V9.90642H58V11.8396H49.8173V0.160428H57.9832V2.10963H52.3325V4.89305H57.1616V6.77808Z"
            fill="#3D3D3D"
        />
    </svg>
);

export const chevronRight: JSX.Element = (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.6899 7.61763C6.92887 7.81767 6.9286 8.18519 6.68935 8.38488L1.42466 12.7791C0.681878 13.3991 0.679895 14.5397 1.42052 15.1623C1.99715 15.647 2.83836 15.6484 3.41668 15.1657L11.5402 8.38537C11.7796 8.18552 11.7797 7.81762 11.5403 7.61772L3.41058 0.82941C2.83542 0.349149 1.99906 0.349065 1.4238 0.829209C0.682085 1.44829 0.68126 2.58767 1.42208 3.20783L6.6899 7.61763Z"
            fill="#00818A"
        />
    </svg>
);

export const chevronRight2: JSX.Element = (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.37864 5.63666C4.58846 5.83426 4.58821 6.16786 4.37811 6.36515L0.856371 9.67203C0.391862 10.1082 0.390563 10.8455 0.85353 11.2833C1.27919 11.6858 1.94477 11.687 2.37185 11.286L7.6119 6.36561C7.82219 6.16815 7.82222 5.83419 7.61197 5.63669L2.3671 0.709782C1.94236 0.310784 1.28061 0.310717 0.85578 0.709628C0.391941 1.14517 0.3914 1.88164 0.854599 2.31786L4.37864 5.63666Z"
            fill="#00818A"
        />
    </svg>
);

export const chevronUp: JSX.Element = (
    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.288 8.12078L13.8047 6.58442L7.30469 -2.84124e-07L0.804687 6.58441L2.32135 8.12078L7.30469 3.07273L12.288 8.12078Z"
            fill="#00818A"
        />
    </svg>
);

export const chevronLeft: JSX.Element = (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.3101 8.38237C5.07113 8.18233 5.0714 7.81481 5.31065 7.61511L10.5753 3.2209C11.3181 2.60093 11.3201 1.46029 10.5795 0.837743C10.0028 0.353033 9.16164 0.35157 8.58332 0.834271L0.459807 7.61463C0.220361 7.81448 0.220323 8.18238 0.459729 8.38228L8.58942 15.1706C9.16458 15.6509 10.0009 15.6509 10.5762 15.1708C11.3179 14.5517 11.3187 13.4123 10.5779 12.7922L5.3101 8.38237Z"
            fill="#00818A"
        />
    </svg>
);
export const chevronLeft2: JSX.Element = (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.62136 6.36334C3.41154 6.16574 3.41179 5.83214 3.62189 5.63485L7.14363 2.32797C7.60814 1.8918 7.60944 1.15451 7.14647 0.716706C6.72081 0.31418 6.05523 0.313006 5.62815 0.714028L0.388101 5.63438C0.17781 5.83185 0.177775 6.16581 0.388026 6.36331L5.63289 11.2902C6.05764 11.6892 6.71939 11.6893 7.14422 11.2904C7.60806 10.8548 7.6086 10.1184 7.1454 9.68214L3.62136 6.36334Z"
            fill="#00818A"
        />
    </svg>
);
export const chevronDown: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.3625 20.8888C20.1649 21.0986 19.8313 21.0984 19.6341 20.8883L13.88 14.7603C13.153 13.9861 11.9242 13.9839 11.1945 14.7556C10.5236 15.465 10.5217 16.5743 11.19 17.2861L19.6336 26.2782C19.8311 26.4885 20.165 26.4886 20.3625 26.2783L28.817 17.2782C29.482 16.5703 29.4821 15.4674 28.8173 14.7593C28.0914 13.9862 26.8639 13.9853 26.1369 14.7573L20.3625 20.8888Z"
            fill="#00818A"
        />
    </svg>
);

export const axessDigitalLogo: JSX.Element = (
    <svg width="105" height="40" viewBox="0 0 105 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M55.7579 8.15828L58.5449 3.67794H56.785L54.8058 7.02985L52.7155 3.67794H50.9346L53.8868 8.25232L51.7215 11.7134L52.4483 13.3727L54.8419 9.32614L57.4277 13.5304H59.2057L55.7579 8.15828Z"
            fill="#344547"
        />
        <path
            d="M60.6178 13.5304V3.67794H66.9144L66.7105 4.93681H62.117V7.7518H65.9939V9.01067H62.117V12.217H67.0733V13.5304H60.6178Z"
            fill="#344547"
        />
        <path
            d="M75.6469 10.6101C75.6496 11.1236 75.514 11.6281 75.2549 12.069C74.9885 12.5285 74.609 12.9098 74.1537 13.1754C73.4291 13.564 72.6179 13.7556 71.7988 13.7316C71.1989 13.7312 70.6015 13.6526 70.0213 13.4975C69.4483 13.3472 68.8978 13.1192 68.3845 12.8198L68.983 11.6314C69.4323 11.9012 69.9127 12.1135 70.4133 12.2636C70.8952 12.4019 71.3938 12.4704 71.8945 12.4672C72.1806 12.4734 72.4663 12.4418 72.7443 12.373C72.9736 12.3104 73.1904 12.2076 73.3847 12.069C73.6004 11.9505 73.7805 11.775 73.9058 11.5609C74.0312 11.3469 74.0972 11.1024 74.0969 10.8533C74.1013 10.669 74.0671 10.4859 73.9964 10.3161C73.9257 10.1463 73.8202 9.9937 73.6869 9.86855C73.2802 9.54002 72.8022 9.31493 72.2925 9.21205L70.94 8.83821C70.3155 8.68667 69.7504 8.34778 69.3181 7.86561C68.931 7.43096 68.7176 6.86518 68.7197 6.27907C68.7173 5.87495 68.8032 5.47533 68.971 5.10892C69.1392 4.75531 69.3824 4.44392 69.6832 4.19712C70.0053 3.92984 70.3722 3.7239 70.7664 3.58925C71.2085 3.44151 71.6715 3.36861 72.1369 3.37346C72.732 3.37475 73.3242 3.45964 73.8964 3.62572C74.4343 3.76766 74.9417 4.00985 75.3925 4.33997L74.7522 5.43413C73.9992 4.92615 73.1157 4.6545 72.2117 4.65302C71.7123 4.62896 71.2178 4.76275 70.7963 5.03598C70.6274 5.14592 70.4891 5.29816 70.3947 5.47807C70.3004 5.65798 70.2532 5.8595 70.2577 6.06328C70.2543 6.22086 70.2862 6.37718 70.351 6.52041C70.4158 6.66363 70.5118 6.79001 70.6318 6.88998C71.0071 7.17019 71.4347 7.36978 71.8885 7.47658L73.1363 7.82914C73.4954 7.92788 73.8434 8.06438 74.1747 8.23642C74.4756 8.38672 74.7497 8.58703 74.9856 8.82909C75.193 9.06226 75.3545 9.33359 75.4614 9.62844C75.5816 9.94146 75.6445 10.2742 75.6469 10.6101Z"
            fill="#344547"
        />
        <path
            d="M83.8171 10.6101C83.8193 11.1232 83.6849 11.6273 83.4281 12.069C83.1601 12.5273 82.781 12.9081 82.3269 13.1754C81.6013 13.564 80.789 13.7556 79.969 13.7316C79.3701 13.7313 78.7737 13.6526 78.1945 13.4975C77.6204 13.3476 77.0688 13.1196 76.5547 12.8198L77.1532 11.6314C77.6025 11.9012 78.0829 12.1135 78.5835 12.2636C79.0702 12.4033 79.574 12.4719 80.0797 12.4672C80.3658 12.4734 80.6515 12.4418 80.9295 12.373C81.159 12.311 81.3759 12.2081 81.5699 12.069C81.786 11.9511 81.9664 11.7756 82.0919 11.5614C82.2173 11.3473 82.2831 11.1025 82.282 10.8533C82.2863 10.6693 82.2523 10.4865 82.1821 10.3168C82.112 10.1471 82.0074 9.99432 81.8751 9.86853C81.4652 9.5397 80.9842 9.31466 80.4717 9.21203L79.1222 8.83819C78.4971 8.68825 77.9315 8.3491 77.5003 7.86559C77.1119 7.43172 76.8983 6.86542 76.9018 6.27904C76.898 5.87476 76.9839 5.4748 77.1532 5.10889C77.3194 4.754 77.5629 4.44219 77.8654 4.19708C78.1863 3.92984 78.5523 3.72389 78.9456 3.58921C79.3878 3.44195 79.8507 3.36906 80.3161 3.37341C80.9112 3.37471 81.5033 3.4596 82.0756 3.62568C82.6095 3.77062 83.1119 4.01598 83.5568 4.34905L82.9164 5.44322C82.1672 4.93323 81.2868 4.65841 80.3849 4.65298C79.8805 4.62655 79.3806 4.7604 78.9546 5.03594C78.786 5.14604 78.6481 5.29839 78.5543 5.47833C78.4605 5.65826 78.4139 5.85971 78.419 6.06325C78.4149 6.22062 78.4463 6.37686 78.5105 6.5201C78.5748 6.66334 78.6704 6.78983 78.79 6.88996C79.1654 7.17016 79.593 7.36975 80.0468 7.47655L81.2946 7.82912C81.6548 7.92734 82.0039 8.06385 82.3359 8.23639C82.6385 8.38535 82.9138 8.5858 83.1498 8.82907C83.3592 9.06069 83.521 9.33246 83.6256 9.62842C83.7493 9.94061 83.8143 10.2736 83.8171 10.6101Z"
            fill="#344547"
        />
        <path d="M42.1289 13.5304L45.7324 5.16503L49.345 13.5304H51.0355L45.7324 1.44336L40.4445 13.5304H42.1289Z" fill="#344547" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.7356 23.8078V37.6033H43.2903C44.9809 37.6033 46.2068 37.5059 46.9679 37.3112C47.729 37.1165 48.4058 36.7898 48.9985 36.3312C49.7471 35.7532 50.3211 34.9837 50.7203 34.0225C51.1196 33.0551 51.3192 31.9525 51.3192 30.715C51.3192 29.4774 51.1196 28.378 50.7203 27.4169C50.3211 26.4557 49.7409 25.6704 48.9798 25.0611C48.4058 24.6025 47.7196 24.2789 46.9211 24.0905C46.1225 23.902 44.7719 23.8078 42.8692 23.8078H40.7356ZM43.7301 36.5197H41.9802V24.8443H43.3277C44.8998 24.8443 46.0009 24.926 46.631 25.0893C47.2611 25.2527 47.7945 25.5322 48.2312 25.928C48.8113 26.4431 49.248 27.1027 49.5412 27.9069C49.8407 28.711 49.9904 29.6407 49.9904 30.6961C49.9904 31.7264 49.8438 32.6436 49.5506 33.4477C49.2574 34.2518 48.8238 34.924 48.2499 35.4643C47.8381 35.8475 47.3359 36.1207 46.7433 36.2841C46.1506 36.4411 45.1462 36.5197 43.7301 36.5197Z"
            fill="#344547"
        />
        <path d="M54.0985 23.8078V37.6033H55.343V23.8078H54.0985Z" fill="#344547" />
        <path
            d="M67.9386 25.3061C68.7496 25.7521 69.4327 26.4086 69.9879 27.2755L70.8956 26.484C70.3591 25.5039 69.5949 24.7627 68.603 24.2601C67.6111 23.7512 66.4195 23.4968 65.0283 23.4968C64.0052 23.4968 63.0757 23.6727 62.2397 24.0245C61.41 24.3763 60.6645 24.9071 60.0032 25.617C59.3918 26.2704 58.9239 27.0399 58.5995 27.9257C58.2751 28.8052 58.1129 29.7412 58.1129 30.7338C58.1129 32.8949 58.7555 34.6413 60.0406 35.9731C61.3258 37.3049 63.007 37.9708 65.0845 37.9708C67.1744 37.9708 68.8151 37.3489 70.0066 36.105C71.1982 34.8612 71.794 33.1399 71.794 30.9411V30.7904H65.7021V31.874H70.4277C70.4028 33.344 69.9068 34.5345 68.9399 35.4454C67.9791 36.3563 66.7314 36.8118 65.1968 36.8118C64.3858 36.8118 63.6403 36.6736 62.9603 36.3972C62.2803 36.1145 61.6658 35.6936 61.1168 35.1344C60.574 34.5691 60.1592 33.922 59.8722 33.1933C59.5915 32.4645 59.4511 31.6887 59.4511 30.8657C59.4511 29.0251 59.9845 27.5268 61.0513 26.3709C62.1181 25.215 63.4999 24.637 65.1968 24.637C66.2136 24.637 67.1276 24.86 67.9386 25.3061Z"
            fill="#344547"
        />
        <path d="M74.4235 23.8078V37.6033H75.6681V23.8078H74.4235Z" fill="#344547" />
        <path d="M81.5167 24.9857V37.6033H82.7613V24.9857H86.5418V23.8078H77.7455V24.9857H81.5167Z" fill="#344547" />
        <path
            d="M88.837 31.8552H88.8344L88.9054 31.68L89.0119 31.4074L89.0159 31.4075L91.2675 25.8526L93.6069 31.8552H93.5999L94.0055 32.9294H94.0093L94.462 34.1386L95.77 37.6033H97.1161L91.3517 23.2612L85.3346 37.6033H86.5911L87.747 34.6451L88.4133 32.9294H88.4173L88.837 31.8552Z"
            fill="#344547"
        />
        <path d="M98.6601 23.8078V37.6033H104.602V36.4254H99.9047V23.8078H98.6601Z" fill="#344547" />
        <path d="M40.4445 18.0005H99.9558V18.5083H40.4445V18.0005Z" fill="#0098AF" />
        <g>
            <path
                d="M18.1907 1.44336C14.5932 1.4443 11.0768 2.51928 8.08605 4.53237C5.09532 6.54546 2.76458 9.40625 1.38854 12.753C0.012497 16.0998 -0.347052 19.7822 0.355354 23.3348C1.05776 26.8873 2.79058 30.1503 5.3347 32.7113C7.87883 35.2722 11.12 37.0161 14.6484 37.7224C18.1768 38.4288 21.834 38.0658 25.1576 36.6794C28.4811 35.293 31.3217 32.9455 33.3203 29.9336C35.3188 26.9218 36.3855 23.3809 36.3855 19.7587C36.3855 17.3531 35.9149 14.971 35.0004 12.7486C34.086 10.5262 32.7457 8.50691 31.0561 6.80611C29.3665 5.10531 27.3606 3.75631 25.1532 2.83616C22.9457 1.91601 20.5798 1.44273 18.1907 1.44336ZM22.1017 28.2123C20.1457 28.2123 18.2336 27.6285 16.607 26.5347C14.9803 25.4409 13.7122 23.8862 12.9628 22.067C12.2134 20.2478 12.0164 18.2458 12.3966 16.3138C12.7768 14.3819 13.7173 12.6068 15.099 11.2129C16.4808 9.81892 18.242 8.86863 20.1599 8.48209C22.0779 8.09555 24.0667 8.29011 25.8748 9.04118C27.683 9.79225 29.2296 11.0661 30.319 12.7019C31.4084 14.3376 31.9919 16.2617 31.9956 18.2312C31.9975 19.5409 31.743 20.8381 31.2467 22.0486C30.7504 23.2592 30.022 24.3594 29.1031 25.2863C28.1842 26.2133 27.093 26.9488 25.8916 27.4509C24.6903 27.9529 23.4025 28.2117 22.1017 28.2123Z"
                fill="url(#paint0_linear)"
            />
            <path
                d="M18.1907 1.44336C14.5932 1.4443 11.0768 2.51928 8.08605 4.53237C5.09532 6.54546 2.76458 9.40625 1.38854 12.753C0.012497 16.0998 -0.347052 19.7822 0.355354 23.3348C1.05776 26.8873 2.79058 30.1503 5.3347 32.7113C7.87883 35.2722 11.12 37.0161 14.6484 37.7224C18.1768 38.4288 21.834 38.0658 25.1576 36.6794C28.4811 35.293 31.3217 32.9455 33.3203 29.9336C35.3188 26.9218 36.3855 23.3809 36.3855 19.7587C36.3855 17.3531 35.9149 14.971 35.0004 12.7486C34.086 10.5262 32.7457 8.50691 31.0561 6.80611C29.3665 5.10531 27.3606 3.75631 25.1532 2.83616C22.9457 1.91601 20.5798 1.44273 18.1907 1.44336ZM22.1017 28.2123C20.1457 28.2123 18.2336 27.6285 16.607 26.5347C14.9803 25.4409 13.7122 23.8862 12.9628 22.067C12.2134 20.2478 12.0164 18.2458 12.3966 16.3138C12.7768 14.3819 13.7173 12.6068 15.099 11.2129C16.4808 9.81892 18.242 8.86863 20.1599 8.48209C22.0779 8.09555 24.0667 8.29011 25.8748 9.04118C27.683 9.79225 29.2296 11.0661 30.319 12.7019C31.4084 14.3376 31.9919 16.2617 31.9956 18.2312C31.9975 19.5409 31.743 20.8381 31.2467 22.0486C30.7504 23.2592 30.022 24.3594 29.1031 25.2863C28.1842 26.2133 27.093 26.9488 25.8916 27.4509C24.6903 27.9529 23.4025 28.2117 22.1017 28.2123Z"
                fill="url(#paint1_linear)"
            />
            <path
                d="M33.5643 15.3913C33.5643 8.01643 26.6808 2.02588 18.1854 2.02588C11.6242 2.02588 6.0207 5.59157 3.81152 10.6179C7.6041 12.4938 10.5433 15.2003 12.2121 18.3889C12.2121 18.3364 12.2121 18.2887 12.2121 18.2362C12.213 16.6217 12.6035 15.0317 13.3502 13.603C14.0968 12.1742 15.1771 10.9496 16.498 10.0345C17.819 9.11947 19.341 8.54136 20.9331 8.34998C22.5252 8.1586 24.1396 8.35969 25.6374 8.93593C27.1352 9.51218 28.4715 10.4463 29.5311 11.6579C30.5908 12.8695 31.3422 14.3223 31.7205 15.8911C32.0988 17.46 32.0928 19.0979 31.7029 20.6639C31.3131 22.2299 30.5511 23.677 29.4825 24.8807C30.7529 23.6497 31.7679 22.1771 32.4686 20.5481C33.1692 18.9191 33.5417 17.1663 33.5643 15.3913Z"
                fill="url(#paint2_linear)"
            />
            <path
                opacity="0.32"
                d="M24.5521 35.5726C20.2527 37.1622 15.5072 37.0013 11.3237 35.1242C7.14021 33.2471 3.84836 29.8017 2.14746 25.5199C2.19961 25.6775 2.24702 25.8398 2.30865 25.9973C3.09155 28.2001 4.29997 30.225 5.86417 31.955C7.42837 33.6851 9.31738 35.0862 11.4222 36.0773C13.527 37.0685 15.8059 37.6301 18.1274 37.7298C20.4489 37.8295 22.767 37.4653 24.9479 36.6582C27.1289 35.8511 29.1295 34.6171 30.8344 33.0274C32.5392 31.4377 33.9144 29.5238 34.8808 27.3961C35.8471 25.2684 36.3854 22.9691 36.4646 20.6309C36.5437 18.2926 36.1622 15.9617 35.342 13.7727C35.2851 13.6152 35.2187 13.4625 35.1571 13.2954C36.6187 17.668 36.3284 22.4422 34.3479 26.6026C32.3674 30.7629 28.8529 33.9811 24.5521 35.5726Z"
                fill="white"
            />
        </g>
        <defs>
            <linearGradient id="paint0_linear" x1="33.0433" y1="9.29074" x2="3.26139" y2="30.0013" gradientUnits="userSpaceOnUse">
                <stop stopColor="#64C0CE" />
                <stop offset="0.46" stopColor="#2FAABD" stopOpacity="0.97" />
                <stop offset="0.81" stopColor="#0D9CB2" stopOpacity="0.96" />
                <stop offset="1" stopColor="#0097AE" stopOpacity="0.95" />
            </linearGradient>
            <linearGradient id="paint1_linear" x1="-0.345556" y1="19.7587" x2="40.2966" y2="19.7587" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0" />
                <stop offset="0.09" stopColor="white" stopOpacity="0.07" />
                <stop offset="0.26" stopColor="white" stopOpacity="0.24" />
                <stop offset="0.49" stopColor="white" stopOpacity="0.52" />
                <stop offset="0.55" stopColor="white" stopOpacity="0.59" />
                <stop offset="0.66" stopColor="white" stopOpacity="0.43" />
                <stop offset="0.82" stopColor="white" stopOpacity="0.2" />
                <stop offset="0.94" stopColor="white" stopOpacity="0.05" />
                <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="paint2_linear" x1="13.8097" y1="19.4772" x2="22.2605" y2="12.0978" gradientUnits="userSpaceOnUse">
                <stop stopColor="#0097AE" stopOpacity="0.01" />
                <stop offset="0.47" stopColor="#00798A" stopOpacity="0.51" />
                <stop offset="0.81" stopColor="#006674" stopOpacity="0.83" />
                <stop offset="1" stopColor="#005F6C" stopOpacity="0.95" />
            </linearGradient>
        </defs>
    </svg>
);

export const searchIcon: JSX.Element = (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.8332 22.9001H23.8118L23.4567 22.5477C24.711 21.0851 25.4738 19.1889 25.4738 17.1114C25.4738 12.4934 21.7303 8.75 17.1119 8.75C12.4936 8.75 8.75 12.4934 8.75 17.1114C8.75 21.7295 12.4936 25.4729 17.1119 25.4729C19.1895 25.4729 21.0845 24.7114 22.5472 23.4584L22.9022 23.8109V24.8297L28.372 30.2915C28.9023 30.821 29.7612 30.8207 30.2911 30.2908C30.8209 29.7611 30.8214 28.9024 30.2923 28.3721L24.8332 22.9001ZM17.112 22.9017C13.9139 22.9017 11.323 20.3109 11.323 17.113C11.323 13.9163 13.9139 11.3243 17.112 11.3243C20.3088 11.3243 22.901 13.9163 22.901 17.113C22.901 20.3109 20.3088 22.9017 17.112 22.9017Z"
            fill="#00818A"
        />
    </svg>
);

export const companyIcon: JSX.Element = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.1508 20C15.5806 20 19.9823 15.5228 19.9823 10C19.9823 4.47715 15.5806 0 10.1508 0C4.72103 0 0.319336 4.47715 0.319336 10C0.319336 15.5228 4.72103 20 10.1508 20ZM13.5688 13.125C13.8888 12.6172 14.0638 12.0334 14.0936 11.3737H11.8598C11.8427 11.9119 11.6976 12.3047 11.4245 12.5521C11.1514 12.7995 10.7567 12.9232 10.2404 12.9232C9.63021 12.9232 9.19923 12.7062 8.94747 12.2721C8.69997 11.8381 8.57623 11.1155 8.57623 10.1042V9.43359C8.58903 8.50043 8.72558 7.82118 8.98587 7.39583C9.24617 6.97049 9.65581 6.75781 10.2148 6.75781C10.7653 6.75781 11.1749 6.88368 11.4437 7.13542C11.7126 7.38715 11.8555 7.80165 11.8726 8.37891H14.1128C14.0189 7.31554 13.6285 6.4822 12.9415 5.87891C12.2545 5.27127 11.3456 4.96745 10.2148 4.96745C9.42965 4.96745 8.73838 5.15842 8.14098 5.54036C7.54358 5.91797 7.08487 6.45616 6.76483 7.15495C6.44906 7.84939 6.29118 8.66102 6.29118 9.58984V10.0586C6.29118 11.5386 6.64322 12.6845 7.34729 13.4961C8.05137 14.3077 9.01574 14.7135 10.2404 14.7135C10.9829 14.7135 11.6422 14.5747 12.2182 14.2969C12.7985 14.0191 13.2487 13.6285 13.5688 13.125Z"
            fill="#707070"
        />
    </svg>
);

export const installationIcon: JSX.Element = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 20C15.5226 20 20 15.523 20 10C20 4.47703 15.5226 0 10 0C4.47737 0 0 4.47703 0 10C0 15.523 4.47737 20 10 20ZM8.93925 5.48491V14.3103H11.0607V5.48491H8.93925Z"
            fill="#B0B0B0"
        />
    </svg>
);
export const homeIcon: JSX.Element = (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.501 0.128337C11.2718 -0.0412749 10.9617 -0.0429073 10.7308 0.124282L0.27872 7.69003C-0.245021 8.06914 0.0184601 8.9092 0.661105 8.90918L2.4704 8.90914V20H8.59858V13.4071C8.59842 13.3956 8.59858 13.3841 8.59858 13.3726C8.59858 12.0179 9.67732 10.9198 11.008 10.9198C12.3387 10.9198 13.4174 12.0179 13.4174 13.3726C13.4174 13.3841 13.4174 13.3956 13.4172 13.4071L13.4174 20H19.7551V8.90874L21.3389 8.9087C21.9781 8.90868 22.2438 8.07612 21.7268 7.6936L11.501 0.128337Z"
            fill="#707070"
        />
    </svg>
);

export const lighthouse: JSX.Element = (
    <svg width="327" height="311" viewBox="0 0 327 311" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M213.545 74.4297H275.464L291.738 305.436H197.668L213.545 74.4297Z" fill="#00818A" />
        <path d="M203.068 226.846L286.197 226.852L291.063 295.885L198.344 295.99L203.068 226.846Z" fill="white" />
        <path d="M213.52 74.3867L276.926 74.3929L280.284 143.426L208.797 143.531L213.52 74.3867Z" fill="white" />
        <path d="M213.538 74.0156L224.879 74.4368L215.416 305.825H198.099L213.538 74.0156Z" fill="black" fillOpacity="0.05" />
        <path opacity="0.2" d="M275.867 74.0156L264.526 74.4368L273.99 305.825H291.306L275.867 74.0156Z" fill="white" />
        <rect x="163.228" y="305.52" width="162.951" height="5.24578" rx="2" fill="#00818A" />
        <rect x="240.801" y="287.035" width="7.8037" height="22.7283" rx="3.90185" fill="#00818A" />
        <rect x="240.801" y="177.438" width="9.03678" height="17.4633" rx="2" fill="#00818A" />
        <rect x="240.801" y="102.242" width="9.03678" height="15.8767" rx="2" fill="#00818A" />
        <rect x="225.153" y="26.4805" width="40.3325" height="45.3471" fill="#A1C8CA" />
        <rect x="211.53" y="69.207" width="67.5793" height="5.24578" rx="2" fill="#00818A" />
        <rect opacity="0.8" x="226.402" y="28.1211" width="18.1907" height="19.2274" fill="white" />
        <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M227.434 42.5245V45.55L242.916 30.0586H239.892L227.434 42.5245Z"
            fill="black"
            fillOpacity="0.05"
        />
        <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M243.676 38.8051L243.681 36.4141L233.48 46.6018L235.799 46.6384L243.676 38.8051Z"
            fill="black"
            fillOpacity="0.05"
        />
        <rect opacity="0.8" x="246.046" y="28.1211" width="18.1907" height="19.2274" fill="white" />
        <rect x="224.021" y="26.4805" width="42.5969" height="2.6224" rx="1.3112" fill="#00818A" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.45 26.6005C265.474 26.1971 265.486 25.7905 265.486 25.3811C265.486 14.2436 256.457 5.21484 245.32 5.21484C234.182 5.21484 225.153 14.2436 225.153 25.3811C225.153 25.7904 225.166 26.197 225.19 26.6003C225.443 26.4845 225.724 26.4199 226.021 26.4199H264.618C264.915 26.4199 265.196 26.4846 265.45 26.6005Z"
            fill="#A1C8CA"
        />
        <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M236.879 30.0586H227.434V39.5043L236.879 30.0586ZM241.65 30.6283V30.0586H241.08L241.65 30.6283Z"
            fill="black"
            fillOpacity="0.05"
        />
        <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M253.897 46.2539L263.342 46.2539L263.342 36.8092L253.897 46.2539ZM249.126 45.6861L249.126 46.2539L249.694 46.2539L249.126 45.6861Z"
            fill="black"
            fillOpacity="0.05"
        />
        <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M246.863 34.1063L246.858 36.3594L253.706 29.9713L251.253 30.0062L246.863 34.1063Z"
            fill="black"
            fillOpacity="0.05"
        />
        <path
            opacity="0.8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M246.858 42.4271V45.4649L262.353 29.9609H259.317L246.858 42.4271Z"
            fill="black"
            fillOpacity="0.05"
        />
        <rect x="243.36" width="2.68631" height="7.82389" rx="1.34316" fill="#00818A" />
        <rect opacity="0.7" x="215.685" y="49.1523" width="3.16804" height="20.0505" fill="#00818A" />
        <rect opacity="0.7" x="223.699" y="49.1523" width="3.16804" height="20.0505" fill="#00818A" />
        <rect opacity="0.7" x="231.714" y="49.1523" width="3.16804" height="20.0505" fill="#00818A" />
        <rect opacity="0.7" x="239.729" y="49.1523" width="3.16804" height="20.0505" fill="#00818A" />
        <rect opacity="0.7" x="247.742" y="49.1523" width="3.16804" height="20.0505" fill="#00818A" />
        <rect opacity="0.7" x="255.757" y="49.1523" width="3.16804" height="20.0505" fill="#00818A" />
        <rect opacity="0.7" x="263.771" y="49.1523" width="3.16804" height="20.0505" fill="#00818A" />
        <rect opacity="0.7" x="271.786" y="49.1523" width="3.16804" height="20.0505" fill="#00818A" />
        <path opacity="0.4" d="M225.066 47.9366L225.066 29.5376L28.9135 103.583L27.7299 188.394L225.066 47.9366Z" fill="white" />
    </svg>
);

export const signOut: JSX.Element = (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.827117 0.967773C0.414274 0.967773 0.0795898 1.30777 0.0795898 1.72727V12.2083C0.0795898 12.6278 0.414274 12.9678 0.827117 12.9678H4.11624C4.52908 12.9678 4.86376 12.6278 4.86376 12.2083V11.5501H2.32217C1.90933 11.5501 1.57464 11.2101 1.57464 10.7906V3.19562C1.57464 2.77612 1.90933 2.43613 2.32217 2.43613H4.86376V1.72727C4.86376 1.30777 4.52908 0.967773 4.11624 0.967773H0.827117Z"
            fill="#00818A"
        />
        <path
            d="M2.37201 1.37284C2.37201 1.14913 2.5505 0.967773 2.77069 0.967773H4.81393C5.03411 0.967773 5.21261 1.14913 5.21261 1.37284V2.03106C5.21261 2.25477 5.03411 2.43613 4.81393 2.43613H2.77069C2.5505 2.43613 2.37201 2.25477 2.37201 2.03106V1.37284Z"
            fill="#00818A"
        />
        <path
            d="M2.37201 11.9551C2.37201 11.7314 2.5505 11.5501 2.77069 11.5501H4.81393C5.03411 11.5501 5.21261 11.7314 5.21261 11.9551V12.5627C5.21261 12.7864 5.03411 12.9678 4.81393 12.9678H2.77069C2.5505 12.9678 2.37201 12.7864 2.37201 12.5627V11.9551Z"
            fill="#00818A"
        />
        <path
            d="M8.09553 3.36489C8.25123 3.20671 8.50366 3.20671 8.65935 3.36489L11.3837 6.1329C11.3951 6.14446 11.4084 6.15387 11.4223 6.16206C11.4518 6.17937 11.4795 6.20083 11.5047 6.22644L11.9628 6.69188C12.1185 6.85007 12.1185 7.10654 11.9628 7.26473L8.70919 10.5704C8.55349 10.7286 8.30106 10.7286 8.14537 10.5704L7.68727 10.105C7.53157 9.94682 7.53157 9.69035 7.68727 9.53216L9.19921 7.99601C9.2934 7.90032 9.22669 7.7367 9.0935 7.7367H3.61788C3.3977 7.7367 3.2192 7.55535 3.2192 7.33164V6.67341C3.2192 6.4497 3.3977 6.26835 3.61788 6.26835H9.11227C9.24547 6.26835 9.31217 6.10473 9.21799 6.00904L7.63743 4.40318C7.48174 4.24499 7.48174 3.98852 7.63743 3.83033L8.09553 3.36489Z"
            fill="#00818A"
        />
    </svg>
);

export const bridgeAdministration: JSX.Element = (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.01502 1.2705e-05C6.79936 -0.00171768 6.62592 0.173525 6.62763 0.391428L6.63267 1.03257C6.63438 1.25047 6.8106 1.42852 7.02626 1.43025L9.21556 1.44781C9.34601 1.44886 9.4126 1.60876 9.32108 1.70122L5.54604 5.51554C5.39476 5.66839 5.39672 5.91821 5.55043 6.07351L6.00268 6.53047C6.15639 6.68577 6.40363 6.68775 6.55491 6.5349L10.317 2.73362C10.4086 2.64115 10.5668 2.70843 10.5678 2.84024L10.5845 4.95629C10.5862 5.1742 10.7624 5.35224 10.9781 5.35398L11.6126 5.35907C11.8283 5.3608 12.0017 5.18555 12 4.96765L11.9642 0.414023C11.9625 0.19612 11.7863 0.0180716 11.5706 0.0163414L10.9361 0.0112506C10.9012 0.0109706 10.8674 0.0153285 10.8352 0.0237541C10.82 0.0277389 10.8044 0.0304177 10.7887 0.0302912L7.01502 1.2705e-05Z"
            fill="#00818A"
        />
        <path
            d="M0.849096 0.0139288H3.75747V0.0159396H4.41065C4.59823 0.0159396 4.75029 0.169583 4.75029 0.359111V1.11383C4.75029 1.30336 4.59823 1.457 4.41065 1.457H3.05444C3.03565 1.457 3.01721 1.45546 2.99925 1.45249H1.94881C1.66744 1.45249 1.43935 1.68296 1.43935 1.96725V10.0473C1.43935 10.3316 1.66744 10.5621 1.94881 10.5621H10.0466C10.328 10.5621 10.5561 10.3316 10.5561 10.0473V9.08747C10.556 9.08404 10.5559 9.08058 10.5559 9.07712V7.70681C10.5559 7.51728 10.708 7.36364 10.8956 7.36364H11.6558C11.8434 7.36364 11.9954 7.51728 11.9954 7.70681L11.9954 11.1421C11.9954 11.6159 11.6153 12 11.1463 12H0.849096C0.380154 12 0 11.6159 0 11.1421V0.871857C0 0.398036 0.380153 0.0139288 0.849096 0.0139288Z"
            fill="#00818A"
        />
    </svg>
);
