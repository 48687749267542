export interface ISelection {
    company: IOption;
    installation: IOption;
    filteredOptions: ISearchResult[];
    searchText: string;
}

export interface IApplication {
    id: number;
    name: string;
    logoUrl?: string;
}

export interface IRole {
    name: string;
    hierarchyDisplayName: string;
    path: string;
}

export interface IRoles {
    global: IRole[];
    companyGroup: IRole[];
    company: IRole[];
    installation: IRole[];
}

export interface IUser {
    id: number;
    name: string;
    email: string;
    roles: IRoles;
    isGlobalAdministrator: boolean;
    isCompanyAdministrator: boolean;
}

export interface IRigInfo {
    id: number;
    displayName: string;
    staticName: string;
}

export interface ICompany extends IRigInfo {
    installations: IInstallation[];
}

export interface IInstallation extends IRigInfo {}

export interface IHierarchyRole {
    hierarchyAssignmentDisplayPath: string;
    hierarchyAssignmentPath: string;
    name: string;
    userId: number;
}

export interface IUserRoles {
    global: IHierarchyRole[];
    companiesRoles: IHierarchyRole[];
    companyGroupsRoles: IHierarchyRole[];
    installationRoles: IHierarchyRole[];
}

export interface ICurrentUser {
    email: string;
    emailStatus: string;
    firstName: string;
    id: number;
    lastName: string;
    registrationStatus: string;
    isGlobalAdmin: boolean;
    isCompanyAdmin: boolean;
    userRoles: IUserRoles;
}

export interface ICurrentCompaniesAndInstallations {
    displayName: string;
    id: number;
    published: boolean;
    staticId: string;
    company: {
        displayName: string;
        id: number;
        published: boolean;
        staticId: string;
    };
}

export enum OptionType {
    NONE = 'NONE',
    COMPANY = 'COMPANY',
    INSTALLATION = 'INSTALLATION',
    MODULE = 'MODULE',
}

export interface IOption {
    id: number;
    key: string;
    value: string;
    rootName?: string;
    rootId?: number;
    type: OptionType;
}
export interface ISearchResult extends IOption {
    startIndex: number;
    endIndex: number;
    splitIndex: number;
    sortIndex: number;
    searchValue: string;
}

export interface ISearchResultText {
    beforeText: string;
    searchedText: string;
    afterText: string;
    subElementText: string;
}

export interface INotification {
    notificationId: number;
    title: string;
    wasNotified: boolean;
    activationDate: string;
    startDate: string;
    endDate: string;
    shortDescription: string;
    longDescription: string;
    isArchived: boolean;
    isGlobal: boolean;
    importanceLevel: string;
    scopeCompanies: number[];
    scopeInstallations: number[];
}

export class ModuleOption implements IOption {
    public readonly id: number;
    public readonly key: string;
    public readonly value: string;
    public readonly type: OptionType;

    constructor(name: string, id: number) {
        this.id = id;
        this.key = name.toLowerCase();
        this.value = name;
        this.type = OptionType.MODULE;
    }
}
