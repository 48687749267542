import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { mainTheme } from '../../../styles/themes/main-theme';
import { archivedIcon } from '../../../ui-icons/components/notifications';
import { ClickOutsideWrapper } from '../../click-outside/click-outside';

const ModalContainer = styled.div`
    background: ${mainTheme.colors.archivedNotification};
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    position: relative;
    padding-left: ${mainTheme.containerMargins.normal};
    padding-right: ${mainTheme.containerMargins.normal};
    width: 100%;
    box-shadow: 0px 0px 5px ${mainTheme.colors.shadow};
    border-radius: 3px;
    display: inline-block;
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
    min-height: 70px;
`;

const Icon = styled.div`
    padding-right: 15px;
    margin-top: -2px;
`;

const HeaderText = styled.div`
    font-weight: bold;
    color: ${mainTheme.colors.grey};
`;

const Link = styled.div`
    padding-top: 15px;
    margin-right: 0;
    margin-left: auto;
    padding-bottom: ${mainTheme.containerMargins.small};
    text-decoration-line: underline;
    color: ${mainTheme.colors.bridgePrimary};
    cursor: pointer;
`;
export interface INotificationProps {
    isOpen: boolean;
    notificationId: number;
    onUndo: (notificationId: number) => void;
    onClose: () => void;
}

export const ArchivedNotification: React.FC<INotificationProps> = ({ isOpen, notificationId, onUndo, onClose }) => {
    useEffect(() => {
        setIsVisible(isOpen);
    }, [isOpen]);

    const [isVisible, setIsVisible] = useState(false);
    const onClick = () => {
        onUndo(notificationId);
        onClose();
    };

    const onClickOutside = () => {
        onClose();
    };

    return (
        <>
            {isVisible ? (
                <ClickOutsideWrapper handleClickOutside={onClickOutside}>
                    <ModalContainer>
                        <Header>
                            <Icon>{archivedIcon}</Icon>
                            <HeaderText>{'notification archived'}</HeaderText>
                            <Link onClick={onClick}>Undo</Link>
                        </Header>
                    </ModalContainer>
                </ClickOutsideWrapper>
            ) : null}
        </>
    );
};
