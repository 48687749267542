export abstract class ApiAdapter {
    /**
     * URI of the API endpoint including its version
     */
    protected readonly apiAddress: string;

    protected constructor(apiAddress: string) {
        this.apiAddress = apiAddress;
    }
}
