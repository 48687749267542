import * as log from 'loglevel';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from "@sentry/integrations";
import { isEnvDevelopment } from './env';

function init() {
    const env = process.env.REACT_APP_ENVIRONMENT;
    if (isEnvDevelopment()) {
        log.debug(`Sentry not initialized because we're in ${env} mode`);
        return;
    }
    log.info(`Sentry initialized for dsn=${process.env.REACT_APP_SENTRY_DSN}, env=${env}`);
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: env,
        maxBreadcrumbs: 25,
        integrations: [
            new Sentry.BrowserTracing(),
            new CaptureConsole(
                {
                    levels: ['warn', 'error']
                }
            ),
        ],
        tracesSampleRate: 1.0,
    });
}

function setUser(userName: string) {
    log.debug(`Setting Sentry user to ${userName}`);
    Sentry.configureScope((scope) => {
        scope.setUser({ 'username': userName });
    });
}

export const SentryFacade = {
    init,
    setUser,
}
