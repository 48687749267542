import { keyframes } from 'styled-components/macro';

export const mainTheme = {
    colors: {
        white: '#ffffff',
        black: '#000000',
        bridgePrimary: '#00818a',
        bridgeRed: '#c96875',
        bridgeOrange: '#f5b97d',
        lightBlue: '#10a1ab',
        lightGrey: '#707070',
        focusBackground: '#F2F9F9',
        mediumGrey: '#dee2e6',
        bridgescrollbar: '#C4C4C4',
        grey: '#3d3d3d',
        menuUnderline: '#b0b0b0',
        inactiveInput: '#b0b0b0',
        mailLink: '#1177b7',
        hoverBackground: 'rgba(0, 129, 138, 0.1)',
        inputBackground: 'rgba(0, 129, 138, 0.05)',
        separator: 'rgba(0,129,138,0.15)',
        lineColor: 'rgba(0, 129, 138, 0.5)',
        unactiveButtonBackground: '#dfdfdf',
        error: '#A20000',
        archivedNotification: 'rgba(162,0,0, 0.3)',
        unactiveButtonText: '#757373',
        mainBackground: '#f6f6f6',
        shadow: 'rgba(0, 0, 0, 0.16)',
        shadowInner: 'rgba(0, 0, 0, 0.5)',
        darkerGrey: '#EAEAEA',
        transparent: 'rgba(0,0,0,0)',
    },
    fontSizes: {
        tiny: '10px',
        small: '12px',
        normal: '14px',
        big: '16px',
        bigger: '20px',
        biggest: '24px',
        biggest28: '28px',
    },
    containerMargins: {
        extrasmall: '10px',
        small: '20px',
        normal: '30px',
        big: '40px',
        bigger: '50px',
        biggest: '60px',
        huge: '80px',
    },
    margins: {
        tiny: '0.1rem',
        small: '0.3rem',
        normal: '0.5rem',
        big: '0.7rem',
        bigger: '0.9rem',
        biggest: '1.5rem',
        huge: '2rem',
    },
    lineHeights: {
        h2: '19px',
        h1: '23px',
        h33: '33px',
    },
    heights: {
        navbar: '50px',
        small: '18rem',
        modalDialog: '265px',
        mainBody: {
            desktop: '49rem',
            mobile: '53rem',
        },
        dropdownOption: '95px',
        loadingSpinnerHeight: '100px',
        notificationsHeight: '120px',
    },
    widths: {
        moduleOption: '50px',
        sideMenuMax: '400px',
        sideMenuMobile: '300px',
        modalDialog: '400px',
        content: '1040px',
        dropdownPhone: '280px',
        dropdownTablet: '440px',
        dropdownNormalAndUp: '400px',
        alertMessage: '640px',
        loadingSpinnerWidth: '150px',
    },
    breakpoints: {
        footerMaxWidth: '(max-width: 342px)',
        changePasswordButtons: '(max-width: 376px)',
    },
    responsiveBreakpoints: {
        phone: 'only screen and (max-width: 600px)',
        tablet: 'only screen and (min-width: 601px) and (max-width: 800px)',
        smallScreen: 'only screen and (min-width: 801px) and (max-width: 1024px)',
        normalScreen: 'only screen and (min-width: 1025px) and (max-width: 1253px)',
        bigScreen: 'only screen and (min-width: 1254px)',
        normalAndBigScreen: 'only screen and (min-width: 1025px)',
        smallScreens: 'only screen and (max-width: 1024px)',
        phoneHeight: 'only screen and (max-height: 680px) and (max-width: 600px)',
        smallHeight: 'only screen and (max-height: 800px)',
        smallDesktopHeight: 'only screen and (max-height: 800px) and (min-width: 1025px)',
    },
    deviceBreakpoints: {
        isPhone: window.innerWidth >= 360 && window.innerWidth < 600,
        isTablet: window.innerWidth >= 600 && window.innerWidth < 800,
        isSmallScreen: window.innerWidth >= 800 && window.innerWidth < 1024,
        isNormalScreen: window.innerWidth >= 1024 && window.innerWidth < 1253,
        isBigScreen: window.innerWidth >= 1253,
    },
    timeouts: {
        navigation: 100,
    },
    blur: {
        loading: 'blur(5px)',
        noBlur: 'blur(0px)',
    },
};

export const animations = {
    profileMenu: {
        expand: keyframes`
            0% { right: -36rem;}
            100% { right: -2rem;}
          `,
        hide: keyframes`
            0% { right: -12rem; }
            100% { right: -36rem; }
          `,
        phoneHide: keyframes`
            0% { right: -2rem; }
            100% { right: -22rem; }
          `,
        fadeIn: keyframes`
            0% {
              opacity:0;
            }
            100% {
              opacity:0.3;
            }
          `,
    },
};

export const px = (value: number) => `${value}px`;
